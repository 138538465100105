import React, { useEffect, useRef, useState } from 'react';
import Downshift from 'downshift';
import styled from 'styled-components/macro';
import { Input } from './Input';
import {
  size100,
  size750,
  size050,
  size250,
  size200,
  size150,
  size000,
} from '../styling/sizes';
import { borderRadiusMedium } from '../styling/borders';
import { shadow000 } from '../styling/shadows';
import { gray500, primary600, primary900 } from '../styling/colours';
import { orderBy, some, debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { FolderIcon, UserIcon } from '../icons/Icons';
import { fontWeightBold } from '../styling/fontWeights';
import { fontSize100 } from '../styling/fontSizes';
import { usePostWithResponse } from '../api/usePostWithResponse';
import {
  SearchProjectsTeamsAndUserAccountsProjectResponse,
  SearchProjectsTeamsAndUserAccountsResponse,
  SearchProjectsTeamsAndUserAccountsUserAccountResponse,
} from './SearchProjectsTeamsAndUserAccountsResponse';

type SearchMatcher = {
  value: string;
  priority: number;
};

type UserSearchResult = {
  kind: 'User';
  searchMatchers: Array<SearchMatcher>;
  user: SearchProjectsTeamsAndUserAccountsUserAccountResponse;
};

type ProjectSearchResult = {
  kind: 'Project';
  searchMatchers: Array<SearchMatcher>;
  project: SearchProjectsTeamsAndUserAccountsProjectResponse;
};

type SearchResult = UserSearchResult | ProjectSearchResult;

export const SearchInput = () => {
  const history = useHistory();

  const apiRequest = usePostWithResponse<
    { searchTerm: string },
    SearchProjectsTeamsAndUserAccountsResponse
  >('SearchProjectsTeamsAndUserAccounts');

  const [boundSearchTerm, setBoundSearchTerm] = useState<string>('');

  const [appliedSearchTerm, setAppliedSearchTerm] = useState<string>('');

  const debouncedSetAppliedSearchTerm = useRef(
    debounce(setAppliedSearchTerm, 1_000)
  );

  useEffect(() => {
    debouncedSetAppliedSearchTerm.current(boundSearchTerm);
  }, [boundSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const [
    cachedResponse,
    setCachedResponse,
  ] = useState<SearchProjectsTeamsAndUserAccountsResponse | null>(null);

  useEffect(() => {
    if (appliedSearchTerm != null && appliedSearchTerm.trim() !== '') {
      apiRequest.makeRequest({
        body: { searchTerm: appliedSearchTerm },
        onSuccess: setCachedResponse,
      });
    }
  }, [appliedSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const projects = cachedResponse?.projects ?? [];
  // const teams = response?.teams ?? [];
  const userAccounts = cachedResponse?.userAccounts ?? [];

  const userSearchResults: Array<UserSearchResult> = userAccounts.map(
    userAccount => {
      const userNameMatcher: SearchMatcher = {
        value: userAccount.fullName,
        priority: 10,
      };

      const userEmailMatcher: SearchMatcher = {
        value: userAccount.emailAddress,
        priority: 8,
      };

      const userOrganisationMatcher: SearchMatcher = {
        value: userAccount.organisationName,
        priority: 3,
      };

      const searchMatchers = [
        userNameMatcher,
        userEmailMatcher,
        userOrganisationMatcher,
      ];

      return {
        kind: 'User',
        searchMatchers,
        user: userAccount,
      };
    }
  );

  const projectSearchResults: Array<ProjectSearchResult> = projects.map(
    project => {
      const projectTitleMatcher: SearchMatcher = {
        value: project.title,
        priority: 10,
      };

      const projectLeadUserMatcher: SearchMatcher = {
        value: project.leadUserAccountFullName,
        priority: 5,
      };

      const searchMatchers = [projectTitleMatcher, projectLeadUserMatcher];

      return {
        kind: 'Project',
        searchMatchers,
        project,
      };
    }
  );

  return (
    <Downshift
      onInputValueChange={inputValue => setBoundSearchTerm(inputValue)}
      itemToString={item => (item ? item.value : '')}
      onSelect={item => {
        if (item?.kind == null) {
          return;
        } else if (item.kind === 'User') {
          history.push(`/app/users/${item.user.userAccountId}`);
        } else {
          history.push(`/app/projects/${item.project.projectId}`);
        }
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        getRootProps,
      }) => {
        const searchWords = (inputValue?.trim().split(' ') || []).map(word =>
          word.trim().toLowerCase()
        );

        const matchingUsers = userSearchResults
          .map(userResult => {
            let score = 0;

            for (const matcher of userResult.searchMatchers) {
              const normalisedValue = matcher.value.trim().toLowerCase();
              const normalisedSearchTerm =
                inputValue?.trim().toLowerCase() || '';

              if (
                normalisedValue.includes(normalisedSearchTerm) ||
                some(
                  searchWords,
                  searchWord =>
                    searchWord.length > 2 &&
                    normalisedValue.includes(searchWord)
                )
              ) {
                score += matcher.priority;
              }
            }

            return { score, result: userResult };
          })
          .filter(match => match.score > 0);

        const matchingProjects = projectSearchResults
          .map(projectResult => {
            let score = 0;

            for (const matcher of projectResult.searchMatchers) {
              const normalisedValue = matcher.value.trim().toLowerCase();
              const normalisedSearchTerm =
                inputValue?.trim().toLowerCase() || '';

              if (
                normalisedValue.includes(normalisedSearchTerm) ||
                some(
                  searchWords,
                  searchWord =>
                    searchWord.length > 2 &&
                    normalisedValue.includes(searchWord)
                )
              ) {
                score += matcher.priority;
              }
            }

            return { score, result: projectResult };
          })
          .filter(match => match.score > 0);

        const allResults: Array<SearchResult> = orderBy(
          [...matchingUsers, ...matchingProjects],
          match => -1 * match.score
        ).map(match => match.result);

        const rootProps = getRootProps(
          { refKey: '' },
          { suppressRefError: true }
        );
        delete rootProps[''];

        return (
          <Container>
            <div style={{ display: 'inline-block' }} {...rootProps}>
              <Input
                {...getInputProps()}
                placeholder="Search for projects, teams, and users"
                style={{ width: '300px' }}
              />
            </div>
            {isOpen && inputValue && (
              <Menu {...getMenuProps()}>
                {apiRequest.inProgress ||
                boundSearchTerm !== appliedSearchTerm ? (
                  <NoResults>Loading...</NoResults>
                ) : allResults.length === 0 ? (
                  <NoResults>
                    Sorry, we couldn't find any matching results.
                  </NoResults>
                ) : (
                  allResults.map((result: SearchResult, index: number) => (
                    <Option
                      {...getItemProps({
                        key:
                          result.kind === 'User'
                            ? result.user.userAccountId
                            : result.project.projectId,
                        index,
                        item: result,
                        style: {
                          backgroundColor:
                            highlightedIndex === index
                              ? primary900
                              : 'transparent',
                        },
                      })}
                    >
                      {result.kind === 'User' ? (
                        <>
                          <OptionIconContainer>
                            <UserIcon />
                          </OptionIconContainer>
                          <div>
                            <OptionText>{result.user.fullName}</OptionText>
                            <OptionSubtext>
                              {result.user.organisationName}
                            </OptionSubtext>
                          </div>
                        </>
                      ) : (
                        <>
                          <OptionIconContainer>
                            <FolderIcon />
                          </OptionIconContainer>
                          <div>
                            <OptionText>{result.project.title}</OptionText>
                            <OptionSubtext>
                              Lead by {result.project.leadUserAccountFullName}
                            </OptionSubtext>
                          </div>
                        </>
                      )}
                    </Option>
                  ))
                )}
              </Menu>
            )}
          </Container>
        );
      }}
    </Downshift>
  );
};

const Container = styled.div`
  position: relative;
`;

const Menu = styled.div`
  position: absolute;
  top: 42px;
  left: 0;
  background-color: white;
  padding: ${size050};
  border-radius: ${borderRadiusMedium};
  box-shadow: ${shadow000};
  width: ${size750};
  max-width: 80vw;
  max-height: 80vh;
`;

const BaseOption = styled.div`
  margin: ${size050} 0;
  padding: ${size100};
  border-radius: ${borderRadiusMedium};

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Option = styled(BaseOption)`
  display: flex;
  align-items: center;
  transition: background-color 0.15s ease;
  cursor: pointer;
`;

const NoResults = styled(BaseOption)`
  color: ${gray500};
`;

const OptionIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${size250};
  width: ${size250};
  margin-right: ${size150};

  svg {
    height: ${size200};
    width: ${size200};
    color: ${primary600};
  }
`;

const OptionText = styled.div`
  margin-bottom: ${size000};
  font-weight: ${fontWeightBold};
`;

const OptionSubtext = styled.div`
  color: ${gray500};
  font-size: ${fontSize100};
`;

import styled from 'styled-components/macro';
import { size050, size000, size450 } from '../styling/sizes';
import { borderRadiusSmall } from '../styling/borders';
import {
  gray800,
  gray700,
  gray950,
  yellow300,
  gray000,
  yellow900,
  yellow500,
  yellow600,
} from '../styling/colours';
import { lineHeightMedium } from '../styling/lineHeights';
import { fontSize200 } from '../styling/fontSizes';
import { ValidityState } from '../form/ValidityState';

export const TextArea = styled.textarea<{ validityState?: ValidityState }>`
  resize: none;
  height: ${size450};
  padding: ${size000} ${size050};
  line-height: ${lineHeightMedium};
  width: 100%;
  border-radius: ${borderRadiusSmall};
  outline: none;
  border-style: none;
  transition: all 0.25s ease;
  font-size: ${fontSize200};

  color: ${props => (props.validityState === 'invalid' ? yellow300 : gray000)};

  background-color: ${props =>
    props.validityState === 'invalid' ? yellow900 : gray950};

  box-shadow: 0 0 0 1px
    ${props => (props.validityState === 'invalid' ? yellow500 : gray800)};

  &:focus {
    background-color: white;
    box-shadow: 0 0 0 2px
      ${props => (props.validityState === 'invalid' ? yellow600 : gray700)};
  }
`;

import React, { useContext, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { DataContext } from '../../model/DataContext';
import { DeprecatedSubmitButton } from '../../shared/Buttons';
import { Formik } from 'formik';
import { Form } from '../../form/Form';
import {
  withBlankOption,
  SelectOptions,
  SelectField,
} from '../../form/SelectField';
import { FormField } from '../../form/FormField';
import { Label } from '../../form/Label';
import { TextAreaField } from '../../form/TextAreaField';
import { produce } from 'immer';
import { CardHeader, CardTitle, Card, CardContent } from '../../shared/Card';
import styled from 'styled-components/macro';
import { AppLink } from '../../shared/AppLink';

export const ViewProjectApprovalRequest = () => {
  const { projectApprovalRequestId } = useParams();

  const dataContext = useContext(DataContext);
  const data = dataContext.getData();

  const [isDone, setIsDone] = useState(false);

  if (isDone) {
    return <Redirect to="/app/management" />;
  }

  const projectApprovalRequest =
    data.projectCyclePlanApprovalRequests[projectApprovalRequestId!];

  if (projectApprovalRequest == null) {
    return null;
  }

  const projectCyclePlan = data.projectCyclePlans[
    projectApprovalRequest.projectCyclePlanId
  ]!;

  const projectCycle = data.projectCycles[projectCyclePlan.projectCycleId]!;

  const project = data.projects[projectCycle.projectId]!;

  const user = data.users[project.leadUserId]!;

  const onSubmit = (formModel: FormModel) => {
    if (formModel.decision === 'Reject') {
      alert('TODO - Rejecting is not implemented yet');
      return;
    }

    const newData = produce(data, draft => {
      draft.projectCycles[projectCycle.projectCycleId].status = 'Doing';
      delete draft.projectCyclePlanApprovalRequests[
        projectApprovalRequest.projectCyclePlanApprovalRequestId
      ];
    });

    dataContext.setData(newData);

    setIsDone(true);
  };

  return (
    <Formik<FormModel>
      onSubmit={onSubmit}
      initialValues={{ decision: '', comments: '' }}
    >
      {() => (
        <>
          <Card>
            <CardHeader>
              <CardTitle>Plan approval request</CardTitle>
            </CardHeader>
            <CardContent>
              <AppLink to={`/app/users/${user.userId}`}>{user.name}</AppLink>{' '}
              submitted this Plan for Cycle {projectCycle.cycleNumber} of{' '}
              <AppLink to={`/app/projects/${project.projectId}`}>
                {project.title}
              </AppLink>
              .
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Plan details</CardTitle>
            </CardHeader>
            <CardContent>
              {/* TODO : View Project Approval Request */}
              {/*<PlanDetails*/}
              {/*  plan={projectCyclePlan}*/}
              {/*  variables={dataEntryFormVariables}*/}
              {/*/>*/}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Decision</CardTitle>
            </CardHeader>
            <CardContent>
              <DecisionForm>
                <FormField>
                  <SelectField<string>
                    name="decision"
                    options={decisionOptions}
                  />
                </FormField>

                <FormField>
                  <Label>Comments</Label>
                  <TextAreaField name="comments" />
                </FormField>

                <DeprecatedSubmitButton>Submit</DeprecatedSubmitButton>
              </DecisionForm>
            </CardContent>
          </Card>
        </>
      )}
    </Formik>
  );
};

type FormModel = {
  decision: string;
  comments: string;
};

const decisionOptions: SelectOptions<string> = withBlankOption<string>(
  [
    { text: 'Approve', value: 'Approve' },
    { text: 'Reject', value: 'Reject' },
  ],
  ''
);

const DecisionForm = styled(Form)``;

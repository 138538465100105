import React from 'react';
import styled from 'styled-components/macro';
import { yellow300 } from '../styling/colours';
import { fontSize100 } from '../styling/fontSizes';
import { FieldMetaProps } from 'formik';
import { size000 } from '../styling/sizes';

type Props = {
  meta: FieldMetaProps<unknown>;
};

export const FieldError = (props: Props) =>
  props.meta.touched && props.meta.error != null ? (
    <FieldErrorText>{props.meta.error}</FieldErrorText>
  ) : null;

export const FieldErrorText = styled.div`
  color: ${yellow300};
  font-size: ${fontSize100};
  margin-top: ${size000};
`;

import React from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  PrimaryButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type CancelProjectCyclePlanApprovalRequestCommand = {
  projectCyclePlanId: number;
};

type Props = {
  projectCyclePlanId: number;
  close: (andRefresh: boolean) => void;
};

export const CancelProjectCyclePlanApprovalRequest = ({
  projectCyclePlanId,
  close,
}: Props) => {
  const cancelProjectCyclePlanApprovalRequestRequest = usePostWithoutResponse<
    CancelProjectCyclePlanApprovalRequestCommand
  >('CancelProjectCyclePlanApprovalRequest');

  const cancelProjectCyclePlanApprovalRequest = () => {
    cancelProjectCyclePlanApprovalRequestRequest.makeRequest({
      body: { projectCyclePlanId },
      onSuccess: () => close(true),
    });
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Cancel request for approval</ModalHeader>
        <div>
          Are you sure you wish to cancel the approval request for this plan?
        </div>
        <ApiErrorBox
          error={cancelProjectCyclePlanApprovalRequestRequest.error}
          withMargin={true}
        />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={() => close(false)}
            disabled={cancelProjectCyclePlanApprovalRequestRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={cancelProjectCyclePlanApprovalRequest}
            disabled={cancelProjectCyclePlanApprovalRequestRequest.inProgress}
          >
            Confirm
          </PrimaryButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

export type ProjectCycleAction = {
  projectCycleActionId: string;
  projectCycleId: string;
  typeCode: ProjectCycleActionTypeCode;
  reason: string;
};

export type ProjectCycleActionTypeCode = 'NewCycle';

export type ProjectCycleActionType = {
  typeCode: ProjectCycleActionTypeCode;
  name: string;
  description: string;
};

export const projectCycleActionTypes: {
  [typeCode in ProjectCycleActionTypeCode]: ProjectCycleActionType;
} = {
  NewCycle: {
    typeCode: 'NewCycle',
    name: 'New Cycle',
    description:
      'Begins a new cycle, allowing you to introduce a change or gather more data.',
  },
};

export type ProjectCompletionApprovalRequest = {
  projectCompletionApprovalRequestId: string;
  projectId: string;
};

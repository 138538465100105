import styled from 'styled-components/macro';
import {
  gray200,
  gray800,
  green200,
  yellow800,
  yellow200,
  red200,
  green900,
  red900,
  accent900,
  accent100,
} from '../styling/colours';
import { size150, size225 } from '../styling/sizes';
import { fontSize000 } from '../styling/fontSizes';
import { letterSpacingWide } from '../styling/letterSpacing';
import { fontWeightBold } from '../styling/fontWeights';

const BaseBadge = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 0 ${size150};
  height: ${size225};
  font-size: ${fontSize000};
  font-weight: ${fontWeightBold};
  letter-spacing: ${letterSpacingWide};
`;

export const NeutralBadge = styled(BaseBadge)`
  background-color: ${gray800};
  color: ${gray200};
`;

export const PrimaryBadge = styled(BaseBadge)`
  background-color: ${accent900};
  color: ${accent100};
`;

export const SuccessBadge = styled(BaseBadge)`
  background-color: ${green900};
  color: ${green200};
`;

export const WarningBadge = styled(BaseBadge)`
  background-color: ${yellow800};
  color: ${yellow200};
`;

export const DangerBadge = styled(BaseBadge)`
  background-color: ${red900};
  color: ${red200};
`;

import React from 'react';
import { useFormikContext } from 'formik';
import { DeprecatedSubmitButton } from '../shared/Buttons';

type Props = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

export const FormSubmitButton = (props: Props) => {
  const formikContext = useFormikContext();

  return (
    <DeprecatedSubmitButton
      disabled={props.disabled || formikContext.isSubmitting}
      className={props.className}
    >
      {props.children}
    </DeprecatedSubmitButton>
  );
};

import React from 'react';
import { Form as FormikForm, FormikFormProps } from 'formik';
import styled from 'styled-components/macro';
import { FieldErrorText } from './FieldError';

export const Form = (
  props: FormikFormProps & { showFieldErrors?: boolean }
) => (
  <FormContainer showFieldErrors={props.showFieldErrors ?? true}>
    <FormikForm {...props} autoComplete="off_completely" />
  </FormContainer>
);

const FormContainer = styled.div<{ showFieldErrors: boolean }>`
  ${FieldErrorText} {
    display: ${props => (props.showFieldErrors ? 'block' : 'none')};
  }
`;

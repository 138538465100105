import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Section, SectionHeader } from '../../shared/Section';
import { useGet } from '../../api/useGet';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { CenteredSpinner } from '../../shared/Spinner';
import {
  ChevronHeader,
  StandaloneTable,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../shared/Table';
import { PopoverMenu, PopoverMenuOption } from '../../shared/PopoverMenu';
import { PopoverMenuButton } from '../../shared/PopoverMenuButton';
import { ButtonRowAlignRight } from '../../shared/Buttons';
import { gray500 } from '../../styling/colours';
import { size150 } from '../../styling/sizes';
import { fontSize200 } from '../../styling/fontSizes';
import { fontWeightBold } from '../../styling/fontWeights';
import { NeutralBadge, WarningBadge, SuccessBadge } from '../../shared/Badge';
import { CheckboxFilterLabel } from '../../shared/SearchPanel';
import { Checkbox } from '../../shared/Checkbox';
import { GroupAddIcon, LogoutIcon } from '../../icons/Icons';
import { Modal } from '../../shared/Modal';
import { JoinTeam } from './JoinTeam';
import { LeaveTeam } from './LeaveTeam';
import { TeamResponse, TeamsResponse } from '../teams/TeamsResponse';
import { UserCircles } from '../../shared/UserCircles';

export const ViewTeams = () => {
  const getTeamsForCurrentUserAccountRequest = useGet<TeamsResponse>(
    'GetTeamsForCurrentUserAccount'
  );

  useEffect(() => {
    getTeamsForCurrentUserAccountRequest.makeRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [myTeamsIncludeArchived, setMyTeamsIncludeArchived] = useState(false);

  const [otherTeamsIncludeArchived, setOtherTeamsIncludeArchived] = useState(
    false
  );

  const [teamToJoin, setTeamToJoin] = useState<TeamResponse | null>(null);

  const closeJoinModal = (andRefresh: boolean) => {
    setTeamToJoin(null);

    if (andRefresh) {
      getTeamsForCurrentUserAccountRequest.makeRequest();
    }
  };

  const [teamToLeave, setTeamToLeave] = useState<TeamResponse | null>(null);

  const closeLeaveModal = (andRefresh: boolean) => {
    setTeamToLeave(null);

    if (andRefresh) {
      getTeamsForCurrentUserAccountRequest.makeRequest();
    }
  };

  if (getTeamsForCurrentUserAccountRequest.error != null) {
    return <ApiErrorBox error={getTeamsForCurrentUserAccountRequest.error} />;
  }

  const response = getTeamsForCurrentUserAccountRequest.response;

  if (getTeamsForCurrentUserAccountRequest.inProgress || response == null) {
    return <CenteredSpinner />;
  }

  const myTeams = response.teams
    .filter(t => t.isMember)
    .filter(t => myTeamsIncludeArchived || !t.isArchived);

  const otherTeams = response.teams
    .filter(t => !t.isMember)
    .filter(t => otherTeamsIncludeArchived || !t.isArchived);

  return (
    <>
      <Section>
        <HeaderContainer>
          <SectionHeader>My Teams</SectionHeader>
          <CheckboxFilterLabel>
            <Checkbox
              checked={myTeamsIncludeArchived}
              onChange={event => {
                setMyTeamsIncludeArchived(event.target.checked);
              }}
            />
            Show archived
          </CheckboxFilterLabel>
        </HeaderContainer>
        <StandaloneTable style={{ width: '100%' }}>
          <THead>
            <Tr>
              <Th>Name</Th>
              <Th>Users</Th>
              <Th>Approvers</Th>
              <Th>Status</Th>
              <ChevronHeader />
            </Tr>
          </THead>
          <TBody>
            {myTeams.length > 0 &&
              myTeams.map(team => (
                <Tr key={team.teamId}>
                  <Td>
                    <TeamName>{team.name}</TeamName>
                  </Td>
                  <Td>
                    {team.users.length === 0 ? (
                      <NeutralBadge>None</NeutralBadge>
                    ) : (
                      <UserCircles
                        users={team.users.map(u => ({
                          userAccountId: u.userAccountId,
                          fullName: u.name,
                        }))}
                      />
                    )}
                  </Td>
                  <Td>
                    {team.approvers.length === 0 ? (
                      <NeutralBadge>None</NeutralBadge>
                    ) : (
                      <UserCircles
                        users={team.approvers.map(u => ({
                          userAccountId: u.userAccountId,
                          fullName: u.name,
                        }))}
                      />
                    )}
                  </Td>
                  <Td>
                    {team.isArchived ? (
                      <NeutralBadge>Archived</NeutralBadge>
                    ) : team.approvers.length === 0 ? (
                      <WarningBadge>No approvers</WarningBadge>
                    ) : (
                      <SuccessBadge>Active</SuccessBadge>
                    )}
                  </Td>
                  <Td>
                    <ButtonRowAlignRight>
                      <PopoverMenuButton>
                        <PopoverMenu>
                          <PopoverMenuOption
                            onClick={() => setTeamToLeave(team)}
                          >
                            <LogoutIcon />
                            Leave team
                          </PopoverMenuOption>
                        </PopoverMenu>
                      </PopoverMenuButton>
                    </ButtonRowAlignRight>
                  </Td>
                </Tr>
              ))}
            {myTeams.length === 0 && (
              <Tr>
                <Td colSpan={5}>No results</Td>
              </Tr>
            )}
          </TBody>
        </StandaloneTable>
      </Section>
      <Section>
        <HeaderContainer>
          <SectionHeader>Other Teams</SectionHeader>
          <CheckboxFilterLabel>
            <Checkbox
              checked={otherTeamsIncludeArchived}
              onChange={event => {
                setOtherTeamsIncludeArchived(event.target.checked);
              }}
            />
            Show archived
          </CheckboxFilterLabel>
        </HeaderContainer>
        <StandaloneTable style={{ width: '100%' }}>
          <THead>
            <Tr>
              <Th>Name</Th>
              <Th>Users</Th>
              <Th>Approvers</Th>
              <Th>Status</Th>
              <ChevronHeader />
            </Tr>
          </THead>
          <TBody>
            {otherTeams.length > 0 &&
              otherTeams.map(team => (
                <Tr key={team.teamId}>
                  <Td>
                    <TeamName>{team.name}</TeamName>
                  </Td>
                  <Td>
                    {team.users.length === 0 ? (
                      <NeutralBadge>None</NeutralBadge>
                    ) : (
                      <UserCircles
                        users={team.users.map(u => ({
                          userAccountId: u.userAccountId,
                          fullName: u.name,
                        }))}
                      />
                    )}
                  </Td>
                  <Td>
                    {team.approvers.length === 0 ? (
                      <NeutralBadge>None</NeutralBadge>
                    ) : (
                      <UserCircles
                        users={team.approvers.map(u => ({
                          userAccountId: u.userAccountId,
                          fullName: u.name,
                        }))}
                      />
                    )}
                  </Td>
                  <Td>
                    {team.isArchived ? (
                      <NeutralBadge>Archived</NeutralBadge>
                    ) : team.approvers.length === 0 ? (
                      <WarningBadge>No approvers</WarningBadge>
                    ) : (
                      <SuccessBadge>Active</SuccessBadge>
                    )}
                  </Td>
                  <Td>
                    <ButtonRowAlignRight>
                      <PopoverMenuButton>
                        <PopoverMenu>
                          <PopoverMenuOption
                            onClick={() => setTeamToJoin(team)}
                          >
                            <GroupAddIcon /> Join team
                          </PopoverMenuOption>
                        </PopoverMenu>
                      </PopoverMenuButton>
                    </ButtonRowAlignRight>
                  </Td>
                </Tr>
              ))}
            {otherTeams.length === 0 && (
              <Tr>
                <Td colSpan={5}>No results</Td>
              </Tr>
            )}
          </TBody>
        </StandaloneTable>
      </Section>

      <Modal isOpen={teamToJoin != null} close={() => closeJoinModal(false)}>
        {teamToJoin != null && (
          <JoinTeam
            teamId={teamToJoin.teamId}
            teamName={teamToJoin.name}
            close={closeJoinModal}
          />
        )}
      </Modal>

      <Modal isOpen={teamToLeave != null} close={() => closeLeaveModal(false)}>
        {teamToLeave != null && (
          <LeaveTeam
            teamId={teamToLeave.teamId}
            teamName={teamToLeave.name}
            close={closeLeaveModal}
          />
        )}
      </Modal>
    </>
  );
};

const TeamName = styled.div`
  font-size: ${fontSize200};
  font-weight: ${fontWeightBold};
  color: ${gray500};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${size150};

  ${SectionHeader} {
    margin: 0;
  }
`;

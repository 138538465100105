import React, { useContext, useState } from 'react';
import { Form } from '../../../form/Form';
import { Formik, FormikHelpers } from 'formik';
import { keyBy, find } from 'lodash';
import { FormField } from '../../../form/FormField';
import { Label } from '../../../form/Label';
import { CheckboxField } from '../../../form/CheckboxField';
import { InputField } from '../../../form/InputField';
import {
  PrimaryButton,
  SecondaryButton,
  ButtonRowAlignRight,
} from '../../../shared/Buttons';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from '../../../shared/Modal';
import styled from 'styled-components/macro';
import { size200, size350 } from '../../../styling/sizes';
import { green900, green400 } from '../../../styling/colours';
import { TickIcon, ReplayIcon } from '../../../icons/Icons';
import { ProjectContext } from '../ProjectContext';
import { DataEntryFormVariableResponse } from '../../../features/projects/GetProjectResponse';
import { usePostWithoutResponse } from '../../../api/usePostWithoutResponse';
import { AddDataCommand, AddDataCommandDataPoint } from './AddDataCommand';
import { ApiErrorBox } from '../../../api/ApiErrorBox';
import { FormSubmitButton } from '../../../form/FormSubmitButton';

type Props = {
  isOpen: boolean;
  close: (andRefresh: boolean) => void;
};

export const AddDataModal = (props: Props) => {
  const [added, setAdded] = useState(false);
  const [shouldRefreshOnClose, setShouldRefreshOnClose] = useState(false);

  const close = () => {
    if (shouldRefreshOnClose) {
      setShouldRefreshOnClose(false);
      props.close(true);
    } else {
      props.close(false);
    }
  };

  const projectContext = useContext(ProjectContext);
  const project = projectContext.getProject();

  const projectCycle = find(
    project.cycles,
    cycle => cycle.status !== 'Complete'
  );

  const projectCyclePlan = projectCycle?.plan;
  const dataEntryForm = projectCyclePlan?.dataEntryForm;
  const dataEntryFormVariables = dataEntryForm?.variables;

  const apiRequest = usePostWithoutResponse<AddDataCommand>('AddData');

  if (dataEntryFormVariables == null) {
    return (
      <Modal isOpen={props.isOpen} close={close}>
        <p>Invalid Project</p>
      </Modal>
    );
  }

  const dataEntryFormVariablesById = keyBy(
    dataEntryFormVariables,
    v => v.dataEntryFormVariableId
  );

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    let dataPoint: AddDataCommandDataPoint = {
      variableValues: [],
    };

    for (const variable of formModel.variables) {
      const variableType =
        dataEntryFormVariablesById[variable.dataEntryFormVariableId].typeCode;

      dataPoint.variableValues.push({
        key: variable.dataEntryFormVariableId,
        value:
          variableType === 'Numeric' ? Number(variable.value) : variable.value,
      });
    }

    apiRequest.makeRequest({
      body: {
        projectId: project.projectId,
        data: [dataPoint],
      },
      onSuccess: () => {
        formikHelpers.setSubmitting(false);
        setAdded(true);
        setShouldRefreshOnClose(true);
      },
      onError: () => {
        formikHelpers.setSubmitting(false);
      },
    });
  };

  const addMoreData = () => setAdded(false);

  if (added) {
    return (
      <Modal isOpen={props.isOpen} close={close}>
        <ModalContent>
          <Container>
            <SuccessIconContainer>
              <SuccessIcon />
            </SuccessIconContainer>
            <ModalSubHeader>Data added successfully</ModalSubHeader>
            <AddMoreDataButton onClick={addMoreData} icon="true">
              <ReplayIcon /> Add more data
            </AddMoreDataButton>
          </Container>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={props.isOpen} close={close}>
      <Formik<FormModel>
        onSubmit={onSubmit}
        initialValues={getInitialValues(dataEntryFormVariables)}
      >
        {formikProps => (
          <Form>
            <ModalContent>
              <ModalHeader>Add Data</ModalHeader>
              {formikProps.values.variables.map((variable, index) => (
                <FormField key={variable.dataEntryFormVariableId}>
                  <Label>
                    {
                      dataEntryFormVariablesById[
                        variable.dataEntryFormVariableId
                      ].label
                    }
                  </Label>
                  {dataEntryFormVariablesById[variable.dataEntryFormVariableId]
                    .typeCode === 'Checkbox' ? (
                    <CheckboxField name={`variables.${index}.value`} />
                  ) : (
                    <InputField name={`variables.${index}.value`} />
                  )}
                </FormField>
              ))}
              <ApiErrorBox error={apiRequest.error} withMargin={true} />
            </ModalContent>
            <ModalFooter>
              <ButtonRowAlignRight>
                <SecondaryButton
                  onClick={close}
                  disabled={apiRequest.inProgress}
                >
                  Cancel
                </SecondaryButton>
                <FormSubmitButton>Save</FormSubmitButton>
              </ButtonRowAlignRight>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

type FormModel = {
  variables: Array<{
    dataEntryFormVariableId: number;
    value: string | boolean;
  }>;
};

const getInitialValues = (
  dataEntryFormVariables: Array<DataEntryFormVariableResponse>
): FormModel => ({
  variables: dataEntryFormVariables.map(dataEntryFormVariable => ({
    dataEntryFormVariableId: dataEntryFormVariable.dataEntryFormVariableId,
    value: dataEntryFormVariable.typeCode === 'Checkbox' ? false : '',
  })),
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${size200};
`;

const SuccessIconContainer = styled.div`
  width: ${size350};
  height: ${size350};
  border-radius: 100%;
  background-color: ${green900};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${size200} 0;
`;

const SuccessIcon = styled(TickIcon)`
  color: ${green400};
  height: 50%;
  width: 50%;
`;

const AddMoreDataButton = styled(PrimaryButton)`
  margin-top: ${size200};
`;

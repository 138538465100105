import React from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  SecondaryButton,
  PrimaryButton,
} from '../../shared/Buttons';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type UnarchiveTeamCommand = { teamId: number };

type Props = {
  teamId: number;
  close: (andRefresh: boolean) => void;
};

export const UnarchiveTeam = (props: Props) => {
  const unarchiveTeamRequest = usePostWithoutResponse<UnarchiveTeamCommand>(
    'UnarchiveTeam'
  );

  const submit = () => {
    unarchiveTeamRequest.makeRequest({
      body: { teamId: props.teamId },
      onSuccess: () => props.close(true),
    });
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Unarchive team</ModalHeader>
        <p>Are you sure you wish to unarchive this team?</p>
        <p>
          Once unarchived, users will once again be able to create new projects
          within this team.
        </p>
        <ApiErrorBox error={unarchiveTeamRequest.error} withMargin={true} />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={() => props.close(false)}
            disabled={unarchiveTeamRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={submit}
            disabled={unarchiveTeamRequest.inProgress}
          >
            Confirm
          </PrimaryButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

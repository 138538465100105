import React from 'react';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { AcceptProjectContributorInviteCommand } from './AcceptProjectContributorInviteCommand';
import { DeclineProjectContributorInviteCommand } from './DeclineProjectContributorInviteCommand';
import styled from 'styled-components/macro';
import { borderRadiusMedium } from '../../styling/borders';
import { size100, size150 } from '../../styling/sizes';
import { shadow000 } from '../../styling/shadows';
import { AppLink } from '../../shared/AppLink';
import {
  ButtonRowAlignRight,
  PrimaryButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { GetProjectsForCurrentUserAccountProjectContributorInviteResponse } from '../../features/projects/GetProjectsForCurrentUserAccountResponse';

type Props = {
  invite: GetProjectsForCurrentUserAccountProjectContributorInviteResponse;
  onAccepted: () => void;
  onDeclined: () => void;
};

export const ProjectInvite = ({ invite, onAccepted, onDeclined }: Props) => {
  const acceptInviteApiRequest = usePostWithoutResponse<
    AcceptProjectContributorInviteCommand
  >('AcceptProjectContributorInvite');

  const acceptInvite = () => {
    acceptInviteApiRequest.makeRequest({
      body: {
        projectContributorInviteId: invite.projectContributorInviteId,
      },
      onSuccess: onAccepted,
    });
  };

  const declineInviteApiRequest = usePostWithoutResponse<
    DeclineProjectContributorInviteCommand
  >('DeclineProjectContributorInvite');

  const declineInvite = () => {
    declineInviteApiRequest.makeRequest({
      body: {
        projectContributorInviteId: invite.projectContributorInviteId,
      },
      onSuccess: onDeclined,
    });
  };

  const requestInProgress =
    declineInviteApiRequest.inProgress || acceptInviteApiRequest.inProgress;

  return (
    <Container>
      <div>
        <strong>{invite.projectLeadUserAccountFullName}</strong> invites you to
        join{' '}
        <AppLink to={`/app/projects/${invite.projectId}`}>
          {invite.projectTitle}
        </AppLink>
      </div>
      <ButtonRowAlignRight>
        <SecondaryButton
          size="small"
          onClick={declineInvite}
          disabled={requestInProgress}
        >
          Decline
        </SecondaryButton>
        <PrimaryButton
          size="small"
          onClick={acceptInvite}
          disabled={requestInProgress}
        >
          Accept
        </PrimaryButton>
      </ButtonRowAlignRight>
    </Container>
  );
};

const Container = styled.div`
  background-color: white;
  border-radius: ${borderRadiusMedium};
  padding: ${size150};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${shadow000};

  &:not(:last-of-type) {
    margin-bottom: ${size100};
  }
`;

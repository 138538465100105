import React from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  SecondaryButton,
  PrimaryButton,
} from '../../shared/Buttons';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type ArchiveTeamCommand = { teamId: number };

type Props = {
  teamId: number;
  close: (andRefresh: boolean) => void;
};

export const ArchiveTeam = (props: Props) => {
  const archiveTeamRequest = usePostWithoutResponse<ArchiveTeamCommand>(
    'ArchiveTeam'
  );

  const submit = () => {
    archiveTeamRequest.makeRequest({
      body: { teamId: props.teamId },
      onSuccess: () => props.close(true),
    });
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Archive team</ModalHeader>
        <p>Are you sure you wish to archive this team?</p>
        <p>
          Once archived, users will be unable to create new projects within this
          team.
        </p>
        <ApiErrorBox error={archiveTeamRequest.error} withMargin={true} />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={() => props.close(false)}
            disabled={archiveTeamRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={submit}
            disabled={archiveTeamRequest.inProgress}
          >
            Confirm
          </PrimaryButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

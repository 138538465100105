import React, { useEffect, useState } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  ButtonRowAlignRight,
} from '../shared/Buttons';
import styled from 'styled-components/macro';
import {
  PlusIcon,
  GroupIcon,
  ProjectCompleteIcon,
  ProjectActiveIcon,
} from '../icons/Icons';
import { Section, SectionHeader } from '../shared/Section';
import { CardHeader, Card, CardTitle } from '../shared/Card';
import { Modal } from '../shared/Modal';
import { CreateProject } from './project/CreateProject';
import {
  Stats,
  Stat,
  StatIcon,
  StatItem,
  StatHeadline,
  StatSupportingText,
} from '../shared/Stats';
import { ActiveProjectsTable } from './project/ActiveProjectsTable';
import { CompletedProjectsTable } from './project/CompletedProjectsTable';
import { borderRadiusMedium } from '../styling/borders';
import { size100, size150 } from '../styling/sizes';
import { shadow000 } from '../styling/shadows';
import { AppLink } from '../shared/AppLink';
import { gray300 } from '../styling/colours';
import { NotificationsBadge } from '../shared/NotificationsBadge';
import { useGet } from '../api/useGet';
import { ApiErrorBox } from '../api/ApiErrorBox';
import { CenteredSpinner } from '../shared/Spinner';
import { GetProjectsForCurrentUserAccountResponse } from '../features/projects/GetProjectsForCurrentUserAccountResponse';
import produce from 'immer';
import { ProjectInvite } from './project/ProjectInvite';

export const ViewProjects = () => {
  const [modal, setModal] = useState<string | null>(null);

  const [
    cachedResponse,
    setCachedResponse,
  ] = useState<GetProjectsForCurrentUserAccountResponse | null>(null);

  const getProjectsForCurrentUserAccountRequest = useGet<
    GetProjectsForCurrentUserAccountResponse
  >('GetProjectsForCurrentUserAccount');

  const load = () => {
    getProjectsForCurrentUserAccountRequest.makeRequest({
      onSuccess: setCachedResponse,
    });
  };

  useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clearInvite = (projectContributorInviteId: number) => {
    if (cachedResponse == null) {
      return;
    }

    setCachedResponse(previousState => {
      return produce(previousState!, draft => {
        draft.projectContributorInvites = draft.projectContributorInvites.filter(
          invite =>
            invite.projectContributorInviteId !== projectContributorInviteId
        );
      });
    });
  };

  if (getProjectsForCurrentUserAccountRequest.error != null) {
    return (
      <ApiErrorBox error={getProjectsForCurrentUserAccountRequest.error} />
    );
  }

  if (
    getProjectsForCurrentUserAccountRequest.inProgress ||
    cachedResponse == null
  ) {
    return <CenteredSpinner />;
  }

  const {
    activeProjects,
    completedProjects,
    projectContributorJoinRequests,
    projectContributorInvites,
  } = cachedResponse;

  const totalProjectsContributedTo =
    activeProjects.length + completedProjects.length;

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>My Projects</CardTitle>
          <ActionsContainer>
            <PrimaryButton
              onClick={() => setModal('createProject')}
              icon="true"
            >
              <PlusIcon /> Create Project
            </PrimaryButton>
            <Modal
              isOpen={modal === 'createProject'}
              close={() => setModal(null)}
            >
              <CreateProject close={() => setModal(null)} />
            </Modal>
          </ActionsContainer>
        </CardHeader>
        <Stats>
          <Stat>
            <StatIcon>
              <ProjectActiveIcon />
            </StatIcon>
            <StatItem>
              <StatHeadline>{activeProjects.length}</StatHeadline>
              <StatSupportingText>
                active project{activeProjects.length === 1 ? '' : 's'}
              </StatSupportingText>
            </StatItem>
          </Stat>
          <Stat>
            <StatIcon>
              <ProjectCompleteIcon />
            </StatIcon>
            <StatItem>
              <StatHeadline>{completedProjects.length}</StatHeadline>
              <StatSupportingText>
                completed project{completedProjects.length === 1 ? '' : 's'}
              </StatSupportingText>
            </StatItem>
          </Stat>
          <Stat>
            <StatIcon>
              <GroupIcon />
            </StatIcon>
            <StatItem>
              <StatHeadline>{totalProjectsContributedTo}</StatHeadline>
              <StatSupportingText>
                total projects contributed to
              </StatSupportingText>
            </StatItem>
          </Stat>
        </Stats>
      </Card>
      {projectContributorJoinRequests.length > 0 && (
        <Section>
          <SectionHeader>
            Join Requests
            {projectContributorJoinRequests.length > 0 && (
              <NotificationsBadge>
                {projectContributorJoinRequests.length}
              </NotificationsBadge>
            )}
          </SectionHeader>
          {projectContributorJoinRequests.map(joinRequest => (
            <JoinRequest key={joinRequest.projectContributorJoinRequestId}>
              <div>
                <AppLink to={`/app/users/${joinRequest.userAccountId}`}>
                  {joinRequest.userAccountFullName}
                </AppLink>{' '}
                has requested to join{' '}
                <AppLink to={`/app/projects/${joinRequest.projectId}`}>
                  {joinRequest.projectTitle}
                </AppLink>
                <JoinRequestMessage>
                  {joinRequest.message || 'No message was provided.'}
                </JoinRequestMessage>
              </div>
              <ButtonRowAlignRight>
                <SecondaryButton
                  size="small"
                  onClick={() => {
                    alert('TODO');
                  }}
                >
                  Reject
                </SecondaryButton>
                <PrimaryButton
                  size="small"
                  onClick={() => {
                    alert('TODO');
                  }}
                >
                  Approve
                </PrimaryButton>
              </ButtonRowAlignRight>
            </JoinRequest>
          ))}
        </Section>
      )}
      {projectContributorInvites.length > 0 && (
        <Section>
          <SectionHeader>
            Invites
            {projectContributorInvites.length > 0 && (
              <NotificationsBadge>
                {projectContributorInvites.length}
              </NotificationsBadge>
            )}
          </SectionHeader>
          {projectContributorInvites.map(invite => (
            <ProjectInvite
              key={invite.projectContributorInviteId}
              invite={invite}
              onAccepted={load}
              onDeclined={() => clearInvite(invite.projectContributorInviteId)}
            />
          ))}
        </Section>
      )}
      <Section>
        <SectionHeader>Active Projects</SectionHeader>
        {activeProjects.length === 0 ? (
          <p>You don't have any active Projects at the moment.</p>
        ) : (
          <ActiveProjectsTable projects={activeProjects} />
        )}
      </Section>
      <Section>
        <SectionHeader>Completed Projects</SectionHeader>
        {completedProjects.length === 0 && (
          <p>You haven't completed any Projects yet.</p>
        )}
        {<CompletedProjectsTable projects={completedProjects} />}
      </Section>
    </>
  );
};

const ActionsContainer = styled(ButtonRowAlignRight)`
  flex: 1;
`;

const JoinRequest = styled.div`
  background-color: white;
  border-radius: ${borderRadiusMedium};
  padding: ${size150};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: ${shadow000};

  &:not(:last-of-type) {
    margin-bottom: ${size100};
  }
`;

const JoinRequestMessage = styled.div`
  margin-top: ${size100};
  font-style: italic;
  white-space: pre-wrap;
  color: ${gray300};
`;

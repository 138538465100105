export type ProjectCycle = {
  projectCycleId: string;
  projectId: string;
  cycleNumber: number;
  status: ProjectCycleStatus;
  createdDateTime: string;
};

export type ProjectCycleStatus =
  | 'Planning'
  | 'AwaitingApprovalToStartDoing'
  | 'Doing'
  | 'Studying'
  | 'Acting'
  | 'AwaitingApprovalToCompleteProject'
  | 'Complete';

export const projectCycleStatusDisplayNames: {
  [status in ProjectCycleStatus]: string;
} = {
  Planning: 'Plan Phase',
  AwaitingApprovalToStartDoing: 'Awaiting Plan Approval',
  Doing: 'Do Phase',
  Studying: 'Study Phase',
  Acting: 'Act Phase',
  AwaitingApprovalToCompleteProject: 'Awaiting Completion Approval',
  Complete: 'Complete',
};

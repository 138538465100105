import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { size250, size350 } from '../styling/sizes';

export const Spinner = () => (
  <Chase>
    <ChaseDot />
    <ChaseDot />
    <ChaseDot />
    <ChaseDot />
    <ChaseDot />
    <ChaseDot />
  </Chase>
);

export const CenteredSpinner = () => (
  <CenteredContainer>
    <Spinner />
  </CenteredContainer>
);

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${size350} ${size250};
`;

const chase = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const chaseDot = keyframes`
  80%, 100% {
    transform: rotate(360deg);
  }
`;

const chaseDotBefore = keyframes`
  50% {
    transform: scale(0.4);
  } 100%, 0% {
    transform: scale(1.0);
  }
`;

const Chase = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  animation: ${chase} 2.5s infinite linear both;
`;

const ChaseDot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: ${chaseDot} 2s infinite ease-in-out both;

  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: currentColor;
    border-radius: 100%;
    animation: ${chaseDotBefore} 2s infinite ease-in-out both;
  }

  &:nth-child(1) {
    animation-delay: -1.1s;
  }
  &:nth-child(2) {
    animation-delay: -1s;
  }
  &:nth-child(3) {
    animation-delay: -0.9s;
  }
  &:nth-child(4) {
    animation-delay: -0.8s;
  }
  &:nth-child(5) {
    animation-delay: -0.7s;
  }
  &:nth-child(6) {
    animation-delay: -0.6s;
  }
  &:nth-child(1):before {
    animation-delay: -1.1s;
  }
  &:nth-child(2):before {
    animation-delay: -1s;
  }
  &:nth-child(3):before {
    animation-delay: -0.9s;
  }
  &:nth-child(4):before {
    animation-delay: -0.8s;
  }
  &:nth-child(5):before {
    animation-delay: -0.7s;
  }
  &:nth-child(6):before {
    animation-delay: -0.6s;
  }
`;

import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form } from '../../form/Form';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import { ButtonRowAlignRight, SecondaryButton } from '../../shared/Buttons';
import { FormField } from '../../form/FormField';
import { TextAreaField } from '../../form/TextAreaField';
import { Label } from '../../form/Label';
import { GetProjectResponse } from '../../features/projects/GetProjectResponse';
import { FormSubmitButton } from '../../form/FormSubmitButton';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type Props = {
  project: GetProjectResponse;
  onSuccess: () => void;
  close: () => void;
};

export const RequestToJoinProject = (props: Props) => {
  const apiRequest = usePostWithoutResponse<RequestToJoinProjectCommand>(
    'RequestToJoinProject'
  );

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    const command: RequestToJoinProjectCommand = {
      projectId: props.project.projectId,
      message: formModel.message.trim() || null,
    };

    apiRequest.makeRequest({
      body: command,
      onSuccess: props.onSuccess,
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  return (
    <Formik<FormModel> onSubmit={onSubmit} initialValues={{ message: '' }}>
      {formikProps => (
        <Form>
          <ModalContent>
            <ModalHeader>Request to join</ModalHeader>
            <p>
              You are requesting to join this project as a contributor. If you
              would like to include a message with your request, please enter it
              in the text box below.
            </p>
            <p>
              Your request to join the project will be sent to{' '}
              <strong>{props.project.leadUserAccount.fullName}</strong> for
              approval. You will receive a notification once they have made
              their decision.
            </p>
            <br />
            <FormField>
              <Label>Message (optional)</Label>
              <TextAreaField
                name="message"
                placeholder="Enter an optional message here."
              />
            </FormField>
            <ApiErrorBox error={apiRequest.error} withMargin={true} />
          </ModalContent>
          <ModalFooter>
            <ButtonRowAlignRight>
              <SecondaryButton
                onClick={props.close}
                disabled={formikProps.isSubmitting}
              >
                Cancel
              </SecondaryButton>
              <FormSubmitButton>Send request</FormSubmitButton>
            </ButtonRowAlignRight>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

type FormModel = {
  message: string;
};

type RequestToJoinProjectCommand = {
  projectId: number;
  message: string | null;
};

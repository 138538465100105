export const assertNotNull = <TValue>(
  value: TValue | null | undefined
): TValue => {
  if (value == null) {
    throw new Error(
      `Expected value to be non-null, but was ${JSON.stringify(value)}`
    );
  }

  return value;
};

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  PrimaryButton,
  SecondaryButton,
  ButtonRowAlignRight,
} from '../../../shared/Buttons';
import styled from 'styled-components/macro';
import { TickIcon, PencilIcon, CrossIcon } from '../../../icons/Icons';
import { Card, CardHeader, CardTitle, CardContent } from '../../../shared/Card';
import {
  NeutralBadge,
  PrimaryBadge,
  SuccessBadge,
} from '../../../shared/Badge';
import { Modal } from '../../../shared/Modal';
import { UserAccountContext } from '../../../features/userAccounts/UserAccountContext';
import { PlanDetails } from './PlanDetails';
import { SubmitProjectCyclePlanForApproval } from '../../../features/projects/SubmitProjectCyclePlanForApproval';
import { CancelProjectCyclePlanApprovalRequest } from '../../../features/projects/CancelProjectCyclePlanApprovalRequest';
import { ProjectContext } from '../ProjectContext';

export const PlanSection = () => {
  const {
    getProject,
    getCycle,
    hardReloadProject: refreshProject,
  } = useContext(ProjectContext);

  const project = getProject();
  const cycle = getCycle();

  const [modal, setModal] = useState<string | null>(null);

  const currentUserAccountId = useContext(
    UserAccountContext
  ).getUserAccountId();
  const currentUserIsProjectLead =
    currentUserAccountId === project.leadUserAccount.userAccountId;

  const closeModal = (andRefresh: boolean) => {
    setModal(null);

    if (andRefresh) {
      refreshProject();
    }
  };

  const currentCyclePlan = cycle.plan;
  const currentProjectCyclePlanId = currentCyclePlan?.projectCyclePlanId;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Plan</CardTitle>
        {cycle.status === 'Planning' ? (
          <NeutralBadge>In Progress</NeutralBadge>
        ) : cycle.status === 'AwaitingApprovalToStartDoing' ? (
          <PrimaryBadge>Awaiting approval</PrimaryBadge>
        ) : (
          <SuccessBadge>Complete</SuccessBadge>
        )}
        {currentCyclePlan != null &&
          currentUserIsProjectLead &&
          (cycle.status === 'Planning' ||
            cycle.status === 'AwaitingApprovalToStartDoing') && (
            <ActionButtons>
              {cycle.status === 'Planning' ? (
                <>
                  <EditButton
                    as={Link}
                    to={`/app/projects/${project.projectId}/plan`}
                    icon="true"
                  >
                    <PencilIcon />
                    Edit
                  </EditButton>
                  <PrimaryButton
                    key="submit-for-approval"
                    onClick={() => setModal('submitForApproval')}
                    icon="true"
                  >
                    <TickIcon />
                    Submit for approval
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton
                  key="cancel-approval-request"
                  onClick={() => setModal('cancelApprovalRequest')}
                  icon="true"
                >
                  <CrossIcon />
                  Cancel request for approval
                </PrimaryButton>
              )}
              {currentProjectCyclePlanId != null && (
                <>
                  <Modal
                    isOpen={modal === 'submitForApproval'}
                    close={() => closeModal(false)}
                  >
                    <SubmitProjectCyclePlanForApproval
                      projectCyclePlanId={currentProjectCyclePlanId}
                      close={closeModal}
                    />
                  </Modal>
                  <Modal
                    isOpen={modal === 'cancelApprovalRequest'}
                    close={() => closeModal(false)}
                  >
                    <CancelProjectCyclePlanApprovalRequest
                      projectCyclePlanId={currentProjectCyclePlanId}
                      close={closeModal}
                    />
                  </Modal>
                </>
              )}
            </ActionButtons>
          )}
      </CardHeader>
      <CardContent>
        {currentCyclePlan == null ? (
          currentUserIsProjectLead ? (
            <>
              <p>You haven't created a Plan yet.</p>
              <PrimaryButton
                as={Link}
                to={`/app/projects/${project.projectId}/plan`}
              >
                Create Plan
              </PrimaryButton>
            </>
          ) : (
            <>The project lead has not created a Plan yet.</>
          )
        ) : (
          <>
            <PlanDetails
              plan={currentCyclePlan}
              variables={currentCyclePlan.dataEntryForm.variables!}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

const ActionButtons = styled(ButtonRowAlignRight)`
  flex: 1;
`;

const EditButton = styled(SecondaryButton)``;

import React from 'react';
import { useField, useFormikContext } from 'formik';
import { TextArea } from '../shared/TextArea';
import { FieldError } from './FieldError';

type Props = {
  name: string;
  placeholder?: string;
};

export const TextAreaField = (props: Props) => {
  const formikContext = useFormikContext();
  const [field, meta] = useField(props);

  const isInvalid = meta.touched && meta.error != null;

  return (
    <>
      <TextArea
        {...props}
        {...field}
        id={field.name}
        placeholder={props.placeholder}
        validityState={isInvalid ? 'invalid' : 'untouched'}
        disabled={formikContext.isSubmitting}
      />
      <FieldError meta={meta} />
    </>
  );
};

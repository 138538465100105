import styled from 'styled-components/macro';
import { size050, size100, size150 } from '../styling/sizes';
import { gray300, gray500, gray800, gray900 } from '../styling/colours';
import { transitionMedium } from '../styling/transitions';
import { borderRadiusMedium } from '../styling/borders';

export const PopoverMenuOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${size100} ${size150};
  background-color: white;
  cursor: pointer;
  transition: all ${transitionMedium} ease;
  color: ${gray500};

  &:hover {
    background-color: ${gray900};
    color: ${gray300};
  }

  svg {
    height: 20px;
    width: 20px;
    position: relative;
    opacity: 0.8;
    margin-right: ${size050};
  }
`;

export const PopoverMenu = styled.div`
  width: 250px;

  ${PopoverMenuOption} {
    border-bottom: solid 1px ${gray800};

    &:first-of-type {
      border-top-left-radius: ${borderRadiusMedium};
      border-top-right-radius: ${borderRadiusMedium};
    }

    &:last-of-type {
      border-bottom-left-radius: ${borderRadiusMedium};
      border-bottom-right-radius: ${borderRadiusMedium};
      border-bottom: none;
    }
  }
`;

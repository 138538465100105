import React from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  IconButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { PlainTable, TBody, Td, Th, THead, Tr } from '../../shared/Table';
import { Validator } from 'fluentvalidation-ts';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import { Form } from '../../form/Form';
import { InputField } from '../../form/InputField';
import styled from 'styled-components/macro';
import { CrossIcon, PlusIcon } from '../../icons/Icons';
import { size100 } from '../../styling/sizes';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { FormSubmitButton } from '../../form/FormSubmitButton';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type CreateUserAccountsCommand = {
  users: Array<{ emailAddress: string; fullName: string }>;
};

type Props = {
  close: (andRefresh: boolean) => void;
};

export const InviteUserAccounts = (props: Props) => {
  const createUserAccountsRequest = usePostWithoutResponse<
    CreateUserAccountsCommand
  >('CreateUserAccounts');

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    createUserAccountsRequest.makeRequest({
      body: formModel,
      onSuccess: () => props.close(true),
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  return (
    <Formik<FormModel>
      initialValues={{ users: [blankUserFormModel] }}
      onSubmit={onSubmit}
      validate={formValidator.validate}
    >
      {formikProps => (
        <Form showFieldErrors={false}>
          <ModalContent>
            <ModalHeader>Invite users</ModalHeader>

            <FieldArray name="users">
              {fieldArrayHelpers => (
                <div>
                  <PlainTable>
                    <THead>
                      <Tr>
                        <Th>Email address</Th>
                        <Th>Name (optional)</Th>
                        <DeleteButtonHeader />
                      </Tr>
                    </THead>
                    <TBody>
                      {formikProps.values.users.map((user, index) => (
                        <Tr key={index}>
                          <Td>
                            <InputField
                              name={`users.${index}.emailAddress`}
                              type="email"
                            />
                          </Td>
                          <Td>
                            <InputField name={`users.${index}.fullName`} />
                          </Td>
                          <DeleteButtonCell>
                            <IconButton
                              onClick={() => fieldArrayHelpers.remove(index)}
                              disabled={
                                formikProps.isSubmitting ||
                                formikProps.values.users.length === 1
                              }
                            >
                              <CrossIcon />
                            </IconButton>
                          </DeleteButtonCell>
                        </Tr>
                      ))}
                    </TBody>
                  </PlainTable>
                  <AddAnotherButton
                    icon="true"
                    onClick={() => fieldArrayHelpers.push(blankUserFormModel)}
                    disabled={formikProps.isSubmitting}
                  >
                    <PlusIcon />
                    Add another
                  </AddAnotherButton>
                </div>
              )}
            </FieldArray>
            <ApiErrorBox
              error={createUserAccountsRequest.error}
              withMargin={true}
            />
          </ModalContent>
          <ModalFooter>
            <ButtonRowAlignRight>
              <SecondaryButton
                onClick={() => props.close(false)}
                disabled={formikProps.isSubmitting}
              >
                Cancel
              </SecondaryButton>
              <FormSubmitButton
                disabled={formikProps.values.users.length === 0}
              >
                {formikProps.isSubmitting ? 'Sending...' : 'Send invites'}
              </FormSubmitButton>
            </ButtonRowAlignRight>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

type UserFormModel = {
  emailAddress: string;
  fullName: string;
};

const blankUserFormModel: UserFormModel = {
  emailAddress: '',
  fullName: '',
};

class UserValidator extends Validator<UserFormModel> {
  constructor() {
    super();

    this.ruleFor('emailAddress')
      .notEmpty()
      .withMessage('Please enter a valid email address')
      .emailAddress()
      .withMessage('Please enter a valid email address');
  }
}

const userValidator = new UserValidator();

type FormModel = {
  users: Array<UserFormModel>;
};

class FormValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleForEach('users').setValidator(() => userValidator);
  }
}

const formValidator = new FormValidator();

const DeleteButtonHeader = styled(Th)`
  width: 25px;
`;

const DeleteButtonCell = styled(Td)`
  padding: 0;
  position: relative;
  top: 2px;
`;

const AddAnotherButton = styled(SecondaryButton)`
  width: 100%;
  margin-top: ${size100};
`;

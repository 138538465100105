import { ApiErrorResult } from './ApiErrorResult';
import { useApiRequest } from './useApiRequest';

type MakeRequestArguments<TRequest extends object, TResponse> = {
  query?: { [key: string]: string };
  body: TRequest;
  onSuccess?: (response: TResponse) => void;
  onError?: (error: ApiErrorResult) => void;
};

export const usePostWithResponse = <TRequest extends object, TResponse>(
  endpoint: string
) => {
  const apiRequest = useApiRequest<TRequest, TResponse>(endpoint, 'post');

  const makeRequest = (args: MakeRequestArguments<TRequest, TResponse>) => {
    apiRequest.makeRequest(args);
  };

  const { response, inProgress, error } = apiRequest;

  return { makeRequest, response, inProgress, error };
};

import React from 'react';
import { useFormikContext } from 'formik';
import { SecondaryButton } from '../shared/Buttons';

type Props = {
  onClick: () => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

export const FormCancelButton = (props: Props) => {
  const formikContext = useFormikContext();

  return (
    <SecondaryButton
      onClick={props.onClick}
      disabled={props.disabled || formikContext.isSubmitting}
      className={props.className}
    >
      {props.children}
    </SecondaryButton>
  );
};

import styled from 'styled-components/macro';
import { Label } from './Label';
import { size050, size250 } from '../styling/sizes';
import { fontSize100 } from '../styling/fontSizes';
import { letterSpacingWide } from '../styling/letterSpacing';
import { gray200 } from '../styling/colours';

export const FormField = styled.div`
  ${Label} {
    font-size: ${fontSize100};
    margin-bottom: ${size050};
    letter-spacing: ${letterSpacingWide};
    color: ${gray200};
  }

  margin-bottom: ${size250};
`;

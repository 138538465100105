import styled from 'styled-components/macro';
import {
  size200,
  size350,
  size100,
  size250,
  size150,
  size000,
} from '../styling/sizes';
import {
  gray800,
  primary900,
  primary400,
  gray300,
  gray500,
} from '../styling/colours';
import { fontSize600, fontSize400 } from '../styling/fontSizes';
import { fontWeightBold } from '../styling/fontWeights';
import { letterSpacingWider } from '../styling/letterSpacing';

export const Stats = styled.div`
  display: flex;
`;

export const Stat = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: ${size200};
  border-right: solid 1px ${gray800};

  &:last-of-type {
    border-right: none;
  }
`;

export const StatIcon = styled.div`
  width: ${size350};
  height: ${size350};
  border-radius: 100%;
  background-color: ${primary900};
  margin: 0 ${size100};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${primary400};
    height: ${size250};
    width: ${size250};
  }
`;

export const StatItem = styled.div`
  flex: 1;
  padding-left: ${size150};
`;

export const StatHeadline = styled.div`
  font-size: ${fontSize600};
  font-weight: ${fontWeightBold};
  color: ${gray300};
  letter-spacing: ${letterSpacingWider};
`;

export const StatSubheadline = styled.span`
  font-size: ${fontSize400};
  font-weight: ${fontWeightBold};
  color: ${gray500};
  letter-spacing: ${letterSpacingWider};
`;

export const StatSupportingText = styled.div`
  margin-top: ${size000};
  color: ${gray500};
`;

import React, { useEffect } from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import { ButtonRowAlignRight, SecondaryButton } from '../../shared/Buttons';
import { usePostWithResponse } from '../../api/usePostWithResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { CenteredSpinner } from '../../shared/Spinner';
import { Form } from '../../form/Form';
import { Formik, FormikHelpers } from 'formik';
import {
  SelectField,
  SelectOptions,
  withBlankOption,
} from '../../form/SelectField';
import { Validator } from 'fluentvalidation-ts';
import { FormField } from '../../form/FormField';
import { Label } from '../../form/Label';
import { TextAreaField } from '../../form/TextAreaField';
import { FormSubmitButton } from '../../form/FormSubmitButton';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { AppLink } from '../../shared/AppLink';
import styled from 'styled-components/macro';
import { size000, size050, size150, size200 } from '../../styling/sizes';
import { gray300, gray800 } from '../../styling/colours';
import { ActDataWriteUp } from '../../app/project/acting/ActDataWriteUp';
import { ActDataAttachments } from '../../app/project/acting/ActDataAttachments';
import { fontWeightBold } from '../../styling/fontWeights';
import { fontSize200 } from '../../styling/fontSizes';

type GetProjectCompletionRequestDetailsQuery = {
  projectCycleId: number;
};

type GetProjectCompletionRequestDetailsResponse = {
  projectCompletionRequestId: number;
  project: {
    projectId: number;
    title: string;
    description: string;
    leadUserAccount: {
      userAccountId: number;
      fullName: string;
    };
  };
  actData: {
    writeUp: string;
    attachments: Array<{
      projectCycleActDataAttachmentId: number;
      fileName: string;
      fileSizeInBytes: number;
    }>;
  };
};

type ApproveOrRejectRequestCommand = {
  projectId: number;
};

type Props = {
  projectCycleId: number;
  close: (andRefresh: boolean) => void;
};

export const ReviewProjectCompletionRequest = ({
  projectCycleId,
  close,
}: Props) => {
  const getDetailsRequest = usePostWithResponse<
    GetProjectCompletionRequestDetailsQuery,
    GetProjectCompletionRequestDetailsResponse
  >('GetProjectCompletionRequestDetails');

  useEffect(() => {
    getDetailsRequest.makeRequest({ body: { projectCycleId } });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const approveRequest = usePostWithoutResponse<ApproveOrRejectRequestCommand>(
    'ApproveProjectCompletionRequest'
  );

  const rejectRequest = usePostWithoutResponse<ApproveOrRejectRequestCommand>(
    'RejectProjectCompletionRequest'
  );

  if (getDetailsRequest.error) {
    return (
      <>
        <ModalContent>
          <ModalHeader>Review completion request</ModalHeader>
          <ApiErrorBox error={getDetailsRequest.error} />
        </ModalContent>
      </>
    );
  }

  if (getDetailsRequest.inProgress) {
    return (
      <>
        <ModalContent>
          <ModalHeader>Review completion request</ModalHeader>
          <CenteredSpinner />
        </ModalContent>
      </>
    );
  }

  const response = getDetailsRequest.response;

  if (response == null) {
    return null;
  }

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    const apiRequest =
      formModel.decision === 'Approve' ? approveRequest : rejectRequest;

    apiRequest.makeRequest({
      body: {
        projectId: response.project.projectId,
        // TODO: Pass reason as well
      },
      onSuccess: () => close(true),
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  return (
    <Formik<FormModel>
      initialValues={initialFormModel}
      onSubmit={onSubmit}
      validate={formValidator.validate}
    >
      <Form>
        <ModalContent>
          <ModalHeader>Review completion request</ModalHeader>
          <p>
            <strong>{response.project.leadUserAccount.fullName}</strong> is
            requesting approval to complete the project{' '}
            <strong>
              <AppLink to={`/app/projects/${response.project.projectId}`}>
                {response.project.title}
              </AppLink>
            </strong>
            .
          </p>
          <br />
          <ActData>
            <Row>
              <Heading>Write-Up</Heading>
              <ActDataWriteUp>{response.actData.writeUp}</ActDataWriteUp>
            </Row>
            <Row>
              <Heading>Supporting documents</Heading>
              <ActDataAttachments
                projectCycleId={projectCycleId}
                attachments={response.actData.attachments}
              />
            </Row>
          </ActData>
          <br />
          <FormField>
            <Label>Decision</Label>
            <SelectField<string> name="decision" options={decisionOptions} />
          </FormField>
          <FormField>
            <Label>Comments</Label>
            <TextAreaField name="comments" />
          </FormField>
          <ApiErrorBox error={approveRequest.error} withMargin={true} />
        </ModalContent>
        <ModalFooter>
          <ButtonRowAlignRight>
            <SecondaryButton
              onClick={() => close(false)}
              disabled={approveRequest.inProgress}
            >
              Cancel
            </SecondaryButton>
            <FormSubmitButton>Confirm</FormSubmitButton>
          </ButtonRowAlignRight>
        </ModalFooter>
      </Form>
    </Formik>
  );
};

type FormModel = {
  decision: string;
  comments: string;
};

const initialFormModel: FormModel = {
  decision: '',
  comments: '',
};

const decisionOptions: SelectOptions<string> = withBlankOption<string>(
  [
    { text: 'Approve', value: 'Approve' },
    { text: 'Reject', value: 'Reject' },
  ],
  ''
);

class FormValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleFor('decision')
      .notEmpty()
      .withMessage('Please select a value');

    this.ruleFor('comments')
      .notEmpty()
      .withMessage('Please give a reason for the rejection')
      .when(m => m.decision === 'Reject');
  }
}

const formValidator = new FormValidator();

const ActData = styled.div`
  border-top: solid 1px ${gray800};
  border-bottom: solid 1px ${gray800};
  margin-bottom: ${size150};
  padding: ${size150} 0;
`;

const Heading = styled.div`
  color: ${gray300};
  font-weight: ${fontWeightBold};
  margin: 0 0 ${size000};
  padding-bottom: ${size050};
  font-size: ${fontSize200};
`;

const Row = styled.div`
  padding-bottom: ${size200};
`;

import styled from 'styled-components/macro';
import { size250, size050, size100, size000 } from '../styling/sizes';
import { gray900, gray700, gray300, gray500 } from '../styling/colours';
import { ChevronRightIcon } from '../icons/Icons';
import { AppLink } from './AppLink';
import { fontSize100 } from '../styling/fontSizes';

export const Breadcrumbs = styled.div`
  margin: -${size250} -${size250} ${size250} -${size250};
  background: ${gray900};
  padding: ${size100} ${size250};
  border-bottom: solid 2px ${gray700};
  color: ${gray300};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BreadcrumbLink = styled(AppLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${fontSize100};
  text-decoration: none;

  svg {
    margin-right: ${size000};
  }
`;

export const BreadcrumbDivider = styled(ChevronRightIcon)`
  color: ${gray500};
  margin: 0 ${size050};
  height: 20px;
`;

export const BreadcrumbItem = styled.div`
  color: ${gray500};
  font-size: ${fontSize100};
`;

import React, { useEffect } from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import { ButtonRowAlignRight, SecondaryButton } from '../../shared/Buttons';
import { DataEntryFormVariableTypeCode } from '../../model/DataEntryForm';
import { usePostWithResponse } from '../../api/usePostWithResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { CenteredSpinner } from '../../shared/Spinner';
import { PlanDetails } from '../../app/project/planning/PlanDetails';
import { Form } from '../../form/Form';
import { Formik, FormikHelpers } from 'formik';
import {
  SelectField,
  SelectOptions,
  withBlankOption,
} from '../../form/SelectField';
import { Validator } from 'fluentvalidation-ts';
import { FormField } from '../../form/FormField';
import { Label } from '../../form/Label';
import { TextAreaField } from '../../form/TextAreaField';
import { FormSubmitButton } from '../../form/FormSubmitButton';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';

type GetProjectCyclePlanApprovalRequestDetailsQuery = {
  projectCycleId: number;
};

type GetProjectCyclePlanApprovalRequestDetailsResponse = {
  projectCyclePlanApprovalRequestId: number;
  project: {
    projectId: number;
    title: string;
    description: string;
    leadUserAccount: {
      userAccountId: number;
      fullName: string;
    };
  };
  plan: {
    projectCyclePlanId: number;
    projectCycleNumber: number;
    whichPatients: string;
    whoIsHelping: string;
    where: string;
    when: string;
    how: string;
    why: string;
    additionalInformation: string;
    variables: Array<{
      dataEntryFormVariableId: number;
      label: string;
      typeCode: DataEntryFormVariableTypeCode;
    }>;
  };
};

type ApproveOrRejectRequestCommand = {
  projectCyclePlanApprovalRequestId: number;
};

type Props = {
  projectCycleId: number;
  close: (andRefresh: boolean) => void;
};

export const ReviewPlanningRequest = ({ projectCycleId, close }: Props) => {
  const getDetailsRequest = usePostWithResponse<
    GetProjectCyclePlanApprovalRequestDetailsQuery,
    GetProjectCyclePlanApprovalRequestDetailsResponse
  >('GetProjectCyclePlanApprovalRequestDetails');

  useEffect(() => {
    getDetailsRequest.makeRequest({ body: { projectCycleId } });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const approveRequest = usePostWithoutResponse<ApproveOrRejectRequestCommand>(
    'ApproveProjectCyclePlanApprovalRequest'
  );

  const rejectRequest = usePostWithoutResponse<ApproveOrRejectRequestCommand>(
    'RejectProjectCyclePlanApprovalRequest'
  );

  if (getDetailsRequest.error) {
    return (
      <>
        <ModalContent>
          <ModalHeader>Review planning request</ModalHeader>
          <ApiErrorBox error={getDetailsRequest.error} />
        </ModalContent>
      </>
    );
  }

  if (getDetailsRequest.inProgress) {
    return (
      <>
        <ModalContent>
          <ModalHeader>Review planning request</ModalHeader>
          <CenteredSpinner />
        </ModalContent>
      </>
    );
  }

  const response = getDetailsRequest.response;

  if (response == null) {
    return null;
  }

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    const apiRequest =
      formModel.decision === 'Approve' ? approveRequest : rejectRequest;

    apiRequest.makeRequest({
      body: {
        projectCyclePlanApprovalRequestId:
          response.projectCyclePlanApprovalRequestId,
      },
      onSuccess: () => close(true),
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  return (
    <Formik<FormModel>
      initialValues={initialFormModel}
      onSubmit={onSubmit}
      validate={formValidator.validate}
    >
      <Form>
        <ModalContent>
          <ModalHeader>Review planning request</ModalHeader>
          <p>
            <strong>{response.project.leadUserAccount.fullName}</strong> is
            requesting planning approval for{' '}
            <strong>Cycle {response.plan.projectCycleNumber}</strong> of the
            project <strong>{response.project.title}</strong>.
          </p>
          <br />
          <PlanDetails
            plan={response.plan}
            variables={response.plan.variables}
          />
          <br />
          <FormField>
            <Label>Decision</Label>
            <SelectField<string> name="decision" options={decisionOptions} />
          </FormField>
          <FormField>
            <Label>Comments</Label>
            <TextAreaField name="comments" />
          </FormField>
          <ApiErrorBox error={approveRequest.error} withMargin={true} />
        </ModalContent>
        <ModalFooter>
          <ButtonRowAlignRight>
            <SecondaryButton
              onClick={() => close(false)}
              disabled={approveRequest.inProgress}
            >
              Cancel
            </SecondaryButton>
            <FormSubmitButton>Confirm</FormSubmitButton>
          </ButtonRowAlignRight>
        </ModalFooter>
      </Form>
    </Formik>
  );
};

type FormModel = {
  decision: string;
  comments: string;
};

const initialFormModel: FormModel = {
  decision: '',
  comments: '',
};

const decisionOptions: SelectOptions<string> = withBlankOption<string>(
  [
    { text: 'Approve', value: 'Approve' },
    { text: 'Reject', value: 'Reject' },
  ],
  ''
);

class FormValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleFor('decision')
      .notEmpty()
      .withMessage('Please select a value');

    this.ruleFor('comments')
      .notEmpty()
      .withMessage('Please give a reason for the rejection')
      .when(m => m.decision === 'Reject');
  }
}

const formValidator = new FormValidator();

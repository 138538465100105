import React from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import { Validator } from 'fluentvalidation-ts';
import { Formik, FormikHelpers } from 'formik';
import { Form } from '../../form/Form';
import { FormSubmitButton } from '../../form/FormSubmitButton';
import { ButtonRowAlignRight, SecondaryButton } from '../../shared/Buttons';
import { InputField } from '../../form/InputField';
import { FormField } from '../../form/FormField';
import { Label } from '../../form/Label';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type CreateTeamCommand = { name: string };

type Props = {
  close: (andRefresh: boolean) => void;
};

export const CreateTeam = (props: Props) => {
  const createTeamRequest = usePostWithoutResponse<CreateTeamCommand>(
    'CreateTeam'
  );

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    createTeamRequest.makeRequest({
      body: formModel,
      onSuccess: () => props.close(true),
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  return (
    <Formik<FormModel>
      onSubmit={onSubmit}
      validate={formValidator.validate}
      initialValues={initialFormModel}
    >
      <Form>
        <ModalContent>
          <ModalHeader>New team</ModalHeader>
          <FormField>
            <Label>Name</Label>
            <InputField name="name" />
          </FormField>
          <ApiErrorBox error={createTeamRequest.error} withMargin={true} />
        </ModalContent>
        <ModalFooter>
          <ButtonRowAlignRight>
            <SecondaryButton
              onClick={() => props.close(false)}
              disabled={createTeamRequest.inProgress}
            >
              Cancel
            </SecondaryButton>
            <FormSubmitButton>Save</FormSubmitButton>
          </ButtonRowAlignRight>
        </ModalFooter>
      </Form>
    </Formik>
  );
};

type FormModel = {
  name: string;
};

const initialFormModel: FormModel = { name: '' };

class FormValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleFor('name')
      .notEmpty()
      .withMessage('Please enter a name for the new team');
  }
}

const formValidator = new FormValidator();

import { format } from 'date-fns';

export const getCurrentDateTime = () => {
  const now = new Date();
  return format(now, 'HH:mm dd/MM/yyyy');
};

export const formatAsShortDate = (timestamp: string) =>
  format(new Date(timestamp), 'dd/MM/yyyy');

export const formatAsShortDateTime = (timestamp: string) =>
  format(new Date(timestamp), "HH:mm 'on' dd/MM/yyyy");

import React, { useContext, useState } from 'react';
import { Modal } from '../../shared/Modal';
import { EditProject } from './EditProject';
import { NeutralBadge, SuccessBadge } from '../../shared/Badge';
import {
  CalendarIcon,
  ClipboardIcon,
  CrossIcon,
  GroupAddIcon,
  GroupIcon,
  PencilIcon,
  ReplayIcon,
  UserPlusIcon,
  UserTickIcon,
} from '../../icons/Icons';
import {
  ButtonBadge,
  ButtonRowAlignRight,
  PrimaryButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { size050, size150, size200, size300 } from '../../styling/sizes';
import { fontSize200, fontSize500 } from '../../styling/fontSizes';
import { fontWeightBold } from '../../styling/fontWeights';
import { gray200, gray500 } from '../../styling/colours';
import { find, includes } from 'lodash';
import { RequestToJoinProject } from './RequestToJoinProject';
import { ManageContributors } from './ManageContributors';
import { InviteContributor } from './InviteContributor';
import {
  CardHeader,
  Card,
  CardContent,
  CardContentHeading,
} from '../../shared/Card';
import { lineHeightMedium } from '../../styling/lineHeights';
import { GetProjectResponse } from '../../features/projects/GetProjectResponse';
import { UserAccountContext } from '../../features/userAccounts/UserAccountContext';
import { ProjectContext } from './ProjectContext';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { CancelProjectContributorJoinRequestCommand } from './CancelProjectContributorJoinRequestCommand';
import { formatAsShortDate } from '../../helpers/dateTimeHelpers';

type Props = {
  project: GetProjectResponse;
};

export const ProjectHeader = (props: Props) => {
  const { hardReloadProject } = useContext(ProjectContext);

  const { cycleNumberParam } = useParams();

  const { project } = props;

  const userAccountContext = useContext(UserAccountContext);
  const currentUserAccountId = userAccountContext.getUserAccountId();

  const isMyProject =
    currentUserAccountId === project.leadUserAccount.userAccountId;

  const projectLead = project.leadUserAccount;

  const [isEditProjectModalOpen, setIsEditProjectModalOpen] = useState(false);

  const [
    isManageContributorsModalOpen,
    setIsManageContributorsModalOpen,
  ] = useState(false);

  const [
    isInviteContributorModalOpen,
    setIsInviteContributorModalOpen,
  ] = useState(false);

  const cycleNumber =
    cycleNumberParam == null ? null : Number(cycleNumberParam);

  const totalNumberOfCycles = project.cycles.length;

  const contributors = project.contributors;
  const contributorUserAccountIds = contributors.map(pc => pc.userAccountId);

  const isContributor =
    isMyProject || includes(contributorUserAccountIds, currentUserAccountId);

  const firstCycle = find(project.cycles, cycle => cycle.cycleNumber === 1)!;

  const projectContributorJoinRequests = project.contributorJoinRequests;

  const joinRequest =
    find(
      projectContributorJoinRequests,
      r => r.userAccountId === currentUserAccountId
    ) || null;

  const hasRequestedToJoin = joinRequest != null;

  const cancelJoinRequestApiRequest = usePostWithoutResponse<
    CancelProjectContributorJoinRequestCommand
  >('CancelProjectContributorJoinRequest');

  const cancelJoinRequest = () => {
    if (joinRequest != null) {
      cancelJoinRequestApiRequest.makeRequest({
        body: {
          projectContributorJoinRequestId:
            joinRequest.projectContributorJoinRequestId,
        },
        onSuccess: hardReloadProject,
      });
    }
  };

  const [isRequestToJoinModalOpen, setIsRequestToJoinModalOpen] = useState(
    false
  );

  if (cycleNumber == null) {
    return (
      <Card>
        <ProjectCardHeader>
          <div>
            <ProjectTitle>
              {project.title}
              <ProjectBadge>
                <SuccessBadge>Complete</SuccessBadge>
              </ProjectBadge>
            </ProjectTitle>
            <ProjectStats>
              <ProjectStat>
                <GroupIcon />
                {project.teamName}
              </ProjectStat>
              <ProjectStat>
                <CalendarIcon /> Started{' '}
                {formatAsShortDate(firstCycle.createdDateTime)}
              </ProjectStat>
              <ProjectStat>
                <UserTickIcon /> Lead by {projectLead.fullName}
              </ProjectStat>
              <ProjectStat>
                <GroupIcon /> {contributors.length + 1} contributor
                {contributors.length > 0 ? 's' : ''}
              </ProjectStat>
            </ProjectStats>
          </div>
          <SecondaryButton
            as={Link}
            to={`/app/projects/${project.projectId}/cycles/${totalNumberOfCycles}`}
            icon="true"
            replace={true}
          >
            <ReplayIcon /> View cycles
          </SecondaryButton>
        </ProjectCardHeader>
        <CardContent>
          <CardContentHeading>Project Description</CardContentHeading>
          <ProjectDescription>{project.description}</ProjectDescription>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Modal
        isOpen={isEditProjectModalOpen}
        close={() => setIsEditProjectModalOpen(false)}
      >
        <EditProject close={() => setIsEditProjectModalOpen(false)} />
      </Modal>
      <Modal
        isOpen={isManageContributorsModalOpen}
        close={() => setIsManageContributorsModalOpen(false)}
      >
        <ManageContributors
          close={() => setIsManageContributorsModalOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={isInviteContributorModalOpen}
        close={() => setIsInviteContributorModalOpen(false)}
      >
        <InviteContributor
          close={() => setIsInviteContributorModalOpen(false)}
        />
      </Modal>
      <Card>
        <ProjectCardHeader>
          <div>
            <ProjectTitle>
              {project.title}
              <ProjectBadge>
                {project.isComplete ? (
                  <SuccessBadge>Complete</SuccessBadge>
                ) : (
                  <NeutralBadge>In progress</NeutralBadge>
                )}
              </ProjectBadge>
            </ProjectTitle>
            <ProjectStats>
              <ProjectStat>
                <GroupIcon />
                {project.teamName}
              </ProjectStat>
              <ProjectStat>
                <CalendarIcon /> Started{' '}
                {formatAsShortDate(firstCycle.createdDateTime)}
              </ProjectStat>
              <ProjectStat>
                <UserTickIcon /> Lead by {projectLead.fullName}
              </ProjectStat>
              <ProjectStat>
                <GroupIcon /> {contributors.length + 1} contributor
                {contributors.length > 0 ? 's' : ''}
              </ProjectStat>
            </ProjectStats>
          </div>
          {isMyProject && !project.isComplete && (
            <>
              <ButtonRowAlignRight>
                <SecondaryButton
                  icon="true"
                  onClick={() => setIsInviteContributorModalOpen(true)}
                >
                  <UserPlusIcon />
                  Invite contributor
                </SecondaryButton>
                <SecondaryButton
                  icon="true"
                  onClick={() => setIsManageContributorsModalOpen(true)}
                >
                  <GroupIcon />
                  Manage contributors
                  {projectContributorJoinRequests.length > 0 && (
                    <ButtonBadge>
                      {projectContributorJoinRequests.length}
                    </ButtonBadge>
                  )}
                </SecondaryButton>
                <SecondaryButton
                  icon="true"
                  onClick={() => setIsEditProjectModalOpen(true)}
                >
                  <PencilIcon /> Edit
                </SecondaryButton>
              </ButtonRowAlignRight>
            </>
          )}
          {project.isComplete && (
            <SecondaryButton
              as={Link}
              to={`/app/projects/${project.projectId}`}
              icon="true"
              replace={true}
            >
              <ClipboardIcon /> View write-up
            </SecondaryButton>
          )}
          {!project.isComplete &&
            !isContributor &&
            (hasRequestedToJoin ? (
              <SecondaryButton
                icon="true"
                onClick={cancelJoinRequest}
                disabled={cancelJoinRequestApiRequest.inProgress}
              >
                <CrossIcon /> Cancel request
              </SecondaryButton>
            ) : (
              <PrimaryButton
                icon="true"
                onClick={() => setIsRequestToJoinModalOpen(true)}
              >
                <GroupAddIcon /> Request to join
              </PrimaryButton>
            ))}
        </ProjectCardHeader>
        <CardContent>
          <CardContentHeading>Project Description</CardContentHeading>
          <ProjectDescription>{project.description}</ProjectDescription>
        </CardContent>
      </Card>
      <Modal
        isOpen={isRequestToJoinModalOpen}
        close={() => setIsRequestToJoinModalOpen(false)}
      >
        <RequestToJoinProject
          project={project}
          onSuccess={hardReloadProject}
          close={() => setIsRequestToJoinModalOpen(false)}
        />
      </Modal>
    </>
  );
};

const ProjectCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${size150} ${size200};
  height: auto;
`;

const ProjectDescription = styled.div`
  line-height: ${lineHeightMedium};
  white-space: pre-wrap;
`;

const ProjectTitle = styled.div`
  font-size: ${fontSize500};
  font-weight: ${fontWeightBold};
  margin-bottom: ${size150};
  color: ${gray200};
  display: flex;
  align-items: center;
`;

const ProjectStats = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;

const ProjectStat = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSize200};
  color: ${gray500};
  margin-right: ${size300};

  svg {
    margin-right: ${size050};
  }
`;

const ProjectBadge = styled.div`
  margin-left: ${size150};
`;

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  BuildingIcon,
  EmailIcon,
  PencilIcon,
  UserSquareIcon,
} from '../../icons/Icons';
import { size100, size200, size300, size400 } from '../../styling/sizes';
import { gray400, gray700 } from '../../styling/colours';
import { fontSize400 } from '../../styling/fontSizes';
import { Card, CardContent } from '../../shared/Card';
import { Modal } from '../../shared/Modal';
import { EditProfile } from './EditProfile';
import { ButtonRowAlignRight, PrimaryButton } from '../../shared/Buttons';
import { fontWeightBold } from '../../styling/fontWeights';
import { Section, SectionHeader } from '../../shared/Section';
import { useGet } from '../../api/useGet';
import { GetPublicUserAccountProfileResponse } from './GetPublicUserAccountProfileResponse';
import { UserAccountContext } from '../../features/userAccounts/UserAccountContext';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { CenteredSpinner } from '../../shared/Spinner';
import { ActiveProjectsTable } from '../project/ActiveProjectsTable';
import { CompletedProjectsTable } from '../project/CompletedProjectsTable';

export const ViewPublicProfile = () => {
  const { userAccountId } = useParams();
  const userAccountContext = useContext(UserAccountContext);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const apiRequest = useGet<GetPublicUserAccountProfileResponse>(
    'GetPublicUserAccountProfile'
  );

  const fetchData = () =>
    apiRequest.makeRequest({
      query: { userAccountId: Number(userAccountId).toString() },
    });

  useEffect(() => {
    fetchData();
  }, [userAccountId]); // eslint-disable-line react-hooks/exhaustive-deps

  const { response, error, inProgress } = apiRequest;

  if (error != null) {
    return <ApiErrorBox error={error} />;
  }

  if (inProgress || response == null) {
    return <CenteredSpinner />;
  }

  const isMe = Number(userAccountId) === userAccountContext.getUserAccountId();

  const {
    fullName,
    emailAddress,
    organisationName,
    // teams, TODO
    activeProjects,
    completedProjects,
  } = response;

  return (
    <>
      <Card>
        <CardContent>
          <ProfileCard>
            <Avatar />
            <div>
              <Name>{fullName}</Name>
              <DetailsRow>
                <EmailIcon /> {emailAddress}
              </DetailsRow>
              <DetailsRow>
                <BuildingIcon /> {organisationName}
              </DetailsRow>
              {/*<DetailsRow>*/}
              {/*  <BriefcaseIcon /> TODO*/}
              {/*</DetailsRow>*/}
            </div>
            <ButtonContainer>
              <ButtonRowAlignRight>
                {isMe && (
                  <PrimaryButton
                    icon="true"
                    onClick={() => setIsEditModalOpen(true)}
                  >
                    <PencilIcon /> Edit
                  </PrimaryButton>
                )}
              </ButtonRowAlignRight>
            </ButtonContainer>
          </ProfileCard>
        </CardContent>
      </Card>
      <Section>
        <SectionHeader>Active Projects</SectionHeader>
        {activeProjects.length === 0 ? (
          <p>
            {isMe ? `You don't` : `${fullName} doesn't`} have any active
            Projects at the moment.
          </p>
        ) : (
          <ActiveProjectsTable projects={activeProjects} />
        )}
      </Section>
      <Section>
        <SectionHeader>Completed Projects</SectionHeader>
        {completedProjects.length === 0 ? (
          <p>
            {isMe ? `You haven't` : `${fullName} hasn't`} completed any Projects
            yet.
          </p>
        ) : (
          <CompletedProjectsTable projects={completedProjects} />
        )}
      </Section>
      <Modal isOpen={isEditModalOpen} close={() => setIsEditModalOpen(false)}>
        <EditProfile
          close={() => setIsEditModalOpen(false)}
          onUpdated={fetchData}
          userAccountProfile={response}
        />
      </Modal>
    </>
  );
};

const ProfileCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Avatar = styled(UserSquareIcon)`
  height: ${size400};
  width: ${size400};
  fill: ${gray700};
  margin-left: ${size200};
  margin-right: ${size300};
`;

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${gray400};
  margin-bottom: ${size100};

  svg {
    height: ${size200};
    width: ${size200};
    margin-right: ${size100};
  }
`;

const Name = styled(DetailsRow)`
  font-size: ${fontSize400};
  font-weight: ${fontWeightBold};
`;

const ButtonContainer = styled.div`
  flex: 1;
`;

import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { FormField } from '../../form/FormField';
import { Label } from '../../form/Label';
import { InputField } from '../../form/InputField';
import { Form } from '../../form/Form';
import { ButtonRowAlignRight } from '../../shared/Buttons';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import { GetPublicUserAccountProfileResponse } from './GetPublicUserAccountProfileResponse';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { UpdateUserAccountCommand } from './UpdateUserAccountCommand';
import { Validator } from 'fluentvalidation-ts';
import { FormSubmitButton } from '../../form/FormSubmitButton';

type Props = {
  userAccountProfile: GetPublicUserAccountProfileResponse;
  onUpdated: () => void;
  close: () => void;
};

export const EditProfile = (props: Props) => {
  const apiRequest = usePostWithoutResponse<UpdateUserAccountCommand>(
    'UpdateUserAccount'
  );

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    apiRequest.makeRequest({
      body: {
        fullName: formModel.fullName,
      },
      onSuccess: () => {
        props.onUpdated();
        props.close();
      },
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  return (
    <Formik<FormModel>
      onSubmit={onSubmit}
      initialValues={{ fullName: props.userAccountProfile.fullName }}
      validate={formValidator.validate}
    >
      <Form>
        <ModalContent>
          <ModalHeader>Edit Profile</ModalHeader>
          <FormField>
            <Label>Full Name</Label>
            <InputField name="fullName" />
          </FormField>
        </ModalContent>
        <ModalFooter>
          <ButtonRowAlignRight>
            <FormSubmitButton>Save</FormSubmitButton>
          </ButtonRowAlignRight>
        </ModalFooter>
      </Form>
    </Formik>
  );
};

type FormModel = {
  fullName: string;
};

class FormValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleFor('fullName')
      .notNull()
      .notEmpty()
      .withMessage('This field is required');
  }
}

const formValidator = new FormValidator();

import React, { useContext } from 'react';
import { Form } from '../../../form/Form';
import { Formik, FormikHelpers } from 'formik';
import { find, keyBy, mapValues } from 'lodash';
import { FormField } from '../../../form/FormField';
import { Label } from '../../../form/Label';
import { CheckboxField } from '../../../form/CheckboxField';
import { InputField } from '../../../form/InputField';
import { ButtonRowAlignRight, SecondaryButton } from '../../../shared/Buttons';
import { ModalContent, ModalHeader, ModalFooter } from '../../../shared/Modal';
import { DataPointResponse } from './GetDataResponse';
import { ProjectContext } from '../ProjectContext';
import { DataEntryFormResponse } from '../../../features/projects/GetProjectResponse';
import { usePostWithoutResponse } from '../../../api/usePostWithoutResponse';
import { ApiErrorBox } from '../../../api/ApiErrorBox';
import { FormSubmitButton } from '../../../form/FormSubmitButton';

type Props = {
  dataPoint: DataPointResponse;
  close: () => void;
  onEdited: (newDataPoint: DataPointResponse) => void;
};

export const EditDataPoint = (props: Props) => {
  const { getProject, getCycle } = useContext(ProjectContext);
  const project = getProject();
  const currentCycle = getCycle();
  const dataEntryForm = currentCycle.plan!.dataEntryForm!;
  const variables = dataEntryForm.variables!;

  const variablesById = keyBy(variables, v => v.dataEntryFormVariableId);

  const editDataPointRequest = usePostWithoutResponse<EditDataPointCommand>(
    'EditData'
  );

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    const command: EditDataPointCommand = {
      projectId: project.projectId,
      cycleNumber: currentCycle.cycleNumber,
      dataPointId: props.dataPoint.id,
      variables: formModel.variables,
    };

    editDataPointRequest.makeRequest({
      body: command,
      onSuccess: () => {
        props.close();

        const newDataPoint: DataPointResponse = {
          ...props.dataPoint,
          valuesByDataEntryFormVariableId: mapValues(
            keyBy(formModel.variables, v => v.dataEntryFormVariableId),
            v => v.value
          ),
        };

        props.onEdited(newDataPoint);
      },
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  const initialValues = getInitialValues(dataEntryForm, props.dataPoint);

  return (
    <Formik<FormModel> onSubmit={onSubmit} initialValues={initialValues}>
      {formikProps => (
        <Form>
          <ModalContent>
            <ModalHeader>Edit Data</ModalHeader>
            {formikProps.values.variables.map((variable, index) => (
              <FormField key={variable.dataEntryFormVariableId}>
                <Label>
                  {variablesById[variable.dataEntryFormVariableId].label}
                </Label>
                {variablesById[variable.dataEntryFormVariableId].typeCode ===
                'Checkbox' ? (
                  <CheckboxField name={`variables.${index}.value`} />
                ) : (
                  <InputField name={`variables.${index}.value`} />
                )}
              </FormField>
            ))}
            <ApiErrorBox error={editDataPointRequest.error} withMargin={true} />
          </ModalContent>
          <ModalFooter>
            <ButtonRowAlignRight>
              <SecondaryButton
                onClick={props.close}
                disabled={editDataPointRequest.inProgress}
              >
                Cancel
              </SecondaryButton>
              <FormSubmitButton>Save</FormSubmitButton>
            </ButtonRowAlignRight>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

type FormModel = {
  variables: Array<{
    dataEntryFormVariableId: number;
    value: string | boolean;
  }>;
};

const getInitialValues = (
  dataEntryForm: DataEntryFormResponse,
  dataPoint: DataPointResponse
): FormModel => ({
  variables: Object.keys(dataPoint.valuesByDataEntryFormVariableId).map(
    dataEntryFormVariableId => {
      const variable = find(
        dataEntryForm.variables,
        v => v.dataEntryFormVariableId === Number(dataEntryFormVariableId)
      )!;
      const value =
        dataPoint.valuesByDataEntryFormVariableId[
          Number(dataEntryFormVariableId)
        ];

      return {
        dataEntryFormVariableId: Number(dataEntryFormVariableId),
        value: variable.typeCode === 'Checkbox' ? value === 'true' : value,
      };
    }
  ),
});

type EditDataPointCommand = {
  projectId: number;
  cycleNumber: number;
  dataPointId: string;
  variables: Array<{
    dataEntryFormVariableId: number;
    value: any;
  }>;
};

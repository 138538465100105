import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { debounce } from 'lodash';
import {
  OverlappingHeaderTop,
  OverlappingHeaderTitleBar,
  OverlappingHeaderTitleText,
  OverlappingHeaderTitleActions,
  OverlappingHeaderPanelTop,
  OverlappingHeaderBottom,
} from '../../layout/OverlappingHeader';
import { PrimaryButton } from '../../shared/Buttons';
import {
  GroupAddIcon,
  ChevronRightIcon,
  GroupIcon,
  UserTickIcon,
} from '../../icons/Icons';
import { OverlappingHeaderPanelBottom } from '../../layout/OverlappingHeader';
import {
  SearchInput,
  SearchInputLabel,
  CheckboxFiltersRow,
  CheckboxFilterLabel,
} from '../../shared/SearchPanel';
import { Checkbox } from '../../shared/Checkbox';
import {
  TableWithClickableRows,
  TBody,
  Tr,
  Td,
  THead,
  Th,
  ChevronCell,
  TableOverlay,
} from '../../shared/Table';
import {
  ConstrainedContainer,
  ConstrainedContainerContent,
} from '../../layout/ConstrainedContainer';
import { size000, size050, size250 } from '../../styling/sizes';
import { ChevronHeader } from '../../shared/Table';
import { usePostWithResponse } from '../../api/usePostWithResponse';
import { GetTeamsResponse } from './GetTeamsResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { Modal } from '../../shared/Modal';
import { CreateTeam } from './CreateTeam';
import { NeutralBadge, SuccessBadge, WarningBadge } from '../../shared/Badge';
import { gray300 } from '../../styling/colours';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

type GetTeamsQuery = {
  searchTerm: string | null;
  includeArchived: boolean;
};

export const ManageTeams = () => {
  const history = useHistory();

  const { showArchived, search } = qs.parse(useLocation().search, {
    decode: true,
  });

  const [searchTerm, setSearchTerm] = useState(
    search == null ? '' : (search as string)
  );
  const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);
  const [includeArchived, setIncludeArchived] = useState(
    showArchived == null ? false : showArchived === 'true'
  );

  const getTeamsRequest = usePostWithResponse<GetTeamsQuery, GetTeamsResponse>(
    'GetTeams'
  );

  const [latestResponse, setLatestResponse] = useState<GetTeamsResponse | null>(
    null
  );

  const makeRequest = () => {
    getTeamsRequest.makeRequest({
      body: {
        searchTerm: searchTerm.trim() === '' ? null : searchTerm.trim(),
        includeArchived,
      },
      onSuccess: response => setLatestResponse(response),
    });
  };

  useEffect(() => {
    makeRequest();

    const query = {
      showArchived: includeArchived,
      search: currentSearchTerm,
    };

    history.replace({ search: qs.stringify(query, { encode: true }) });
  }, [currentSearchTerm, includeArchived]); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedSetSearchTerm = useRef(
    debounce((value: string) => {
      setCurrentSearchTerm(value);
    }, 1_000)
  );

  useEffect(() => {
    debouncedSetSearchTerm.current(searchTerm);
  }, [searchTerm]);

  const onChangeSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);

  const closeCreateTeamModal = (andRefresh: boolean) => {
    if (andRefresh) {
      makeRequest();
    }

    setShowCreateTeamModal(false);
  };

  return (
    <>
      <OverlappingHeaderTop>
        <OverlappingHeaderTitleBar>
          <OverlappingHeaderTitleText>Teams</OverlappingHeaderTitleText>
          <OverlappingHeaderTitleActions>
            <PrimaryButton
              icon="true"
              onClick={() => setShowCreateTeamModal(true)}
            >
              <GroupAddIcon /> New team
            </PrimaryButton>
          </OverlappingHeaderTitleActions>
        </OverlappingHeaderTitleBar>
        <OverlappingHeaderPanelTop>
          <SearchInputLabel>Search teams</SearchInputLabel>
          <SearchInput
            value={searchTerm}
            placeholder="Search by team name"
            onChange={onChangeSearchInput}
          />
        </OverlappingHeaderPanelTop>
      </OverlappingHeaderTop>
      <OverlappingHeaderBottom>
        <OverlappingHeaderPanelBottom>
          <CheckboxFiltersRow>
            <CheckboxFilterLabel>
              <Checkbox
                checked={includeArchived}
                onChange={event => {
                  setIncludeArchived(event.target.checked);
                }}
              />
              Show archived
            </CheckboxFilterLabel>
          </CheckboxFiltersRow>
        </OverlappingHeaderPanelBottom>
      </OverlappingHeaderBottom>
      <ConstrainedContainer>
        <ConstrainedContainerContent>
          <TableOverlay show={getTeamsRequest.inProgress}>
            <TeamsTable>
              <THead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Members</Th>
                  <Th>Status</Th>
                  <ChevronHeader />
                </Tr>
              </THead>
              <TBody>
                {latestResponse == null ? (
                  <Tr>
                    <Td colSpan={4}>Loading...</Td>
                  </Tr>
                ) : latestResponse.teams.length === 0 ? (
                  <Tr>
                    <Td colSpan={4}>No results</Td>
                  </Tr>
                ) : (
                  latestResponse.teams.map(team => (
                    <Tr
                      key={team.teamId}
                      onClick={() => history.push(`/teams/${team.teamId}`)}
                    >
                      <Td>{team.name}</Td>
                      <Td>
                        <MembersRow>
                          <GroupIcon />
                          {team.usersCount} user
                          {team.usersCount === 1 ? '' : 's'}
                        </MembersRow>
                        <MembersRow>
                          <UserTickIcon />
                          {team.approversCount} approver
                          {team.approversCount === 1 ? '' : 's'}
                        </MembersRow>
                      </Td>
                      <Td>
                        {team.isArchived ? (
                          <NeutralBadge>Archived</NeutralBadge>
                        ) : team.approversCount === 0 ? (
                          <WarningBadge>No approvers</WarningBadge>
                        ) : (
                          <SuccessBadge>Active</SuccessBadge>
                        )}
                      </Td>
                      <ChevronCell>
                        <ChevronRightIcon />
                      </ChevronCell>
                    </Tr>
                  ))
                )}
              </TBody>
            </TeamsTable>
          </TableOverlay>
          <ApiErrorBox error={getTeamsRequest.error} withMargin={true} />
        </ConstrainedContainerContent>
      </ConstrainedContainer>
      <Modal
        isOpen={showCreateTeamModal}
        close={() => closeCreateTeamModal(false)}
      >
        <CreateTeam close={closeCreateTeamModal} />
      </Modal>
    </>
  );
};

const TeamsTable = styled(TableWithClickableRows)`
  margin-top: ${size250};
  width: 100%;
`;

const MembersRow = styled.div`
  display: flex;
  align-items: center;
  color: ${gray300};

  svg {
    margin-right: ${size050};
    opacity: 0.8;
  }

  &:not(:last-of-type) {
    margin-bottom: ${size000};
  }
`;

import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Input } from '../shared/Input';
import { FieldError } from './FieldError';

type Props = {
  name: string;
  type?: 'text' | 'password' | 'email';
  placeholder?: string;
  hideError?: boolean;
};

export const InputField = (props: Props) => {
  const formikContext = useFormikContext();
  const [field, meta] = useField(props);

  const isInvalid = meta.touched && meta.error != null;

  return (
    <>
      <Input
        {...props}
        {...field}
        id={field.name}
        placeholder={props.placeholder}
        validityState={isInvalid ? 'invalid' : 'untouched'}
        disabled={formikContext.isSubmitting}
      />
      {props.hideError === true ? null : <FieldError meta={meta} />}
    </>
  );
};

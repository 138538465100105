import React, { useContext } from 'react';
import { Form } from '../../../form/Form';
import { Formik, FormikHelpers } from 'formik';
import { FormField } from '../../../form/FormField';
import { Label } from '../../../form/Label';
import { TextAreaField } from '../../../form/TextAreaField';
import { ButtonRowAlignRight, SecondaryButton } from '../../../shared/Buttons';
import { ModalContent, ModalHeader, ModalFooter } from '../../../shared/Modal';
import { ProjectContext } from '../ProjectContext';
import { usePostWithoutResponse } from '../../../api/usePostWithoutResponse';
import { UpdateWriteUpCommand } from './UpdateWriteUpCommand';
import { FormSubmitButton } from '../../../form/FormSubmitButton';
import { ApiErrorBox } from '../../../api/ApiErrorBox';

type Props = {
  close: () => void;
};

export const CreateOrEditStudyData = (props: Props) => {
  const { getCycle, hardReloadProject } = useContext(ProjectContext);

  const currentCycle = getCycle();

  const existingStudyData = currentCycle.study!;

  const updateWriteUpRequest = usePostWithoutResponse<UpdateWriteUpCommand>(
    'UpdateWriteUp'
  );

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    updateWriteUpRequest
      .makeRequest({
        body: {
          projectCycleId: currentCycle.projectCycleId,
          writeUp: formModel.writeUp,
        },
        onSuccess: () => {
          hardReloadProject();
        },
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <Formik<FormModel>
      initialValues={{ writeUp: existingStudyData.writeUp }}
      onSubmit={onSubmit}
    >
      <Form>
        <ModalContent>
          <ModalHeader>Edit Write-Up</ModalHeader>
          <FormField>
            <Label>Write-up</Label>
            <TextAreaField name="writeUp" />
          </FormField>
          <ApiErrorBox error={updateWriteUpRequest.error} />
        </ModalContent>
        <ModalFooter>
          <ButtonRowAlignRight>
            <SecondaryButton
              onClick={props.close}
              disabled={updateWriteUpRequest.inProgress}
            >
              Cancel
            </SecondaryButton>
            <FormSubmitButton disabled={updateWriteUpRequest.inProgress}>
              Save
            </FormSubmitButton>
          </ButtonRowAlignRight>
        </ModalFooter>
      </Form>
    </Formik>
  );
};

type FormModel = {
  writeUp: string;
};

export const formatFileSize = (fileSizeInBytes: number) => {
  if (fileSizeInBytes < 1000) return `${fileSizeInBytes} bytes`;

  const fileSizeInKilobytes = fileSizeInBytes / 1000;

  if (fileSizeInKilobytes < 1000)
    return `${formatNumber(fileSizeInKilobytes)} KB`;

  const fileSizeInMegabytes = fileSizeInKilobytes / 1000;

  if (fileSizeInMegabytes < 1000)
    return `${formatNumber(fileSizeInMegabytes)} MB`;

  const fileSizeInGigabytes = fileSizeInMegabytes / 1000;

  return `${formatNumber(fileSizeInGigabytes)} GB`;
};

const formatNumber = (value: number) =>
  value.toLocaleString('en-GB', { maximumFractionDigits: 0 });

import styled from 'styled-components/macro';
import { size100, size150, size250 } from '../styling/sizes';
import { gray100, gray300, gray800, primary600 } from '../styling/colours';
import { borderRadiusMedium } from '../styling/borders';
import { transitionMedium } from '../styling/transitions';
import { fontSize200 } from '../styling/fontSizes';

export const UserResults = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  margin-top: ${size150};
  padding-right: ${size150};
`;

export const UserResult = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  border: solid 2px ${props => (props.selected ? primary600 : gray800)};
  opacity: ${props => (props.selected ? 1 : 0.8)};
  border-radius: ${borderRadiusMedium};
  padding: ${size100};
  transition: all ${transitionMedium} ease;
  font-size: ${fontSize200};
  cursor: pointer;

  &:not(:first-of-type) {
    margin-top: ${size100};
  }

  &:hover {
    opacity: 1;
  }
`;

export const UserResultName = styled.div`
  color: ${gray100};
`;

export const UserResultEmail = styled.div`
  color: ${gray300};
  margin-left: ${size100};
`;

export const UserResultsSpinnerContainer = styled.div`
  margin-top: ${size250};
  display: flex;
  justify-content: center;
`;

export const UserResultsNoResultsContainer = styled.div`
  padding: ${size100} 0;
  min-height: 46px;
`;

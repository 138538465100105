import React from 'react';
import { useField } from 'formik';
import { Checkbox } from '../shared/Checkbox';

type Props = {
  name: string;
};

export const CheckboxField = (props: Props) => {
  const [field] = useField(props);

  return <Checkbox {...field} id={field.name} checked={field.value} />;
};

import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import {
  size350,
  desktopContainerWidth,
  size100,
  size200,
  size150,
  size300,
  size250,
} from '../styling/sizes';
import { usePostWithoutResponse } from '../api/usePostWithoutResponse';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import {
  gray000,
  gray700,
  black,
  gray800,
  gray600,
  primary600,
  gray900,
} from '../styling/colours';
import { fontSize200, fontSize300 } from '../styling/fontSizes';
import { fontWeightBold } from '../styling/fontWeights';
import { borderRadiusMedium } from '../styling/borders';
import { useGet } from '../api/useGet';
import { ApiErrorBox } from '../api/ApiErrorBox';
import { Spinner } from '../shared/Spinner';
import { BuildingIcon } from '../icons/Icons';

type GetCurrentOrganisationResponse = {
  organisationName: string;
};

type Props = { children?: React.ReactNode };

export const OrganisationAppLayout = (props: Props) => {
  const history = useHistory();

  const authenticationContext = useContext(AuthenticationContext);

  const logoutRequest = usePostWithoutResponse<{}>('Logout');

  const logout = () => {
    logoutRequest.makeRequest({
      body: {},
      onSuccess: () => {
        authenticationContext.setState({
          isAuthenticated: false,
          accountType: null,
        });
        history.push('/login');
      },
    });
  };

  const getCurrentOrganisationRequest = useGet<GetCurrentOrganisationResponse>(
    'CurrentOrganisation'
  );

  useEffect(() => {
    getCurrentOrganisationRequest.makeRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (getCurrentOrganisationRequest.error != null) {
    return (
      <CenteredContainer>
        <ApiErrorBox error={getCurrentOrganisationRequest.error} />
      </CenteredContainer>
    );
  }

  const getCurrentOrganisationResponse = getCurrentOrganisationRequest.response;

  if (
    getCurrentOrganisationRequest.inProgress ||
    getCurrentOrganisationResponse == null
  ) {
    return (
      <CenteredContainer>
        <Spinner />
      </CenteredContainer>
    );
  }

  return (
    <LayoutContainer>
      <Navbar>
        <NavbarContent>
          <NavbarLeft>
            <NavbarLogo to="/">QI Compass</NavbarLogo>
            {/*<NavbarLink to="/" activeOnlyWhenExact={true}>*/}
            {/*  Dashboard*/}
            {/*</NavbarLink>*/}
            <NavbarLink to="/users">Users</NavbarLink>
            <NavbarLink to="/teams">Teams</NavbarLink>
            {/*<NavbarLink to="/settings">Settings</NavbarLink>*/}
          </NavbarLeft>
          <NavbarRight>
            <OrganisationName>
              <BuildingIcon />
              {getCurrentOrganisationResponse.organisationName}
            </OrganisationName>
            <LogoutButton onClick={logout} disabled={logoutRequest.inProgress}>
              {logoutRequest.inProgress ? 'Logging out...' : 'Log out'}
            </LogoutButton>
          </NavbarRight>
        </NavbarContent>
      </Navbar>
      <Content>{props.children}</Content>
    </LayoutContainer>
  );
};

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${size350} ${size250};
`;

const LayoutContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${gray900};
`;

const Navbar = styled.nav`
  height: ${size350};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${gray000};
  border-bottom: solid 2px ${black};
`;

const NavbarContent = styled.div`
  width: ${desktopContainerWidth};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NavbarLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const NavbarRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const NavbarLogo = styled(Link)`
  outline: none;
  border: none;
  text-decoration: none;
  background: none;
  font-size: ${fontSize300};
  font-weight: ${fontWeightBold};
  color: ${primary600};
  margin-right: ${size300};
`;

const StyledLink = styled(Link)<{ highlight: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${size100} ${size150};
  border-radius: ${borderRadiusMedium};
  margin-left: ${size200};
  color: ${props => (props.highlight ? gray800 : gray600)};
  background-color: ${props => (props.highlight ? black : 'transparent')};
  text-decoration: none;
  font-size: ${fontSize200};

  &:hover {
    color: ${gray800};
  }
`;

const NavbarLink = (props: {
  children?: React.ReactNode;
  to: string;
  activeOnlyWhenExact?: boolean;
}) => {
  const { children, to, activeOnlyWhenExact } = props;
  const match = useRouteMatch({ path: to, exact: activeOnlyWhenExact });

  return (
    <StyledLink to={to} highlight={match != null ? 'highlight' : ''}>
      {children}
    </StyledLink>
  );
};

const OrganisationName = styled.span`
  max-width: 250px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: ${size250};
  color: ${gray700};
  display: flex;
  align-items: center;
  font-size: ${fontSize200};

  svg {
    opacity: 0.8;
    margin-right: ${size100};
  }
`;

const LogoutButton = styled.button`
  outline: none;
  border: none;
  display: inline;
  background: none;
  color: ${gray700};
  cursor: pointer;
  text-decoration: underline;

  &:disabled {
    cursor: default;
  }
`;

const Content = styled.div``;

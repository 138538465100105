import styled from 'styled-components/macro';
import { fontWeightBold } from '../styling/fontWeights';
import { fontSize100 } from '../styling/fontSizes';
import { size050, size275, size100 } from '../styling/sizes';
import { gray200, gray300 } from '../styling/colours';
import { letterSpacingSlightlyWide } from '../styling/letterSpacing';
import { Input } from './Input';
import { Checkbox } from './Checkbox';

export const SearchInputLabel = styled.label`
  font-weight: ${fontWeightBold};
  font-size: ${fontSize100};
  margin-bottom: ${size050};
  display: inline-block;
  color: ${gray200};
  letter-spacing: ${letterSpacingSlightlyWide};
`;

export const SearchInput = styled(Input)`
  height: ${size275};

  &::placeholder {
    opacity: 0.75;
  }
`;

export const CheckboxFiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CheckboxFilterLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${fontSize100};
  color: ${gray300};
  user-select: none;

  ${Checkbox} {
    margin-right: ${size100};
  }

  &:not(:last-of-type) {
    margin-right: ${size275};
  }
`;

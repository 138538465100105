import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { find } from 'lodash';
import {
  ProjectCycleStatus,
  projectCycleStatusDisplayNames,
} from '../../../model/ProjectCycle';
import { Card, CardHeader, CardTitle } from '../../../shared/Card';
import {
  ButtonRowAlignRight,
  SecondaryButton,
  PrimaryButton,
} from '../../../shared/Buttons';
import styled from 'styled-components/macro';
import {
  PlusIcon,
  TickIcon,
  EyeIcon,
  ClipboardIcon,
  DataPointsIcon,
} from '../../../icons/Icons';
import { gray500 } from '../../../styling/colours';
import { fontSize300 } from '../../../styling/fontSizes';
import { dataEntryFormVariableTypes } from '../../../model/DataEntryForm';
import { GroupIcon } from '../../../icons/Icons';
import { NeutralBadge, SuccessBadge } from '../../../shared/Badge';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from '../../../shared/Modal';
import { AddDataModal } from './AddDataModal';
import {
  Stats,
  Stat,
  StatIcon,
  StatItem,
  StatHeadline,
  StatSupportingText,
  StatSubheadline,
} from '../../../shared/Stats';
import { UserAccountContext } from '../../../features/userAccounts/UserAccountContext';
import { usePostWithoutResponse } from '../../../api/usePostWithoutResponse';
import { CompleteDoingPhaseCommand } from './CompleteDoingPhaseCommand';
import { ApiErrorBox } from '../../../api/ApiErrorBox';
import { ProjectContext } from '../ProjectContext';

export const DoSection = () => {
  const {
    getProject,
    getCycle,
    hardReloadProject: refreshProject,
  } = useContext(ProjectContext);

  const project = getProject();
  const cycle = getCycle();

  const userAccountContext = useContext(UserAccountContext);
  const currentUserAccountId = userAccountContext.getUserAccountId();

  const currentUserIsProjectLead =
    currentUserAccountId === project.leadUserAccount.userAccountId;

  const [modal, setModal] = useState<string | null>(null);

  const contributors = project.contributors;

  const completeDoingPhaseRequest = usePostWithoutResponse<
    CompleteDoingPhaseCommand
  >('CompleteDoingPhase');

  const isAtOrBeyondStatus = (status: ProjectCycleStatus) => {
    const statuses = Object.keys(projectCycleStatusDisplayNames);
    return statuses.indexOf(cycle.status) >= statuses.indexOf(status);
  };

  if (!isAtOrBeyondStatus('Doing')) {
    return null;
  }

  const currentCyclePlan = cycle.plan!;
  const currentDataEntryForm = currentCyclePlan.dataEntryForm;
  const dataEntryFormVariables = currentDataEntryForm.variables;

  const dataSubmissionStats = cycle.doing;

  const moveToStudyPhase = () => {
    completeDoingPhaseRequest.makeRequest({
      body: {
        projectId: project.projectId,
      },
      onSuccess: () => {
        refreshProject();
      },
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Do</CardTitle>
        {cycle.status === 'Doing' ? (
          <NeutralBadge>In Progress</NeutralBadge>
        ) : (
          <SuccessBadge>Complete</SuccessBadge>
        )}
        <ActionButtons>
          {!isAtOrBeyondStatus('Studying') && currentUserIsProjectLead && (
            <SecondaryButton
              onClick={() => setModal('completePhase')}
              icon="true"
            >
              <TickIcon />
              Complete phase
            </SecondaryButton>
          )}
          <SecondaryButton
            as={Link}
            to={`/app/projects/${project.projectId}/cycles/${cycle.cycleNumber}/view-data`}
            icon="true"
          >
            <EyeIcon />
            View data
          </SecondaryButton>
          {!isAtOrBeyondStatus('Studying') && (
            <PrimaryButton icon="true" onClick={() => setModal('addData')}>
              <PlusIcon />
              Add data
            </PrimaryButton>
          )}
          <AddDataModal
            isOpen={modal === 'addData'}
            close={(andRefresh: boolean) => {
              setModal(null);

              if (andRefresh) {
                refreshProject();
              }
            }}
          />
          <Modal
            isOpen={modal === 'completePhase'}
            close={() => setModal(null)}
            preventClose={completeDoingPhaseRequest.inProgress}
          >
            <ModalContent>
              <ModalHeader>Complete phase</ModalHeader>
              <p>
                Are you sure you wish to complete the Do phase and move to the
                Study phase?
              </p>
              <div>
                Once you complete this phase you will no longer be able to
                collect data.
              </div>
              <ApiErrorBox
                withMargin={true}
                error={completeDoingPhaseRequest.error}
              />
            </ModalContent>
            <ModalFooter>
              <ButtonRowAlignRight>
                <SecondaryButton
                  onClick={() => setModal(null)}
                  disabled={completeDoingPhaseRequest.inProgress}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={moveToStudyPhase}
                  disabled={completeDoingPhaseRequest.inProgress}
                >
                  Confirm
                </PrimaryButton>
              </ButtonRowAlignRight>
            </ModalFooter>
          </Modal>
        </ActionButtons>
      </CardHeader>
      <Stats>
        <Stat>
          <StatIcon>
            <ClipboardIcon />
          </StatIcon>
          <StatItem>
            <VariablesTitle>Variables</VariablesTitle>
            <ul>
              {dataEntryFormVariables.map(variable => (
                <li key={variable.dataEntryFormVariableId}>
                  {variable.label} (
                  {
                    find(
                      dataEntryFormVariableTypes,
                      type => type.typeCode === variable.typeCode
                    )?.name
                  }
                  )
                </li>
              ))}
            </ul>
          </StatItem>
        </Stat>
        <Stat>
          <StatIcon>
            <DataPointsIcon />
          </StatIcon>
          <StatItem>
            <StatHeadline>
              {dataSubmissionStats.numberOfDataPointsSubmitted}
            </StatHeadline>
            <StatSupportingText>
              data{' '}
              {dataSubmissionStats.numberOfDataPointsSubmitted === 1
                ? 'point has been submitted'
                : 'points have been submitted'}
            </StatSupportingText>
          </StatItem>
        </Stat>
        <Stat>
          <StatIcon>
            <GroupIcon />
          </StatIcon>
          <StatItem>
            <StatHeadline>
              {dataSubmissionStats.numberOfContributorsWhoHaveSubmittedData}
              <StatSubheadline>/{contributors.length + 1}</StatSubheadline>
            </StatHeadline>
            <StatSupportingText>
              contributors have submitted data
            </StatSupportingText>
          </StatItem>
        </Stat>
      </Stats>
    </Card>
  );
};

const ActionButtons = styled(ButtonRowAlignRight)`
  flex: 1;
`;

const VariablesTitle = styled.div`
  color: ${gray500};
  font-size: ${fontSize300};
  text-transform: uppercase;
`;

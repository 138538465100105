import React from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  PrimaryButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type SubmitProjectPlanForApprovalCommand = {
  projectCyclePlanId: number;
};

type Props = {
  projectCyclePlanId: number;
  close: (andRefresh: boolean) => void;
};

export const SubmitProjectCyclePlanForApproval = ({
  projectCyclePlanId,
  close,
}: Props) => {
  const submitProjectCyclePlanForApprovalRequest = usePostWithoutResponse<
    SubmitProjectPlanForApprovalCommand
  >('SubmitProjectCyclePlanForApproval');

  const submitProjectCyclePlanForApproval = () => {
    submitProjectCyclePlanForApprovalRequest.makeRequest({
      body: { projectCyclePlanId },
      onSuccess: () => close(true),
    });
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Submit for approval</ModalHeader>
        <p>Are you sure you wish to submit this plan for approval?</p>
        <p>
          Once approved you will be unable to edit your plan, including the
          variables you're collecting.
        </p>
        <ApiErrorBox
          error={submitProjectCyclePlanForApprovalRequest.error}
          withMargin={true}
        />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={() => close(false)}
            disabled={submitProjectCyclePlanForApprovalRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={submitProjectCyclePlanForApproval}
            disabled={submitProjectCyclePlanForApprovalRequest.inProgress}
          >
            Confirm
          </PrimaryButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

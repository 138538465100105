import React, { useEffect, useState } from 'react';
import {
  THead,
  Tr,
  Th,
  TBody,
  Td,
  ChevronCell,
  ChevronHeader,
  TableWithClickableRows,
} from '../../shared/Table';
import styled from 'styled-components/macro';
import { SectionHeader, Section } from '../../shared/Section';
import { gray500 } from '../../styling/colours';
import { ChevronRightIcon } from '../../icons/Icons';
import { size000 } from '../../styling/sizes';
import { useGet } from '../../api/useGet';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { CenteredSpinner } from '../../shared/Spinner';
import { Modal } from '../../shared/Modal';
import { ReviewPlanningRequest } from '../../features/projects/ReviewPlanningRequest';
import { ReviewProjectCompletionRequest } from '../../features/projects/ReviewProjectCompletionRequest';

type GetPendingApprovalsResponse = {
  cyclesAwaitingPlanningApproval: Array<ProjectCycleResponse>;
  cyclesAwaitingCompletionApproval: Array<ProjectCycleResponse>;
};

type ProjectCycleResponse = {
  projectCycleId: number;
  projectCycleNumber: number;
  projectTitle: string;
  leadUserAccountFullName: string;
  leadUserAccountEmailAddress: string;
};

export const Approvals = () => {
  const [planningRequestCycleId, setPlanningRequestCycleId] = useState<
    number | null
  >(null);

  const closePlanningRequestModal = (andRefresh: boolean) => {
    setPlanningRequestCycleId(null);

    if (andRefresh) {
      getPendingApprovalsRequest.makeRequest();
    }
  };

  const [completionRequestCycleId, setCompletionRequestCycleId] = useState<
    number | null
  >(null);

  const closeCompletionRequestModal = (andRefresh: boolean) => {
    setCompletionRequestCycleId(null);

    if (andRefresh) {
      getPendingApprovalsRequest.makeRequest();
    }
  };

  const getPendingApprovalsRequest = useGet<GetPendingApprovalsResponse>(
    'GetPendingApprovals'
  );

  useEffect(() => {
    getPendingApprovalsRequest.makeRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (getPendingApprovalsRequest.error != null) {
    return <ApiErrorBox error={getPendingApprovalsRequest.error} />;
  }

  const pendingApprovals = getPendingApprovalsRequest.response;

  if (getPendingApprovalsRequest.inProgress || pendingApprovals == null) {
    return <CenteredSpinner />;
  }

  const {
    cyclesAwaitingPlanningApproval,
    cyclesAwaitingCompletionApproval,
  } = pendingApprovals;

  return (
    <div>
      <Section>
        <SectionHeader>Planning Requests</SectionHeader>
        {cyclesAwaitingPlanningApproval.length === 0 ? (
          <div>There are no outstanding planning requests at the moment.</div>
        ) : (
          <RequestsTable>
            <THead>
              <Tr>
                <Th>Project</Th>
                <Th>Lead</Th>
                <ChevronHeader />
              </Tr>
            </THead>
            <TBody>
              {cyclesAwaitingPlanningApproval.map(r => {
                return (
                  <Tr
                    key={r.projectCycleId}
                    onClick={() => setPlanningRequestCycleId(r.projectCycleId)}
                  >
                    <Td>
                      {r.projectTitle}
                      <SecondaryText>
                        Cycle {r.projectCycleNumber}
                      </SecondaryText>
                    </Td>
                    <Td>
                      {r.leadUserAccountFullName}
                      <SecondaryText>
                        {r.leadUserAccountEmailAddress}
                      </SecondaryText>
                    </Td>
                    <ChevronCell>
                      <ChevronRightIcon />
                    </ChevronCell>
                  </Tr>
                );
              })}
            </TBody>
          </RequestsTable>
        )}
      </Section>

      <Modal
        isOpen={planningRequestCycleId != null}
        close={() => closePlanningRequestModal(false)}
      >
        <ReviewPlanningRequest
          projectCycleId={planningRequestCycleId!}
          close={closePlanningRequestModal}
        />
      </Modal>

      <Section>
        <SectionHeader>Completion Requests</SectionHeader>
        {cyclesAwaitingCompletionApproval.length === 0 ? (
          <div>There are no outstanding completion requests at the moment.</div>
        ) : (
          <RequestsTable>
            <THead>
              <Tr>
                <Th>Project</Th>
                <Th>Lead</Th>
                <ChevronHeader />
              </Tr>
            </THead>
            <TBody>
              {cyclesAwaitingCompletionApproval.map(r => {
                return (
                  <Tr
                    key={r.projectCycleId}
                    onClick={() =>
                      setCompletionRequestCycleId(r.projectCycleId)
                    }
                  >
                    <Td>
                      {r.projectTitle}
                      <SecondaryText>
                        Cycle {r.projectCycleNumber}
                      </SecondaryText>
                    </Td>
                    <Td>
                      {r.leadUserAccountFullName}
                      <SecondaryText>
                        {r.leadUserAccountEmailAddress}
                      </SecondaryText>
                    </Td>
                    <ChevronCell>
                      <ChevronRightIcon />
                    </ChevronCell>
                  </Tr>
                );
              })}
            </TBody>
          </RequestsTable>
        )}
      </Section>

      <Modal
        isOpen={completionRequestCycleId != null}
        close={() => closeCompletionRequestModal(false)}
      >
        <ReviewProjectCompletionRequest
          projectCycleId={completionRequestCycleId!}
          close={closeCompletionRequestModal}
        />
      </Modal>
    </div>
  );
};

const RequestsTable = styled(TableWithClickableRows)`
  width: 100%;
`;

const SecondaryText = styled.div`
  color: ${gray500};
  margin-top: ${size000};
`;

import React, { useState, createContext } from 'react';

type UserContextValue = {
  userId: string | null;
  setUserId: (userId: string | null) => void;
};

export const UserContext = createContext<UserContextValue>({
  userId: null,
  setUserId: () => {
    throw new Error('UserContext is not initialised yet');
  },
});

const localStorageKey = 'COMPASS_QI_PROTO_USER_ID';

export const UserContextProvider = (props: { children: React.ReactNode }) => {
  const [userId, setUserIdValue] = useState<string | null>(
    localStorage.getItem(localStorageKey)
  );

  const setUserId = (newUserId: string | null) => {
    if (newUserId == null) {
      localStorage.removeItem(localStorageKey);
    } else {
      localStorage.setItem(localStorageKey, newUserId);
    }

    setUserIdValue(newUserId);
  };

  return (
    <UserContext.Provider value={{ userId, setUserId }}>
      {props.children}
    </UserContext.Provider>
  );
};

import React, { useEffect } from 'react';

export const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: (event: React.FocusEvent<Element>) => void
) => {
  useEffect(() => {
    const listener = (event: React.FocusEvent<Element>) => {
      if (!ref?.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener as any);
    document.addEventListener('touchstart', listener as any);

    return () => {
      document.removeEventListener('mousedown', listener as any);
      document.removeEventListener('touchstart', listener as any);
    };
  }, [ref, handler]);
};

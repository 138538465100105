import React from 'react';
import { Table, TBody, Td, Th, THead, Tr } from '../../shared/Table';
import { projectCycleStatusDisplayNames } from '../../model/ProjectCycle';
import styled from 'styled-components/macro';
import { ActiveProjectResponse } from '../../features/projects/ActiveProjectResponse';
import { UserCircles } from '../../shared/UserCircles';
import { AppLink } from '../../shared/AppLink';
import { fontWeightBold } from '../../styling/fontWeights';

type Props = { projects: Array<ActiveProjectResponse> };

export const ActiveProjectsTable = ({ projects }: Props) => {
  if (projects.length === 0) {
    return null;
  }

  return (
    <ProjectsTable>
      <THead>
        <Tr>
          <Th>Title</Th>
          <Th>Team</Th>
          <Th>Cycle</Th>
          <Th>Status</Th>
          <Th>Lead by</Th>
          <Th>Contributors</Th>
        </Tr>
      </THead>
      <TBody>
        {projects.map(p => (
          <Tr key={p.projectId}>
            <Td>
              <ProjectLink to={`/app/projects/${p.projectId}`}>
                {p.title}
              </ProjectLink>
            </Td>
            <Td>{p.team.name}</Td>
            <Td>Cycle {p.currentCycle.cycleNumber}</Td>
            <Td>{projectCycleStatusDisplayNames[p.currentCycle.status]}</Td>
            <Td>
              <AppLink to={`/app/users/${p.leadUserAccount.userAccountId}`}>
                {p.leadUserAccount.fullName}
              </AppLink>
            </Td>
            <Td>
              <UserCircles
                users={p.contributors.map(c => ({
                  userAccountId: c.userAccountId,
                  fullName: c.fullName,
                }))}
              />
            </Td>
          </Tr>
        ))}
      </TBody>
    </ProjectsTable>
  );
};

const ProjectsTable = styled(Table)`
  width: 100%;
`;

const ProjectLink = styled(AppLink)`
  font-weight: ${fontWeightBold};
`;

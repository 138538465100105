import React from 'react';
import styled from 'styled-components/macro';
import { HelpIcon } from '../icons/Icons';
import { gray400, primary500 } from '../styling/colours';
import { size050 } from '../styling/sizes';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
};

export const HelpButton = ({ onClick, disabled }: Props) => {
  return (
    <Button
      onClick={onClick}
      type="button"
      title="View guidance"
      disabled={disabled}
    >
      <HelpIcon />
    </Button>
  );
};

const Button = styled.button`
  position: relative;
  outline: none;
  border: none;
  background: none;
  color: ${gray400};
  cursor: pointer;
  transition: all 0.25s ease;
  padding: 0;
  margin-left: ${size050};

  &:hover {
    color: ${primary500};
  }

  svg {
    position: relative;
    top: 2px;
    height: 16px;
    width: 16px;
  }
`;

import React from 'react';
import styled from 'styled-components/macro';
import { size200, size350 } from '../styling/sizes';
import { green400, green900 } from '../styling/colours';
import { TickIcon } from '../icons/Icons';
import { PrimaryButton } from './Buttons';

type Props = { children?: React.ReactNode };

export const ModalSuccessMessage = (props: Props) => (
  <Container>
    <SuccessIconContainer>
      <SuccessIcon />
    </SuccessIconContainer>
    {props.children}
  </Container>
);

export const ModalSuccessMessageButton = styled(PrimaryButton)`
  margin-top: ${size200};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${size200};
`;

const SuccessIconContainer = styled.div`
  width: ${size350};
  height: ${size350};
  border-radius: 100%;
  background-color: ${green900};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${size200} 0;
`;

export const SuccessIcon = styled(TickIcon)`
  color: ${green400};
  height: 50%;
  width: 50%;
`;

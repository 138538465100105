import React, { useContext } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  primary500,
  gray000,
  primary600,
  gray600,
  gray500,
  gray700,
  gray800,
  gray900,
  primary400,
  pink300,
} from '../styling/colours';
import { fontSize000, fontSize300 } from '../styling/fontSizes';
import { fontWeightBold } from '../styling/fontWeights';
import { letterSpacingWide } from '../styling/letterSpacing';
import {
  FlareIcon,
  UserIcon,
  FolderIcon,
  FolderSharedIcon,
  FolderOpenIcon,
  RuleIcon,
  GroupIcon,
} from '../icons/Icons';
import {
  size250,
  size150,
  size200,
  size300,
  size350,
  size550,
  size050,
  size100,
  size275,
  size000,
} from '../styling/sizes';
import { borderRadiusMedium } from '../styling/borders';
import { SearchInput } from '../shared/SearchInput';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { usePostWithoutResponse } from '../api/usePostWithoutResponse';
import { UserAccountContext } from '../features/userAccounts/UserAccountContext';

type Props = {
  children?: React.ReactNode;
};

export const UserAppLayout = (props: Props) => {
  const history = useHistory();

  const authenticationContext = useContext(AuthenticationContext);
  const userAccountContext = useContext(UserAccountContext);

  const logoutRequest = usePostWithoutResponse<{}>('Logout');

  const logout = () => {
    logoutRequest.makeRequest({
      body: {},
      onSuccess: () => {
        authenticationContext.setState({
          isAuthenticated: false,
          accountType: null,
        });
        history.push('/login');
      },
    });
  };

  return (
    <Container>
      <AppSidebar>
        <AppSidebarContent>
          <LogoLink to="/app">
            <Logo />
            QI Compass
          </LogoLink>
          <SidebarLink to="/app/projects" activeStyle={activeLinkStyles}>
            <SidebarLinkIconAndText>
              <FolderSharedIcon /> My Projects
            </SidebarLinkIconAndText>
            <SidebarLinkBadge>TODO</SidebarLinkBadge>
          </SidebarLink>
          <SidebarLink to="/app/active-projects" activeStyle={activeLinkStyles}>
            <SidebarLinkIconAndText>
              <FolderIcon /> Active Projects
            </SidebarLinkIconAndText>
          </SidebarLink>
          <SidebarLink
            to="/app/completed-projects"
            activeStyle={activeLinkStyles}
          >
            <SidebarLinkIconAndText>
              <FolderOpenIcon /> Completed Projects
            </SidebarLinkIconAndText>
          </SidebarLink>
          <SidebarLink to="/app/teams" activeStyle={activeLinkStyles}>
            <SidebarLinkIconAndText>
              <GroupIcon /> Teams
            </SidebarLinkIconAndText>
          </SidebarLink>
          {userAccountContext.getIsAnApproverForSomeTeams() && (
            <SidebarLink to="/app/approvals" activeStyle={activeLinkStyles}>
              <SidebarLinkIconAndText>
                <RuleIcon /> Approvals
              </SidebarLinkIconAndText>
              <SidebarLinkBadge>TODO</SidebarLinkBadge>
            </SidebarLink>
          )}
        </AppSidebarContent>
      </AppSidebar>
      <AppContentContainer>
        <Navbar>
          <NavbarLeft>
            <SearchInput />
          </NavbarLeft>
          <NavbarRight>
            <ProfileLink
              to={`/app/users/${userAccountContext.getUserAccountId()}`}
            >
              <ProfileIcon />
              <span>{userAccountContext.getFullName()}</span>
            </ProfileLink>
            <LogOutButton onClick={logout}>
              {logoutRequest.inProgress ? 'Logging out...' : 'Log out'}
            </LogOutButton>
          </NavbarRight>
        </Navbar>
        <AppContent>{props.children}</AppContent>
      </AppContentContainer>
    </Container>
  );
};

const activeLinkStyles = {
  color: gray900,
};

const Container = styled.div`
  display: flex;
`;

const AppSidebar = styled.div`
  width: ${size550};
  height: 100vh;
  background-color: ${gray000};
`;

const AppSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const SidebarLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${size275};
  padding: 0 ${size200};
  color: ${gray600};
  text-decoration: none;
  font-weight: ${fontWeightBold};
  letter-spacing: ${letterSpacingWide};
  transition: all 0.25s ease;

  &:hover {
    color: ${gray800};
  }
`;

const SidebarLinkIconAndText = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: ${size100};
  }
`;

const SidebarLinkBadge = styled.div`
  height: ${size200};
  border-radius: ${size100};
  background-color: ${pink300};
  padding: ${size000} ${size050};
  color: white;
  font-size: ${fontSize000};
  font-weight: ${fontWeightBold};
  transform: scale(0.9);
`;

const AppContentContainer = styled.div`
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
  background-color: ${gray800};
`;

const AppContent = styled.div`
  padding: ${size250};
`;

const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${size350};
  background-color: ${gray900};
  padding: 0 ${size250};
  border-bottom: solid 1px ${gray700};
`;

const NavbarLeft = styled.div`
  position: relative;
`;

const NavbarRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const LogoLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-size: ${fontSize300};
  color: ${primary600};
  font-weight: ${fontWeightBold};
  letter-spacing: ${letterSpacingWide};
  height: ${size350};
  width: 100%;
  padding: 0 ${size200};
  margin-bottom: ${size250};
`;

const Logo = styled(FlareIcon)`
  display: block;
  height: ${size250};
  width: ${size250};
  margin-right: ${size150};
  fill: ${primary500};
`;

const ProfileIcon = styled(UserIcon)`
  height: ${size200};
  margin-right: ${size050};
`;

const ProfileLink = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: ${size300};
  color: ${gray500};
  padding: 0 ${size150};
  transition: all 0.25s ease;
  border-radius: ${borderRadiusMedium};

  &:hover {
    color: ${primary400};
  }
`;

const LogOutButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  height: ${size250};
  color: ${gray500};
  padding: 0 ${size150};
  margin-left: ${size150};
  transition: all 0.25s ease;
  border: none;
  border-radius: ${borderRadiusMedium};
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: ${primary400};
  }
`;

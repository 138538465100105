import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AppHome } from '../app/AppHome';
import { Users } from '../app/management/Users';
import { ViewPublicProfile } from '../app/profile/ViewPublicProfile';
import { ViewProjects } from '../app/ViewProjects';
import { ActiveProjects } from '../app/ActiveProjects';
import { CompletedProjects } from '../app/CompletedProjects';
import { CreateOrEditPlan } from '../app/project/planning/CreateOrEditPlan';
import { ViewData } from '../app/project/doing/ViewData';
import { ViewProject } from '../app/project/ViewProject';
import { Search } from '../app/Search';
import { ViewProjectApprovalRequest } from '../app/management/ViewProjectApprovalRequest';
import { ViewProjectCompletionRequest } from '../app/management/ViewProjectCompletionRequest';
import { Approvals } from '../app/management/Approvals';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { UserAppLayout } from '../layout/UserAppLayout';
import { ViewTeams } from '../features/userAccounts/ViewTeams';
import { UserAccountContextProvider } from '../features/userAccounts/UserAccountContext';
import { ProjectContextProvider } from '../app/project/ProjectContext';

export const UserAccountRoutes = () => {
  const authenticationContext = useContext(AuthenticationContext);

  if (
    !authenticationContext.isAuthenticated ||
    authenticationContext.accountType !== 'user'
  ) {
    return null;
  }

  return (
    <>
      <Route path="/" exact={true}>
        <Redirect to="/app" push={false} />
      </Route>
      <Route path="/app">
        <UserAccountContextProvider>
          <UserAppLayout>
            <Switch>
              <Route exact path="/app">
                <AppHome />
              </Route>
              <Route exact path="/app/users">
                <Users />
              </Route>
              <Route path="/app/users/:userAccountId">
                <ViewPublicProfile />
              </Route>
              <Route exact path="/app/projects">
                <ViewProjects />
              </Route>
              <Route exact path="/app/active-projects">
                <ActiveProjects />
              </Route>
              <Route exact path="/app/completed-projects">
                <CompletedProjects />
              </Route>
              <Route path="/app/projects/:id/plan">
                <CreateOrEditPlan />
              </Route>
              <Route path="/app/projects/:id/cycles/:cycleNumberParam/view-data">
                <ProjectContextProvider>
                  <ViewData />
                </ProjectContextProvider>
              </Route>
              <Route path="/app/projects/:id/cycles/:cycleNumberParam">
                <ProjectContextProvider>
                  <ViewProject />
                </ProjectContextProvider>
              </Route>
              <Route exact path="/app/projects/:id">
                <ProjectContextProvider>
                  <ViewProject />
                </ProjectContextProvider>
              </Route>
              <Route exact path="/app/search">
                <Search />
              </Route>
              <Route path="/app/management/projectApprovalRequests/:projectApprovalRequestId">
                <ViewProjectApprovalRequest />
              </Route>
              <Route path="/app/management/projectCompletionRequests/:projectCompletionRequestId">
                <ViewProjectCompletionRequest />
              </Route>
              <Route exact path="/app/approvals">
                <Approvals />
              </Route>
              <Route path="/app/teams">
                <ViewTeams />
              </Route>
              <Route path="*">Not Found</Route>
            </Switch>
          </UserAppLayout>
        </UserAccountContextProvider>
      </Route>
    </>
  );
};

import React from 'react';
import styled from 'styled-components/macro';
import { ApiErrorResult } from './ApiErrorResult';
import { yellow400, yellow900, yellow200 } from '../styling/colours';
import { size200, size150 } from '../styling/sizes';
import { borderRadiusMedium } from '../styling/borders';

type Props = {
  error: ApiErrorResult | null;
  withMargin?: boolean;
};

export const ApiErrorBox = (props: Props) => {
  return props.error == null ? null : (
    <ErrorBox withMargin={props.withMargin}>
      {props.error.userVisibleMessage}
    </ErrorBox>
  );
};

export const ErrorBox = styled.div<{ withMargin?: boolean }>`
  border: solid 1px ${yellow400};
  background-color: ${yellow900};
  color: ${yellow200};
  padding: ${size150} ${size200};
  border-radius: ${borderRadiusMedium};
  margin-top: ${props => (props.withMargin ? size200 : 0)};
`;

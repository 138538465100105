import React from 'react';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  DangerButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type DeactivateUserAccountCommand = {
  userAccountId: number;
};

type Props = {
  close: (andRefresh: boolean) => void;
  userAccountId: number;
  emailAddress: string;
};

export const DeactivateUserAccount = (props: Props) => {
  const deactivateUserAccountRequest = usePostWithoutResponse<
    DeactivateUserAccountCommand
  >('DeactivateUserAccount');

  const deactivateUserAccount = () => {
    deactivateUserAccountRequest.makeRequest({
      body: { userAccountId: props.userAccountId },
      onSuccess: () => props.close(true),
    });
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Deactivate user</ModalHeader>
        <p>
          Are you sure you wish to deactivate{' '}
          <strong>{props.emailAddress}</strong>?
        </p>
        <p>
          Once deactivated, this user will no longer be able to access the QI
          Compass site.
        </p>
        <ApiErrorBox
          error={deactivateUserAccountRequest.error}
          withMargin={true}
        />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={() => props.close(false)}
            disabled={deactivateUserAccountRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <DangerButton
            onClick={deactivateUserAccount}
            disabled={deactivateUserAccountRequest.inProgress}
          >
            Confirm
          </DangerButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

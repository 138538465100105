import React from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  SecondaryButton,
  PrimaryButton,
} from '../../shared/Buttons';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type RemoveApproverCommand = {
  teamUserAccountId: number;
};

type Props = {
  teamUserAccountId: number;
  name: string;
  emailAddress: string;
  close: (andRefresh: boolean) => void;
};

export const RemoveApprover = (props: Props) => {
  const removeApproverRequest = usePostWithoutResponse<RemoveApproverCommand>(
    'RemoveApprover'
  );

  const submit = () => {
    removeApproverRequest.makeRequest({
      body: { teamUserAccountId: props.teamUserAccountId },
      onSuccess: () => props.close(true),
    });
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Remove approver</ModalHeader>
        <p>
          Are you sure you wish to remove {props.name} ({props.emailAddress}) as
          an approver for this team?
        </p>
        <ApiErrorBox error={removeApproverRequest.error} withMargin={true} />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={() => props.close(false)}
            disabled={removeApproverRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={submit}
            disabled={removeApproverRequest.inProgress}
          >
            Confirm
          </PrimaryButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

import React, { createContext, useState } from 'react';
import { DataModel } from './DataModel';

export type DataContextValue = {
  getData: () => DataModel;
  setData: (newData: DataModel) => void;
};

export const DataContext = createContext<DataContextValue>({
  getData: () => {
    throw new Error('DataContext is not initialised yet');
  },
  setData: () => {
    throw new Error('DataContext is not initialised yet');
  },
});

const localStorageKey = 'COMPASS_QI_PROTO_DATA_CONTEXT';

const getDataFromLocalStorage = (): DataModel =>
  localStorage.getItem(localStorageKey) == null
    ? initialDataModel
    : JSON.parse(localStorage.getItem(localStorageKey)!);

const saveDataToLocalStorage = (newData: DataModel) => {
  localStorage.setItem(localStorageKey, JSON.stringify(newData));
};

export const DataContextProvider = (props: { children: React.ReactNode }) => {
  const [dataValue, setDataValue] = useState<DataModel>(
    getDataFromLocalStorage()
  );

  const getData = () => dataValue;

  const setData = (newData: DataModel) => {
    setDataValue(newData);
    saveDataToLocalStorage(newData);
  };

  return (
    <DataContext.Provider value={{ getData, setData }}>
      {props.children}
    </DataContext.Provider>
  );
};

export const initialDataModel: DataModel = {
  organisations: {
    '0': {
      organisationId: '0',
      uniqueIdentifier: 'ruh',
      name: 'The Royal United Hospital Bath',
    },
    '1': {
      organisationId: '1',
      uniqueIdentifier: 'uhbristol',
      name: 'University Hospitals Bristol',
    },
    '2': {
      organisationId: '2',
      uniqueIdentifier: 'cgh',
      name: 'Cheltenham General Hospital',
    },
    '3': {
      organisationId: '3',
      uniqueIdentifier: 'grh',
      name: 'Gloucester Royal Hospital',
    },
    '4': {
      organisationId: '4',
      uniqueIdentifier: 'nbt',
      name: 'Southmead Hospital',
    },
    '5': {
      organisationId: '5',
      uniqueIdentifier: 'gwh',
      name: 'The Great Western Hospital',
    },
    '6': {
      organisationId: '6',
      uniqueIdentifier: 'waht',
      name: 'Weston General Hospital',
    },
    '7': {
      organisationId: '7',
      uniqueIdentifier: 'ydh',
      name: 'Yeovil District Hospital',
    },
  },
  users: {
    '0': {
      userId: '0',
      organisationId: '6',
      department: 'Medicine',
      profession: 'Doctor (Trainee)',
      name: 'Daniel Potter',
      emailAddress: 'dan@nhs.uk',
      isManager: false,
    },
    '1': {
      userId: '1',
      organisationId: '6',
      department: 'Medicine',
      profession: 'Manager',
      name: 'Alex Potter',
      emailAddress: 'alex@nhs.uk',
      isManager: true,
    },
  },
  projects: {},
  projectContributors: {},
  projectContributorJoinRequests: {},
  projectContributorInvites: {},
  projectWriteUps: {},
  projectWriteUpAttachments: {},
  projectCycles: {},
  projectCyclePlans: {},
  projectCyclePlanApprovalRequests: {},
  dataEntryForms: {},
  dataEntryFormVariables: {},
  dataEntryFormVariableOptions: {},
  dataEntryFormSubmissions: {},
  dataEntryFormSubmissionVariables: {},
  projectCycleStudies: {},
  projectCycleStudyAttachments: {},
  projectCycleActions: {},
  projectCompletionApprovalRequests: {},
};

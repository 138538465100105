import { createGlobalStyle } from 'styled-components/macro';
import { gray100 } from './styling/colours';
import { lineHeightMedium } from './styling/lineHeights';
import { modalIsOpenClassName } from './shared/Modal';
import { fontSize100 } from './styling/fontSizes';

export const GlobalStyles = createGlobalStyle`
  html.${modalIsOpenClassName} {
    overflow-y: hidden;

    body {
      overflow-y: hidden;
    }
  }

  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${gray100};
    font-size: ${fontSize100};
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }

  p, li {
    line-height: ${lineHeightMedium};
  }

  html,
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  span,
  p,
  button,
  input,
  textarea,
  a,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  table,
  thead,
  th,
  tr,
  tbody,
  td,
  select,
  option {
    font-family: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

      box-sizing: border-box;
  }
`;

import React from 'react';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  PrimaryButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type ReactivateUserAccountCommand = {
  userAccountId: number;
};

type Props = {
  close: (andRefresh: boolean) => void;
  userAccountId: number;
  emailAddress: string;
};

export const ReactivateUserAccount = (props: Props) => {
  const reactivateUserAccountRequest = usePostWithoutResponse<
    ReactivateUserAccountCommand
  >('ReactivateUserAccount');

  const reactivateUserAccount = () => {
    reactivateUserAccountRequest.makeRequest({
      body: { userAccountId: props.userAccountId },
      onSuccess: () => props.close(true),
    });
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Reactivate user</ModalHeader>
        <p>
          Are you sure you wish to reactivate{' '}
          <strong>{props.emailAddress}</strong>?
        </p>
        <ApiErrorBox
          error={reactivateUserAccountRequest.error}
          withMargin={true}
        />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={() => props.close(false)}
            disabled={reactivateUserAccountRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={reactivateUserAccount}
            disabled={reactivateUserAccountRequest.inProgress}
          >
            Confirm
          </PrimaryButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

import React, { useContext, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { DataContext } from '../../model/DataContext';
import { find, values } from 'lodash';
import { DeprecatedSubmitButton } from '../../shared/Buttons';
import { Formik } from 'formik';
import { Form } from '../../form/Form';
import {
  withBlankOption,
  SelectOptions,
  SelectField,
} from '../../form/SelectField';
import { FormField } from '../../form/FormField';
import { Label } from '../../form/Label';
import { TextAreaField } from '../../form/TextAreaField';
import { produce } from 'immer';
import {
  CardHeader,
  CardTitle,
  Card,
  CardContent,
  CardContentHeading,
} from '../../shared/Card';
import styled from 'styled-components/macro';
import { AppLink } from '../../shared/AppLink';
import { lineHeightMedium } from '../../styling/lineHeights';
import { size000, size100, size050 } from '../../styling/sizes';
import { gray800, gray900, gray700 } from '../../styling/colours';
import { fontSize100 } from '../../styling/fontSizes';

export const ViewProjectCompletionRequest = () => {
  const { projectCompletionRequestId } = useParams();

  const dataContext = useContext(DataContext);
  const data = dataContext.getData();

  const [isDone, setIsDone] = useState(false);

  if (isDone) {
    return <Redirect to="/app/management" />;
  }

  const projectCompletionRequest =
    data.projectCompletionApprovalRequests[projectCompletionRequestId!];

  if (projectCompletionRequest == null) {
    return null;
  }

  const projectWriteUp = find(
    values(data.projectWriteUps),
    writeUp => writeUp.projectId === projectCompletionRequest.projectId
  )!;

  const projectWriteUpAttachments = values(
    data.projectWriteUpAttachments
  ).filter(a => a.projectWriteUpId === projectWriteUp.projectWriteUpId);

  const project = data.projects[projectCompletionRequest.projectId]!;

  const user = data.users[project.leadUserId]!;

  const onSubmit = (formModel: FormModel) => {
    if (formModel.decision === 'Reject') {
      alert('TODO - Rejecting is not implemented yet');
      return;
    }

    const newData = produce(data, draft => {
      const activeCycle = find(
        values(draft.projectCycles),
        cycle => cycle.status !== 'Complete'
      )!;

      activeCycle.status = 'Complete';

      draft.projects[project.projectId].isComplete = true;

      delete draft.projectCompletionApprovalRequests[
        projectCompletionRequest.projectCompletionApprovalRequestId
      ];
    });

    dataContext.setData(newData);

    setIsDone(true);
  };

  return (
    <Formik<FormModel>
      onSubmit={onSubmit}
      initialValues={{ decision: '', comments: '' }}
    >
      {formikProps => (
        <>
          <Card>
            <CardHeader>
              <CardTitle>Project completion request</CardTitle>
            </CardHeader>
            <CardContent>
              <AppLink to={`/app/users/${user.userId}`}>{user.name}</AppLink>{' '}
              submitted this request for{' '}
              <AppLink to={`/app/projects/${project.projectId}`}>
                {project.title}
              </AppLink>
              .
            </CardContent>
            <CardContent>
              <CardContentHeading>Write-Up</CardContentHeading>
              <WriteUp>{projectWriteUp.writeUp}</WriteUp>
            </CardContent>
            <CardContent>
              <CardContentHeading>Supporting documents</CardContentHeading>
              {projectWriteUpAttachments.length === 0 ? (
                <div>No attachments were uploaded.</div>
              ) : (
                <AttachmentsRow>
                  {projectWriteUpAttachments.map(attachment => (
                    <Attachment
                      key={attachment.projectWriteUpAttachmentId}
                      onClick={() =>
                        alert(`TODO: download ${attachment.fileName}`)
                      }
                    >
                      <AttachmentFileName>
                        {attachment.fileName}
                      </AttachmentFileName>
                    </Attachment>
                  ))}
                </AttachmentsRow>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Decision</CardTitle>
            </CardHeader>
            <CardContent>
              <DecisionForm>
                <FormField>
                  <SelectField<string>
                    name="decision"
                    options={decisionOptions}
                  />
                </FormField>

                <FormField>
                  <Label>Comments</Label>
                  <TextAreaField name="comments" />
                </FormField>

                <DeprecatedSubmitButton>Submit</DeprecatedSubmitButton>
              </DecisionForm>
            </CardContent>
          </Card>
        </>
      )}
    </Formik>
  );
};

type FormModel = {
  decision: string;
  comments: string;
};

const decisionOptions: SelectOptions<string> = withBlankOption<string>(
  [
    { text: 'Approve', value: 'Approve' },
    { text: 'Reject', value: 'Reject' },
  ],
  ''
);

const DecisionForm = styled(Form)``;

const WriteUp = styled.div`
  white-space: pre-wrap;
  line-height: ${lineHeightMedium};
`;

const AttachmentsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const Attachment = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${size000} ${size100};
  border-radius: 100px;
  border: solid 1px ${gray800};
  margin-right: ${size050};
  background-color: ${gray900};
  transition: all 0.25s ease;
  cursor: pointer;

  &:hover {
    background-color: ${gray800};
    border-color: ${gray700};
  }
`;

const AttachmentFileName = styled.div`
  margin: 0 ${size000};
  font-size: ${fontSize100};
`;

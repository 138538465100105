import React, { createContext, useState } from 'react';
import { AccountType } from './AccountType';

type AuthenticationState =
  | { isAuthenticated: false; accountType: null }
  | { isAuthenticated: true; accountType: AccountType };

type AuthenticationContextValue = AuthenticationState & {
  setState: (authenticationState: AuthenticationState) => void;
};

export const AuthenticationContext = createContext<AuthenticationContextValue>({
  isAuthenticated: false,
  accountType: null,
  setState: () => {
    throw new Error('AuthenticationContext is not initialised yet');
  },
});

type Props = {
  children?: React.ReactNode;
};

export const AuthenticationContextProvider = (props: Props) => {
  const [state, setState] = useState(getStateFromLocalStorage());

  const setStateAndSaveToLocalStorage = (state: AuthenticationState) => {
    saveStateToLocalStorage(state);
    setState(state);
  };

  const contextValue: AuthenticationContextValue = {
    ...state,
    setState: setStateAndSaveToLocalStorage,
  };

  return (
    <AuthenticationContext.Provider value={contextValue}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

const isAuthenticatedLocalStorageKey = 'qi_compass_auth_state_is_authenticated';
const accountTypeLocalStorageKey = 'qi_compass_auth_state_account_type';

const saveStateToLocalStorage = (authenticationState: AuthenticationState) => {
  localStorage.setItem(
    isAuthenticatedLocalStorageKey,
    authenticationState.isAuthenticated.toString()
  );

  localStorage.setItem(
    accountTypeLocalStorageKey,
    authenticationState.accountType ?? ''
  );
};

const getStateFromLocalStorage = (): AuthenticationState => {
  const isAuthenticated =
    localStorage.getItem(isAuthenticatedLocalStorageKey) === true.toString();

  const accountType = localStorage.getItem(accountTypeLocalStorageKey);

  return isAuthenticated
    ? {
        isAuthenticated,
        accountType: accountType as AccountType,
      }
    : {
        isAuthenticated: false,
        accountType: null,
      };
};

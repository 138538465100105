import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { find } from 'lodash';
import { v4 } from 'uuid';
import {
  DataEntryFormVariableTypeCode,
  dataEntryFormVariableTypes,
} from '../../../model/DataEntryForm';
import { Form } from '../../../form/Form';
import { Formik, FieldArray, FormikHelpers } from 'formik';
import { FormField } from '../../../form/FormField';
import { Label } from '../../../form/Label';
import { TextAreaField } from '../../../form/TextAreaField';
import { SelectField } from '../../../form/SelectField';
import { InputField } from '../../../form/InputField';
import styled from 'styled-components/macro';
import {
  size050,
  size150,
  size100,
  size200,
  size250,
} from '../../../styling/sizes';
import { SecondaryButton } from '../../../shared/Buttons';
import { CrossIcon, PlusIcon } from '../../../icons/Icons';
import { lineHeightWide, lineHeightMedium } from '../../../styling/lineHeights';
import { HelpButton } from '../../../form/HelpButton';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalSubHeader,
} from '../../../shared/Modal';
import { primary900, primary700, primary200 } from '../../../styling/colours';
import { fontSize400 } from '../../../styling/fontSizes';
import { fontWeightBold } from '../../../styling/fontWeights';
import { usePostWithResponse } from '../../../api/usePostWithResponse';
import {
  DataEntryFormVariableResponse,
  GetProjectResponse,
  ProjectCyclePlanResponse,
} from '../../../features/projects/GetProjectResponse';
import { ApiErrorBox } from '../../../api/ApiErrorBox';
import { CenteredSpinner } from '../../../shared/Spinner';
import { usePostWithoutResponse } from '../../../api/usePostWithoutResponse';
import { FormSubmitButton } from '../../../form/FormSubmitButton';
import { Validator } from 'fluentvalidation-ts';

type GetProjectQuery = { projectId: number };

type CreateOrUpdateDataEntryFormVariableCommand = {
  dataEntryFormVariableId: number | null;
  typeCode: DataEntryFormVariableTypeCode;
  label: string;
};

type CreateOrUpdateProjectCyclePlanCommand = {
  projectCyclePlanId: number | null;
  projectCycleId: number;
  whichPatients: string;
  whoIsHelping: string;
  where: string;
  when: string;
  how: string;
  why: string;
  additionalInformation: string;
  variables: Array<CreateOrUpdateDataEntryFormVariableCommand>;
};

export const CreateOrEditPlan = () => {
  const { id } = useParams();
  const history = useHistory();

  const [openModal, setOpenModal] = useState<string | null>(null);

  const getProjectRequest = usePostWithResponse<
    GetProjectQuery,
    GetProjectResponse
  >('GetProject');

  useEffect(() => {
    getProjectRequest.makeRequest({ body: { projectId: Number(id) } });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createOrUpdatePlanRequest = usePostWithoutResponse<
    CreateOrUpdateProjectCyclePlanCommand
  >('CreateOrUpdateProjectCyclePlan');

  if (getProjectRequest.error != null) {
    return <ApiErrorBox error={getProjectRequest.error} />;
  }

  const project = getProjectRequest.response;

  if (getProjectRequest.inProgress || project == null) {
    return <CenteredSpinner />;
  }

  const currentCycle = find(
    project.cycles,
    cycle => cycle.status !== 'Complete'
  )!;

  const currentCyclePlan = currentCycle.plan!;
  const currentDataEntryForm = currentCyclePlan?.dataEntryForm;
  const currentDataEntryFormVariables = currentDataEntryForm?.variables;

  const createPlan = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    const command: CreateOrUpdateProjectCyclePlanCommand = {
      projectCyclePlanId: null,
      projectCycleId: currentCycle.projectCycleId,
      whichPatients: formModel.whichPatients,
      whoIsHelping: formModel.whoIsHelping,
      where: formModel.where,
      when: formModel.when,
      how: formModel.how,
      why: formModel.why,
      additionalInformation: formModel.additionalInformation,
      variables: formModel.variables.map(variable => ({
        dataEntryFormVariableId: variable.id,
        typeCode: variable.typeCode,
        label: variable.label,
      })),
    };

    createOrUpdatePlanRequest.makeRequest({
      body: command,
      onSuccess: () => history.push(`/app/projects/${id}`),
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  const updatePlan = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    const command: CreateOrUpdateProjectCyclePlanCommand = {
      projectCyclePlanId: currentCyclePlan!.projectCyclePlanId,
      projectCycleId: currentCycle.projectCycleId,
      whichPatients: formModel.whichPatients,
      whoIsHelping: formModel.whoIsHelping,
      where: formModel.where,
      when: formModel.when,
      how: formModel.how,
      why: formModel.why,
      additionalInformation: formModel.additionalInformation,
      variables: formModel.variables.map(variable => ({
        dataEntryFormVariableId: variable.id,
        typeCode: variable.typeCode,
        label: variable.label,
      })),
    };

    createOrUpdatePlanRequest.makeRequest({
      body: command,
      onSuccess: () => history.push(`/app/projects/${id}`),
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    if (currentCyclePlan == null) {
      createPlan(formModel, formikHelpers);
    } else {
      updatePlan(formModel, formikHelpers);
    }
  };

  return (
    <Formik<FormModel>
      onSubmit={onSubmit}
      initialValues={getInitialFormModel(
        currentCyclePlan,
        currentDataEntryFormVariables
      )}
      validate={formValidator.validate}
    >
      {formikProps => (
        <Form>
          <h1>{currentCyclePlan == null ? 'Create' : 'Edit'} Plan</h1>
          <Intro>
            <SupportingText>
              Your plan should act like a map to help you decide where your
              project is going.
            </SupportingText>
            <SupportingText>
              The best plans answer the questions listed here, but feel free to
              skip any that don't apply to your project. If you're not sure how
              to answer any of the questions, just click the help icon to view
              some guidance.
            </SupportingText>
            <SupportingText>
              If you'd like to enter additional information that doesn't quite
              fit with any of the preset questions, just put it in the
              "Additional information" section.
            </SupportingText>
          </Intro>
          <FormField>
            <Label>
              Which patients will you be collecting information on?
              <HelpButton
                onClick={() => setOpenModal('whichPatients')}
                disabled={createOrUpdatePlanRequest.inProgress}
              />
            </Label>
            <TextAreaField
              name="whichPatients"
              placeholder={`e.g. "This project will include female patients diagnosed with urinary infections between the ages of 18 and 65. This project will not be including patients already taking antibiotics."`}
            />
            <Modal
              isOpen={openModal === 'whichPatients'}
              close={() => setOpenModal(null)}
            >
              <ModalContent>
                <ModalHeader>Who?</ModalHeader>
                <ModalSubHeader>
                  Which patients will you be collecting information on?
                </ModalSubHeader>
                <ParagraphText>
                  You might be collecting data on patients with a particular
                  health problem, of a certain age or sex.
                </ParagraphText>
                <ExampleText>
                  This project will include female patients diagnosed with
                  urinary infections between the ages of 18 and 65.
                </ExampleText>
                <ParagraphText>
                  You might also want to say who you are <em>not</em> collecting
                  data on.
                </ParagraphText>
                <ExampleText>
                  This project will not be including patients already taking
                  antibiotics.
                </ExampleText>
              </ModalContent>
            </Modal>
          </FormField>
          <FormField>
            <Label>
              Who will be helping you with this Project?
              <HelpButton
                onClick={() => setOpenModal('whoIsHelping')}
                disabled={createOrUpdatePlanRequest.inProgress}
              />
            </Label>
            <TextAreaField
              name="whoIsHelping"
              placeholder={`e.g. "Alex James (CT1 Medicine) will be helping with data collection and this project is supported by the Ward 21 team and our consultant Dr Smith."`}
            />
            <Modal
              isOpen={openModal === 'whoIsHelping'}
              close={() => setOpenModal(null)}
            >
              <ModalContent>
                <ModalHeader>Who?</ModalHeader>
                <ModalSubHeader>
                  Who will be helping you with this Project?
                </ModalSubHeader>
                <ParagraphText>
                  Are you acting alone or are other people from your team going
                  to help you? These could be team members who are going to join
                  you on the project or just people who support you.
                </ParagraphText>
                <ParagraphText>
                  Whenever you start a new QI Journey it’s a good idea to let
                  the people you work with know. You might need their help and
                  support further down the road!
                </ParagraphText>
                <ExampleText>
                  Alex James (CT1 Medicine) will be helping with data collection
                  and this project is supported by the Ward 21 team and our
                  consultant Dr Smith.
                </ExampleText>
              </ModalContent>
            </Modal>
          </FormField>
          <FormField>
            <Label>
              Where will you be collecting patient information from?
              <HelpButton
                onClick={() => setOpenModal('where')}
                disabled={createOrUpdatePlanRequest.inProgress}
              />
            </Label>
            <TextAreaField
              name="where"
              placeholder={`e.g. "We will be collecting information on patients on ward 21."`}
            />
            <Modal
              isOpen={openModal === 'where'}
              close={() => setOpenModal(null)}
            >
              <ModalContent>
                <ModalHeader>Where?</ModalHeader>
                <ModalSubHeader>
                  Where will you be collecting patient information from?
                </ModalSubHeader>
                <ParagraphText>
                  This might be a ward, a department, a hospital, or even an
                  entire region!
                </ParagraphText>
                <ExampleText>
                  We will be collecting information on patients on ward 21.
                </ExampleText>
              </ModalContent>
            </Modal>
          </FormField>
          <FormField>
            <Label>
              When are you planning on collecting the information?
              <HelpButton
                onClick={() => setOpenModal('when')}
                disabled={createOrUpdatePlanRequest.inProgress}
              />
            </Label>
            <TextAreaField
              name="when"
              placeholder={`e.g. "We will be completing this project over the next six months."`}
            />
            <Modal
              isOpen={openModal === 'when'}
              close={() => setOpenModal(null)}
            >
              <ModalContent>
                <ModalHeader>When?</ModalHeader>
                <ModalSubHeader>
                  When are you planning on collecting the information?
                </ModalSubHeader>
                <ParagraphText>
                  It's a good idea to have a rough estimate of how long a
                  project will take and when you will be collecting data. If in
                  doubt, guess the amount of time it will take and add some!
                </ParagraphText>
                <ExampleText>
                  We will be completing this project over the next six months.
                </ExampleText>
              </ModalContent>
            </Modal>
          </FormField>
          <FormField>
            <Label>
              How are you going to get the information you need?
              <HelpButton
                onClick={() => setOpenModal('how')}
                disabled={createOrUpdatePlanRequest.inProgress}
              />
            </Label>
            <TextAreaField
              name="how"
              placeholder={`e.g. "We will be collecting data from the prescription charts of patients currently on ward 21."`}
            />
            <Modal
              isOpen={openModal === 'how'}
              close={() => setOpenModal(null)}
            >
              <ModalContent>
                <ModalHeader>How?</ModalHeader>
                <ModalSubHeader>
                  How are you going to get the information you need?
                </ModalSubHeader>
                <ParagraphText>
                  You might be getting information from old notes, online
                  systems, directly from staff/patients, or from a few different
                  places.
                </ParagraphText>
                <ExampleText>
                  We will be collecting data from the prescription charts of
                  patients currently on ward 21.
                </ExampleText>
              </ModalContent>
            </Modal>
          </FormField>
          <FormField>
            <Label>
              Why do you think this project is necessary?
              <HelpButton
                onClick={() => setOpenModal('why')}
                disabled={createOrUpdatePlanRequest.inProgress}
              />
            </Label>
            <TextAreaField
              name="why"
              placeholder={`e.g. "On ward round we noticed that a patient was prescribed the wrong antibiotic for a urinary infection. At the moment we don't know if this was a one off or is happening more often. NICE have produced guidelines on the correct antibiotic to use (https://cks.nice.org.uk/urinary-tract-infection-lower-women#!scenario) and our hospital guidelines are the same as this."`}
            />
            <Modal
              isOpen={openModal === 'why'}
              close={() => setOpenModal(null)}
            >
              <ModalContent>
                <ModalHeader>Why?</ModalHeader>
                <ModalSubHeader>
                  Why do you think this project is necessary?
                </ModalSubHeader>
                <ParagraphText>
                  There are lots of reasons why people start quality improvement
                  projects.
                </ParagraphText>
                <ParagraphText>
                  Maybe you've seen something you don't think is quite right.
                  Perhaps you already have some evidence that things need to be
                  done better. Or is it that we just don't know if what we're
                  currently doing is up to standard?
                </ParagraphText>
                <ParagraphText>
                  If there are any guidelines or standards to help us decide
                  what we should be doing, this is the place to mention them!
                </ParagraphText>
                <ExampleText>
                  On ward round we noticed that a patient was prescribed the
                  wrong antibiotic for a urinary infection. At the moment we
                  don't know if this was a one off or is happening more often.
                  NICE have produced guidelines on the correct antibiotic to use
                  (https://cks.nice.org.uk/urinary-tract-infection-lower-women#!scenario)
                  and our hospital guidelines are the same as this.
                </ExampleText>
              </ModalContent>
            </Modal>
          </FormField>
          <VariablesHeader>
            Variables
            <HelpButton
              onClick={() => setOpenModal('what')}
              disabled={createOrUpdatePlanRequest.inProgress}
            />
          </VariablesHeader>
          <Modal isOpen={openModal === 'what'} close={() => setOpenModal(null)}>
            <ModalContent>
              <ModalHeader>What?</ModalHeader>
              <ModalSubHeader>
                What are you collecting information on?
              </ModalSubHeader>
              <ParagraphText>
                What information do you need to answer your question? These are
                the variables that you and your contributors will input data
                for.
              </ParagraphText>
              <ExampleText>
                <ul>
                  <li>Age</li>
                  <li>Admission date</li>
                  <li>Name of antibiotic prescribed</li>
                  <li>Duration of antibiotic</li>
                </ul>
              </ExampleText>
              <ParagraphText>
                If you have changed how you are doing things to try to make an
                improvement, it's a good idea to also think about unintended
                consequences.
              </ParagraphText>
              <ParagraphText>
                For example, if you had created some new paperwork that needs to
                be filled in every time antibiotics are given, you might also
                look at "time taken to prescribe antibiotics".
              </ParagraphText>
            </ModalContent>
          </Modal>
          <FieldArray name="variables">
            {fieldArrayHelpers => (
              <>
                {formikProps.values.variables.map((variable, index) => (
                  <VariableRow key={variable.formId}>
                    <FormField>
                      <Label>Label</Label>
                      <InputField
                        name={`variables.${index}.label`}
                        hideError={true}
                      />
                    </FormField>
                    <FormField>
                      <Label>Type</Label>
                      <SelectField
                        name={`variables.${index}.typeCode`}
                        options={variableTypeCodeOptions}
                      />
                    </FormField>
                    <RemoveButton
                      onClick={() => fieldArrayHelpers.remove(index)}
                      disabled={
                        formikProps.values.variables.length === 1 ||
                        createOrUpdatePlanRequest.inProgress
                      }
                    >
                      <CrossIcon />
                    </RemoveButton>
                  </VariableRow>
                ))}
                <div>
                  <AddAnotherButton
                    onClick={() =>
                      fieldArrayHelpers.push(getInitialVariableFormModel())
                    }
                    disabled={createOrUpdatePlanRequest.inProgress}
                  >
                    <PlusIcon /> Add variable
                  </AddAnotherButton>
                </div>
              </>
            )}
          </FieldArray>
          <FormField>
            <Label>
              Additional information
              <HelpButton
                onClick={() => setOpenModal('additionalInformation')}
                disabled={createOrUpdatePlanRequest.inProgress}
              />
            </Label>
            <TextAreaField name="additionalInformation" />
            <Modal
              isOpen={openModal === 'additionalInformation'}
              close={() => setOpenModal(null)}
            >
              <ModalContent>
                <ModalHeader>Additional information</ModalHeader>
                <ModalSubHeader>
                  What other relevant information, if any, would you like to
                  give?
                </ModalSubHeader>
                <ParagraphText>
                  You may want to provide some background information on your
                  proposed project which doesn't fit into any of the preset
                  categories. If so, this is the place to put it.
                </ParagraphText>
              </ModalContent>
            </Modal>
          </FormField>
          <FormSubmitButton>Save</FormSubmitButton>
        </Form>
      )}
    </Formik>
  );
};

type DataEntryFormVariableFormModel = {
  formId: string;
  id: number | null;
  typeCode: DataEntryFormVariableTypeCode;
  label: string;
};

class VariableValidator extends Validator<DataEntryFormVariableFormModel> {
  constructor() {
    super();

    this.ruleFor('label')
      .notEmpty()
      .withMessage('This field is required')
      .maxLength(64)
      .withMessage('Please enter at most 64 characters');
  }
}

const variableValidator = new VariableValidator();

const getInitialVariableFormModel = (
  dataEntryFormVariable?: DataEntryFormVariableResponse | null
): DataEntryFormVariableFormModel => ({
  formId: v4(), // Randomly generated - used as React "key" prop
  id: dataEntryFormVariable?.dataEntryFormVariableId ?? null,
  typeCode: dataEntryFormVariable?.typeCode ?? 'FreeText',
  label: dataEntryFormVariable?.label ?? '',
});

type FormModel = {
  whichPatients: string;
  whoIsHelping: string;
  where: string;
  when: string;
  how: string;
  why: string;
  additionalInformation: string;
  variables: Array<DataEntryFormVariableFormModel>;
};

class FormValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleFor('whichPatients')
      .notEmpty()
      .withMessage('This field is required')
      .maxLength(5000);

    this.ruleFor('whoIsHelping')
      .notEmpty()
      .withMessage('This field is required')
      .maxLength(5000);

    this.ruleFor('where')
      .notEmpty()
      .withMessage('This field is required')
      .maxLength(5000);

    this.ruleFor('when')
      .notEmpty()
      .withMessage('This field is required')
      .maxLength(5000);

    this.ruleFor('how')
      .notEmpty()
      .withMessage('This field is required')
      .maxLength(5000);

    this.ruleFor('why')
      .notEmpty()
      .withMessage('This field is required')
      .maxLength(5000);

    this.ruleFor('additionalInformation').maxLength(5000);

    this.ruleForEach('variables').setValidator(() => variableValidator);
  }
}

const formValidator = new FormValidator();

const getInitialFormModel = (
  projectCyclePlan?: ProjectCyclePlanResponse,
  dataEntryFormVariables?: Array<DataEntryFormVariableResponse>
): FormModel => ({
  whichPatients: projectCyclePlan?.whichPatients ?? '',
  whoIsHelping: projectCyclePlan?.whoIsHelping ?? '',
  where: projectCyclePlan?.where ?? '',
  when: projectCyclePlan?.when ?? '',
  how: projectCyclePlan?.how ?? '',
  why: projectCyclePlan?.why ?? '',
  variables:
    dataEntryFormVariables == null || !dataEntryFormVariables.length
      ? [getInitialVariableFormModel()]
      : dataEntryFormVariables?.map(getInitialVariableFormModel),
  additionalInformation: projectCyclePlan?.additionalInformation ?? '',
});

const variableTypeCodeOptions = dataEntryFormVariableTypes.map(type => ({
  text: type.name,
  value: type.typeCode,
}));

const Intro = styled.div`
  margin-bottom: ${size250};
`;

const SupportingText = styled.div`
  margin-bottom: ${size100};
  line-height: ${lineHeightMedium};
`;

const VariablesHeader = styled.div`
  font-size: ${fontSize400};
  font-weight: ${fontWeightBold};
  display: flex;
  align-items: center;
  margin-bottom: ${size150};
`;

const VariableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: ${size150};

  ${FormField} {
    flex: 1;
    margin-right: ${size050};
    margin-bottom: 0;
  }
`;

const RemoveButton = styled(SecondaryButton)`
  padding: 0 ${size050};

  svg {
    opacity: 0.75;
  }
`;

const AddAnotherButton = styled(SecondaryButton)`
  width: 100%;
  margin-bottom: ${size200};

  svg {
    margin-right: ${size050};
    opacity: 0.75;
  }
`;

const ParagraphText = styled.div`
  line-height: ${lineHeightMedium};
  margin-bottom: ${size150};
`;

const ExampleText = styled.div`
  margin: ${size200} 0 ${size250} 0;
  font-style: italic;
  padding: ${size100} ${size150};
  border-left: solid 5px ${primary700};
  background-color: ${primary900};
  color: ${primary200};
  line-height: ${lineHeightWide};

  &:last-of-type {
    margin-bottom: ${size100};
  }

  ul {
    margin: 0;
    padding-left: ${size150};
  }
`;

import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { SecondaryButton } from './Buttons';
import { gray800 } from '../styling/colours';
import { formatFileSize } from '../helpers/fileHelpers';

type Props = {
  selectedFile: File | null;
  onFileSelected: (file: File | null) => void;
  disabled?: boolean;
};

export const FileInput = ({
  selectedFile,
  onFileSelected,
  disabled,
}: Props) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputFiles = event?.target?.files;

    if (inputFiles == null) {
      onFileSelected(null);
      return;
    }

    const file = inputFiles[0];

    onFileSelected(file ?? null);
  };

  return (
    <>
      <Container>
        <FakeInput
          onClick={() => hiddenFileInput.current?.click()}
          disabled={disabled ? 'true' : undefined}
        >
          {selectedFile == null
            ? 'No file selected'
            : `${selectedFile.name} (${formatFileSize(selectedFile.size)})`}
        </FakeInput>
        <SecondaryButton
          onClick={() => hiddenFileInput.current?.click()}
          disabled={disabled}
        >
          Select file
        </SecondaryButton>
      </Container>
      <input
        type="file"
        onChange={handleOnChange}
        style={{ visibility: 'hidden', position: 'fixed', height: 0 }}
        ref={hiddenFileInput}
      />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FakeInput = styled.div<{ disabled?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 100%;
  background: white;
  border: solid 1px ${gray800};
  padding: 10px;
  border-radius: 5px;
  margin-right: 8px;
  cursor: ${props => (props.disabled != null ? 'not-allowed' : 'pointer')};
`;

import styled from 'styled-components/macro';
import { borderRadiusMedium } from '../styling/borders';
import {
  gray800,
  gray900,
  gray500,
  gray700,
  gray600,
  gray750,
  gray400,
  gray950,
} from '../styling/colours';
import { size150, size250, size300, size100, size050 } from '../styling/sizes';
import { fontSize000 } from '../styling/fontSizes';
import { letterSpacingWide } from '../styling/letterSpacing';
import { shadow000 } from '../styling/shadows';
import { lineHeightMedium } from '../styling/lineHeights';
import { transitionMedium } from '../styling/transitions';

export const Th = styled.th`
  padding: ${size100} ${size250};
  border-left-width: 0;
  border-right-width: 0;
  text-align: left;
  line-height: ${lineHeightMedium};
`;

export const Td = styled.td`
  padding: ${size150} ${size250};
  border-left-width: 0;
  border-right-width: 0;
  text-align: left;
  line-height: ${lineHeightMedium};
`;

export const Tr = styled.tr`
  ${Th} {
    background-color: ${gray900};
    color: ${gray500};
    font-size: ${fontSize000};
    letter-spacing: ${letterSpacingWide};

    &:first-of-type {
      border-top-left-radius: ${borderRadiusMedium};
    }

    &:last-of-type {
      border-top-right-radius: ${borderRadiusMedium};
    }
  }

  ${Td} {
    background-color: white;
    border-top: solid 1px ${gray800};
  }

  &:last-of-type {
    ${Td} {
      &:first-of-type {
        border-bottom-left-radius: ${borderRadiusMedium};
      }

      &:last-of-type {
        border-bottom-right-radius: ${borderRadiusMedium};
      }
    }
  }
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const Table = styled.table`
  table-layout: fixed;
  border-radius: ${borderRadiusMedium};
  box-shadow: ${shadow000};
  border-spacing: 0;
`;

export const ChevronHeader = styled(Th)`
  width: ${size300};
`;

export const ChevronCell = styled(Td)`
  color: ${gray700};
  padding-left: 0;
  padding-right: 0;

  svg {
    height: ${size250};
    width: ${size250};
  }
`;

export const TableWithClickableRows = styled(Table)`
  ${Tr} {
    ${Td} {
      cursor: pointer;
      transition: all 0.25s ease;
    }

    &:hover {
      ${Td} {
        background-color: ${gray950};
      }
    }
  }
`;

export const ListTable = styled(TableWithClickableRows)`
  box-shadow: none;
  width: 100%;

  ${Th} {
    padding: 0;
  }
`;

export const StandaloneTable = styled(Table)`
  width: 100%;

  ${Th} {
    color: ${gray600};
    background-color: white;
    text-transform: none;
  }

  ${Tr} {
    &:first-of-type {
      ${Td} {
        border-top: solid 3px ${gray750};
      }
    }
  }
`;

export const PlainTable = styled(Table)`
  width: 100%;
  table-layout: fixed;
  box-shadow: none;

  ${Th} {
    color: ${gray400};
  }

  ${Td} {
    border: none;
    padding-top: ${size050};
    padding-bottom: ${size050};
  }

  ${Th}, ${Td} {
    padding-left: 0;
    background-color: transparent;
  }
`;

export const TableOverlay = styled.div<{ show: boolean }>`
  position: relative;

  :after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: ${props => (props.show ? 'auto' : 'none')};
    cursor: default;
    background-color: white;
    z-index: 10;
    transition: all ${transitionMedium} ease;
    user-select: ${props => (props.show ? 'none' : 'auto')};
    opacity: ${props => (props.show ? '0.6' : '0')};
  }
`;

export type DataEntryForm = {
  dataEntryFormId: string;
  projectCyclePlanId: string;
};

export type DataEntryFormVariable = {
  dataEntryFormVariableId: string;
  dataEntryFormId: string;
  typeCode: DataEntryFormVariableTypeCode;
  label: string;
};

export type DataEntryFormVariableTypeCode =
  | 'FreeText'
  | 'Numeric'
  | 'Checkbox'
  | 'MultipleChoice';

export type DataEntryFormVariableType = {
  typeCode: DataEntryFormVariableTypeCode;
  name: string;
};

export const dataEntryFormVariableTypes: Array<DataEntryFormVariableType> = [
  { typeCode: 'FreeText', name: 'Free Text' },
  { typeCode: 'Numeric', name: 'Numeric' },
  { typeCode: 'Checkbox', name: 'Checkbox' },
  //{ typeCode: 'MultipleChoice', name: 'Multiple Choice' },
];

export type DataEntryFormVariableOption = {
  dataEntryFormVariableOptionId: string;
  dataEntryFormVariableId: string;
  value: string;
};

export type DataEntryFormSubmission = {
  dataEntryFormSubmissionId: string;
  dataEntryFormId: string;
  userId: string;
  createdDateTime: string;
};

export type DataEntryFormSubmissionVariable = {
  dataEntryFormSubmissionVariableId: string;
  dataEntryFormSubmissionId: string;
  dataEntryFormVariableId: string;
  value: string;
};

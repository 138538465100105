import React from 'react';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  DangerButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type CancelPendingUserAccountCommand = {
  userAccountId: number;
};

type Props = {
  close: (andRefresh: boolean) => void;
  userAccountId: number;
  emailAddress: string;
};

export const CancelPendingUserAccount = (props: Props) => {
  const cancelPendingUserAccountRequest = usePostWithoutResponse<
    CancelPendingUserAccountCommand
  >('CancelPendingUserAccount');

  const cancelPendingUserAccount = () => {
    cancelPendingUserAccountRequest.makeRequest({
      body: { userAccountId: props.userAccountId },
      onSuccess: () => props.close(true),
    });
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Cancel invite</ModalHeader>
        <p>
          Are you sure you wish to cancel the invite for{' '}
          <strong>{props.emailAddress}</strong>?
        </p>
        <ApiErrorBox
          error={cancelPendingUserAccountRequest.error}
          withMargin={true}
        />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={() => props.close(false)}
            disabled={cancelPendingUserAccountRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <DangerButton
            onClick={cancelPendingUserAccount}
            disabled={cancelPendingUserAccountRequest.inProgress}
          >
            Confirm
          </DangerButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

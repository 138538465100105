import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';

export const uploadBlobWithSasUrl = async (
  file: File,
  containerName: string,
  blobName: string,
  sas: string
) => {
  const prefix = sas.split('.blob.core.windows.net')[0];
  const sasToken = sas.split('?')[1];
  const sasUri = `${prefix}.blob.core.windows.net/?${sasToken}`;
  const blobService = new BlobServiceClient(sasUri);

  const containerClient: ContainerClient = blobService.getContainerClient(
    containerName
  );

  const blobClient = containerClient.getBlockBlobClient(blobName);
  const options = { blobHTTPHeaders: { blobContentType: file.type } };
  await blobClient.uploadData(file, options);
};

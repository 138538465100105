import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form } from '../../../form/Form';
import { FormField } from '../../../form/FormField';
import { Label } from '../../../form/Label';
import { TextAreaField } from '../../../form/TextAreaField';
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalSubHeader,
} from '../../../shared/Modal';
import {
  ButtonRowAlignRight,
  SecondaryButton,
  IconButton,
} from '../../../shared/Buttons';
import { AttachIcon, CrossIcon } from '../../../icons/Icons';
import styled from 'styled-components/macro';
import {
  size050,
  size000,
  size100,
  size150,
  size200,
} from '../../../styling/sizes';
import { gray800, gray900 } from '../../../styling/colours';
import { fontSize100 } from '../../../styling/fontSizes';
import { GetSasForActDataAttachmentUploadCommand } from './GetSasForActDataAttachmentUploadCommand';
import { GetSasForActDataAttachmentUploadResponse } from './GetSasForActDataAttachmentUploadResponse';
import { ProjectContext } from '../ProjectContext';
import { usePostWithResponse } from '../../../api/usePostWithResponse';
import { uploadBlobWithSasUrl } from '../../../helpers/blobHelpers';
import { usePostWithoutResponse } from '../../../api/usePostWithoutResponse';
import { VerifyActDataAttachmentCommand } from './VerifyActDataAttachmentCommand';
import { SubmitProjectForCompletionCommand } from './SubmitProjectForCompletionCommand';
import { ApiErrorResult } from '../../../api/ApiErrorResult';
import { ApiErrorBox } from '../../../api/ApiErrorBox';
import { FormSubmitButton } from '../../../form/FormSubmitButton';

type Props = {
  close: () => void;
};

export const CompleteProject = (props: Props) => {
  const { getProject, getCycle, hardReloadProject } = useContext(
    ProjectContext
  );
  const project = getProject();
  const currentCycle = getCycle();

  const [attachments, setAttachments] = useState<Array<File>>([]);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onAddAttachment = (event: ChangeEvent<HTMLInputElement>) => {
    const inputFiles = event?.target?.files;
    if (inputFiles == null) return;
    const file = inputFiles[0];
    if (file == null) return;
    setAttachments(currentAttachments => [...currentAttachments, file]);
  };

  const removeAttachment = (attachmentIndex: number) => {
    setAttachments(currentAttachments =>
      currentAttachments.filter((_, index) => index !== attachmentIndex)
    );
  };

  const [failedToUpload, setFailedToUpload] = useState(false);

  const getSasForUploadRequest = usePostWithResponse<
    GetSasForActDataAttachmentUploadCommand,
    GetSasForActDataAttachmentUploadResponse
  >('GetSasForActDataAttachmentUpload');

  const verifyAttachmentRequest = usePostWithoutResponse<
    VerifyActDataAttachmentCommand
  >('VerifyActDataAttachment');

  const submitProjectForCompletionRequest = usePostWithoutResponse<
    SubmitProjectForCompletionCommand
  >('SubmitProjectForCompletion');

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    uploadAllAttachments().then(() => {
      submitProjectForCompletionRequest.makeRequest({
        body: {
          projectId: project.projectId,
          writeUp: formModel.writeUp,
        },
        onSuccess: () => {
          hardReloadProject();
        },
        onError: () => {
          formikHelpers.setSubmitting(false);
        },
      });
    });
  };

  const uploadAllAttachments = async () => {
    setFailedToUpload(false);

    for (const attachment of attachments) {
      try {
        await new Promise((resolve, reject) => {
          getSasForUploadRequest.makeRequest({
            body: {
              projectCycleId: currentCycle.projectCycleId,
              fileName: attachment.name,
              fileSizeInBytes: attachment.size,
              fileType: attachment.type,
            },
            onSuccess: response => {
              uploadBlobWithSasUrl(
                attachment,
                response.blobContainerName,
                response.blobName,
                response.sas
              )
                .then(() => {
                  verifyAttachmentRequest.makeRequest({
                    body: {
                      projectCycleId: currentCycle.projectCycleId,
                      fileName: attachment.name,
                    },
                    onSuccess: () => {
                      resolve();
                    },
                    onError: () => {
                      reject('Failed to verify attachment');
                      return;
                    },
                  });
                })
                .catch(uploadError => {
                  console.error(uploadError);
                  setFailedToUpload(true);
                  reject('Failed to upload attachment');
                  return;
                });
            },
            onError: () => {
              reject('Failed to get SAS for upload');
            },
          });
        });
      } catch (error) {
        console.error(`Failed to upload attachment ${attachment.name}`, error);
        return;
      }
    }
  };

  const error: ApiErrorResult | null = failedToUpload
    ? {
        userVisibleMessage: 'Failed to upload attachments, please try again',
        exceptionMessage: null,
        stackTrace: null,
      }
    : getSasForUploadRequest.error ??
      verifyAttachmentRequest.error ??
      submitProjectForCompletionRequest.error ??
      null;

  return (
    <Formik<FormModel> initialValues={{ writeUp: '' }} onSubmit={onSubmit}>
      {form => (
        <Form>
          <ModalContent>
            <ModalHeader>Complete Project</ModalHeader>
            <ModalSubHeader>
              Marks the Project as completed. Requires approval.
            </ModalSubHeader>
            <FormField>
              <Label>Write-up</Label>
              <TextAreaField name="writeUp" />
            </FormField>
            <FormField>
              <Label>Supporting documents</Label>
              {attachments.length === 0 ? (
                <div>You have not attached any documents yet.</div>
              ) : (
                <AttachmentsRow>
                  {attachments.map((attachment, attachmentIndex) => (
                    <Attachment key={attachment.name}>
                      <AttachmentFileName>{attachment.name}</AttachmentFileName>
                      {!form.isSubmitting && (
                        <AttachmentButton
                          type="button"
                          onClick={e => {
                            e.stopPropagation();
                            removeAttachment(attachmentIndex);
                          }}
                        >
                          <CrossIcon />
                        </AttachmentButton>
                      )}
                    </Attachment>
                  ))}
                </AttachmentsRow>
              )}
              <AttachDocumentsButton
                type="button"
                icon="true"
                onClick={() => hiddenFileInput.current?.click()}
              >
                <AttachIcon /> Attach document
              </AttachDocumentsButton>
              <input
                type="file"
                onChange={onAddAttachment}
                style={{ visibility: 'hidden', position: 'fixed', height: 0 }}
                ref={hiddenFileInput}
              />
            </FormField>
            <ApiErrorBox error={error} withMargin={true} />
          </ModalContent>
          <ModalFooter>
            <ButtonRowAlignRight>
              <SecondaryButton
                onClick={props.close}
                disabled={form.isSubmitting}
              >
                Cancel
              </SecondaryButton>
              <FormSubmitButton>Submit</FormSubmitButton>
            </ButtonRowAlignRight>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

type FormModel = {
  writeUp: string;
};

const AttachDocumentsButton = styled(SecondaryButton)`
  margin-top: ${size200};
`;

const AttachmentsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const Attachment = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${size000} ${size100};
  border-radius: 100px;
  border: solid 1px ${gray800};
  margin-right: ${size050};
  background-color: ${gray900};
`;

const AttachmentButton = styled(IconButton)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${size150};
`;

const AttachmentFileName = styled.div`
  margin: 0 ${size000};
  font-size: ${fontSize100};
`;

import styled from 'styled-components/macro';
import { size000, size050, size100, size200 } from '../styling/sizes';
import { pink300 } from '../styling/colours';
import { fontSize000 } from '../styling/fontSizes';
import { fontWeightBold } from '../styling/fontWeights';

export const NotificationsBadge = styled.div`
  height: ${size200};
  border-radius: ${size100};
  background-color: ${pink300};
  padding: ${size000} ${size050};
  color: white;
  font-size: ${fontSize000};
  font-weight: ${fontWeightBold};
  display: inline-block;
  margin-left: ${size100};
  transform: scale(0.9);
`;

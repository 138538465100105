import React, { useContext, useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from '../../../shared/Modal';
import { usePostWithResponse } from '../../../api/usePostWithResponse';
import { GetSharedAccessSignatureForStudyAttachmentUploadCommand } from './GetSharedAccessSignatureForStudyAttachmentUploadCommand';
import { GetSharedAccessSignatureForStudyAttachmentUploadResponse } from './GetSharedAccessSignatureForStudyAttachmentUploadResponse';
import { VerifyStudyAttachmentCommand } from './VerifyStudyAttachmentCommand';
import { ProjectContext } from '../ProjectContext';
import { uploadBlobWithSasUrl } from '../../../helpers/blobHelpers';
import { FileInput } from '../../../shared/FileInput';
import {
  ButtonRowAlignRight,
  PrimaryButton,
  SecondaryButton,
} from '../../../shared/Buttons';
import { VerifyStudyAttachmentResponse } from './VerifyStudyAttachmentResponse';
import { ApiErrorBox } from '../../../api/ApiErrorBox';
import { ApiErrorResult } from '../../../api/ApiErrorResult';
import styled from 'styled-components/macro';
import { size200, size350 } from '../../../styling/sizes';
import { green400, green900 } from '../../../styling/colours';
import { TickIcon } from '../../../icons/Icons';
import { ProjectCycleStudyAttachmentResponse } from '../../../features/projects/GetProjectResponse';
import produce from 'immer';

type Props = {
  isOpen: boolean;
  close: () => void;
};

export const UploadStudyAttachmentModal = (props: Props) => {
  const { getCycle, setProject, getProject } = useContext(ProjectContext);
  const project = getProject();
  const currentCycle = getCycle();

  const addStudyAttachment = (
    newAttachment: ProjectCycleStudyAttachmentResponse
  ) => {
    const newProject = produce(project, draftProject => {
      const latestCycle = draftProject.cycles.filter(
        c => c.projectCycleId === currentCycle.projectCycleId
      )[0]!;

      latestCycle.study!.attachments.push(newAttachment);
    });

    setProject(newProject);
  };

  const getSasForUploadRequest = usePostWithResponse<
    GetSharedAccessSignatureForStudyAttachmentUploadCommand,
    GetSharedAccessSignatureForStudyAttachmentUploadResponse
  >('GetSharedAccessSignatureForStudyAttachmentUpload');

  const verifyAttachmentRequest = usePostWithResponse<
    VerifyStudyAttachmentCommand,
    VerifyStudyAttachmentResponse
  >('VerifyStudyAttachment');

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [isUploading, setIsUploading] = useState(false);
  const [failedToUpload, setFailedToUpload] = useState(false);

  const [success, setSuccess] = useState(false);

  const close = () => {
    if (success && verifyAttachmentRequest.response != null) {
      addStudyAttachment(verifyAttachmentRequest.response);
    }

    setSelectedFile(null);
    setSuccess(false);
    props.close();
  };

  const uploadSelectedFile = () => {
    if (selectedFile == null) {
      return;
    }

    const file = selectedFile;
    const fileName = selectedFile.name;
    const fileSizeInBytes = selectedFile.size;
    const fileType = selectedFile.type;

    getSasForUploadRequest.makeRequest({
      body: {
        projectCycleId: currentCycle.projectCycleId,
        fileName,
        fileSizeInBytes,
        fileType,
      },
      onSuccess: response => {
        setFailedToUpload(false);
        setIsUploading(true);

        uploadBlobWithSasUrl(
          file,
          response.blobContainerName,
          response.blobName,
          response.sharedAccessSignature
        )
          .then(() => {
            verifyAttachmentRequest.makeRequest({
              body: {
                projectCycleId: currentCycle.projectCycleId,
                fileName,
              },
              onSuccess: () => {
                setSuccess(true);
              },
            });
          })
          .catch(() => {
            setFailedToUpload(true);
          })
          .finally(() => {
            setIsUploading(false);
          });
      },
    });
  };

  const inProgress =
    getSasForUploadRequest.inProgress ||
    isUploading ||
    verifyAttachmentRequest.inProgress;

  const apiError: ApiErrorResult | null = failedToUpload
    ? {
        userVisibleMessage: 'Upload failed, please try again',
        exceptionMessage: null,
        stackTrace: null,
      }
    : getSasForUploadRequest.error ?? verifyAttachmentRequest.error ?? null;

  return (
    <Modal isOpen={props.isOpen} close={close} preventClose={!success}>
      {!success ? (
        <>
          <ModalContent>
            <ModalHeader>Upload attachment</ModalHeader>
            <FileInput
              selectedFile={selectedFile}
              onFileSelected={setSelectedFile}
              disabled={inProgress}
            />
            <ApiErrorBox error={apiError} withMargin={true} />
          </ModalContent>
          <ModalFooter>
            <ButtonRowAlignRight>
              <SecondaryButton disabled={inProgress} onClick={close}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                onClick={uploadSelectedFile}
                disabled={selectedFile == null || inProgress}
              >
                {inProgress ? 'Uploading...' : 'Upload'}
              </PrimaryButton>
            </ButtonRowAlignRight>
          </ModalFooter>
        </>
      ) : (
        <ModalContent>
          <SuccessMessageContainer>
            <SuccessIconContainer>
              <SuccessIcon />
            </SuccessIconContainer>
            <ModalSubHeader>File uploaded successfully</ModalSubHeader>
          </SuccessMessageContainer>
        </ModalContent>
      )}
    </Modal>
  );
};

const SuccessMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${size200};
`;

const SuccessIconContainer = styled.div`
  width: ${size350};
  height: ${size350};
  border-radius: 100%;
  background-color: ${green900};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${size200} 0;
`;

const SuccessIcon = styled(TickIcon)`
  color: ${green400};
  height: 50%;
  width: 50%;
`;

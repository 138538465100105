import { ApiErrorResult } from './ApiErrorResult';
import { useApiRequest } from './useApiRequest';

type MakeRequestArguments<TRequest extends object> = {
  query?: { [key: string]: string };
  body: TRequest;
  onSuccess?: () => void;
  onError?: (error: ApiErrorResult) => void;
};

export const usePostWithoutResponse = <TRequest extends object>(
  endpoint: string
) => {
  const apiRequest = useApiRequest<TRequest>(endpoint, 'post');

  const makeRequest = (args: MakeRequestArguments<TRequest>) =>
    apiRequest.makeRequest(args);

  const { inProgress, error } = apiRequest;

  return { makeRequest, inProgress, error };
};

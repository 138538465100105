import React from 'react';
import { usePostWithResponse } from '../../../api/usePostWithResponse';
import { GetSasForActDataAttachmentDownloadCommand } from './GetSasForActDataAttachmentDownloadCommand';
import { GetSasForActDataAttachmentDownloadResponse } from './GetSasForActDataAttachmentDownloadResponse';
import {
  Attachment,
  AttachmentFileName,
  AttachmentsRow,
} from '../../../shared/Attachment';

type ActDataAttachment = {
  projectCycleActDataAttachmentId: number;
  fileName: string;
  fileSizeInBytes: number;
};

type Props = {
  projectCycleId: number;
  attachments: Array<ActDataAttachment>;
};

export const ActDataAttachments = ({ projectCycleId, attachments }: Props) => {
  const getSasForDownloadRequest = usePostWithResponse<
    GetSasForActDataAttachmentDownloadCommand,
    GetSasForActDataAttachmentDownloadResponse
  >('GetSasForActDataAttachmentDownload');

  const downloadAttachment = (projectCycleActDataAttachmentId: number) => {
    if (getSasForDownloadRequest.inProgress) {
      return;
    }

    getSasForDownloadRequest.makeRequest({
      body: {
        projectCycleId,
        projectCycleActDataAttachmentId,
      },
      onSuccess: response => {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = response.sharedAccessSignature;
        hiddenElement.target = '_blank';
        hiddenElement.click();
        setTimeout(() => hiddenElement.remove(), 1_000);
      },
      onError: error => {
        alert(`Failed to get SAS: ${error.userVisibleMessage}`); // TODO - tidy this up
      },
    });
  };

  return (
    <>
      {attachments.length === 0 ? (
        <div>No attachments were uploaded.</div>
      ) : (
        <AttachmentsRow>
          {attachments.map(attachment => (
            <Attachment
              key={attachment.projectCycleActDataAttachmentId}
              disable={getSasForDownloadRequest.inProgress ? 'true' : 'false'}
              onClick={() =>
                downloadAttachment(attachment.projectCycleActDataAttachmentId)
              }
            >
              <AttachmentFileName>{attachment.fileName}</AttachmentFileName>
            </Attachment>
          ))}
        </AttachmentsRow>
      )}
    </>
  );
};

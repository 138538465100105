import React, { useContext } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { find, values, includes, maxBy } from 'lodash';
import styled from 'styled-components/macro';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardContentHeading,
} from '../../shared/Card';
import { ViewCycle } from './ViewCycle';
import { ProjectHeader } from './ProjectHeader';
import { lineHeightMedium } from '../../styling/lineHeights';
import { PlanDetails } from './planning/PlanDetails';
import { ProjectBreadcrumbs } from './ProjectBreadcrumbs';
import { UserAccountContext } from '../../features/userAccounts/UserAccountContext';
import { ProjectContext } from './ProjectContext';
import { ActDataAttachments } from './acting/ActDataAttachments';

export const ViewProject = () => {
  const { getProject, hardReloadProject } = useContext(ProjectContext);
  const project = getProject();

  const { cycleNumberParam } = useParams();

  const userAccountContext = useContext(UserAccountContext);
  const currentUserAccountId = userAccountContext.getUserAccountId();

  const isMyProject =
    currentUserAccountId === project.leadUserAccount.userAccountId;

  const projectContributorUserAccountIds = project.contributors.map(
    c => c.userAccountId
  );

  const isContributor =
    isMyProject ||
    includes(projectContributorUserAccountIds, currentUserAccountId);

  const joinRequest =
    find(
      values(project.contributorJoinRequests),
      r => r.userAccountId === currentUserAccountId
    ) || null;

  const hasRequestedToJoin = joinRequest != null;

  const cycleNumber =
    cycleNumberParam == null ? null : Number(cycleNumberParam);

  const totalNumberOfCycles = project.cycles.length;

  if ((cycleNumber == null || isNaN(cycleNumber)) && !project.isComplete) {
    return (
      <Redirect
        to={`/app/projects/${project.projectId}/cycles/${totalNumberOfCycles}`}
      />
    );
  }

  const finalCycle = !project.isComplete
    ? null
    : maxBy(project.cycles, c => c.cycleNumber);

  const finalCycleActData = finalCycle?.actData;

  if (cycleNumber == null && finalCycle != null && finalCycleActData != null) {
    const finalCycleActDataAttachments = finalCycleActData.attachments;

    return (
      <div>
        <ProjectBreadcrumbs project={project} />
        <ProjectHeader project={project} />
        <Card>
          <CardHeader>
            <CardTitle>Outcome</CardTitle>
          </CardHeader>
          <CardContent>
            <CardContentHeading>Write-Up</CardContentHeading>
            <WriteUp>{finalCycleActData.writeUp}</WriteUp>
          </CardContent>
          <CardContent>
            <CardContentHeading>Supporting documents</CardContentHeading>
            {finalCycleActDataAttachments.length === 0 ? (
              <div>No attachments were uploaded.</div>
            ) : (
              <ActDataAttachments
                projectCycleId={finalCycle.projectCycleId}
                attachments={finalCycleActDataAttachments}
              />
            )}
          </CardContent>
        </Card>
      </div>
    );
  }

  const cycle = find(project.cycles, c => c.cycleNumber === cycleNumber)!;
  const plan = cycle?.plan;
  const dataEntryForm = plan?.dataEntryForm;
  const planVariables = dataEntryForm?.variables;

  return (
    <>
      <ProjectBreadcrumbs project={project} />
      {hasRequestedToJoin && (
        <Card>
          <CardContent>
            <div>
              You have requested to join this project as a contributor. You will
              receive a notification when{' '}
              <strong>{project.leadUserAccount.fullName}</strong> has reviewed
              your request.
            </div>
          </CardContent>
        </Card>
      )}
      <ProjectHeader project={project} />
      {isContributor ? (
        <ViewCycle
          key={cycleNumber!}
          project={project}
          cycle={cycle}
          refreshProject={hardReloadProject}
        />
      ) : (
        plan != null && (
          <Card>
            <CardHeader>
              <CardTitle>Plan</CardTitle>
            </CardHeader>
            <CardContent>
              <PlanDetails plan={plan} variables={planVariables!} />
            </CardContent>
          </Card>
        )
      )}
    </>
  );
};

const WriteUp = styled.div`
  line-height: ${lineHeightMedium};
  white-space: pre-wrap;
`;

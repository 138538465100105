import { useContext, useEffect, useRef, useState } from 'react';
import superagent from 'superagent';
import { ApiErrorResult, getApiErrorResult } from './ApiErrorResult';
import { isAbortedError } from './apiHelpers';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { useHistory } from 'react-router-dom';
import produce, { Draft } from 'immer';

const getUrl = (endpoint: string) => {
  if (window.location.origin === 'http://localhost:3000') {
    return 'http://localhost:7071/api/' + endpoint;
  } else if (window.location.origin.includes('int.qicompass.com')) {
    return 'https://intapi.qicompass.com/api/' + endpoint;
  } else {
    throw new Error('Could not determine API URL from environment');
  }
};

type MakeRequestArguments<
  TRequest extends object | undefined = undefined,
  TResponse = undefined
> = {
  query?: { [key: string]: string };
  body?: TRequest;
  onSuccess?: (response: TResponse) => void;
  onError?: (error: ApiErrorResult) => void;
};

type State<TResponse = undefined> = {
  response: TResponse | null;
  inProgress: boolean;
  error: ApiErrorResult | null;
};

export const useApiRequest = <
  TRequest extends object | undefined = undefined,
  TResponse = undefined
>(
  endpoint: string,
  method: 'get' | 'post'
) => {
  const authenticationContext = useContext(AuthenticationContext);
  const history = useHistory();

  const [state, setState] = useState<State<TResponse>>({
    response: null,
    inProgress: false,
    error: null,
  });

  const currentRequest = useRef<superagent.Request | null>(null);
  const isAborting = useRef<boolean>(false);

  const abortRequest = () => {
    if (currentRequest.current != null) {
      isAborting.current = true;
      currentRequest.current.abort();
      currentRequest.current = null;
    }
  };

  useEffect(() => {
    // Abort the request as a clean-up action
    return abortRequest;
  }, []);

  const makeRequest = ({
    query,
    body,
    onSuccess,
    onError,
  }: MakeRequestArguments<TRequest, TResponse>) => {
    abortRequest();

    setState({ response: null, inProgress: true, error: null });

    const request = superagent[method](getUrl(endpoint))
      .withCredentials()
      .query(query ?? {})
      .send(body)
      .set('Accept', '*/*')
      .set('Content-Type', 'application/json');

    currentRequest.current = request;

    return request
      .then(rawResponse => {
        const response = rawResponse.body as TResponse;

        setState({
          response,
          inProgress: false,
          error: null,
        });

        if (onSuccess) {
          onSuccess(response);
        }

        currentRequest.current = null;

        return response;
      })
      .catch(rawError => {
        if (isAbortedError(rawError) && isAborting.current) {
          // Swallow the error and bail out
          isAborting.current = false;
          return;
        }

        const errorCode = rawError?.response?.status;

        if (errorCode === 401) {
          // The session has clearly expired
          // TODO - Can we do a slightly nicer UI (show a modal or something?)
          authenticationContext.setState({
            isAuthenticated: false,
            accountType: null,
          });
          history.push('/login');
        }

        console.error('API request returned error', rawError);

        const error = getApiErrorResult(rawError);

        setState({
          response: null,
          inProgress: false,
          error,
        });

        if (onError) {
          onError(error);
        }

        currentRequest.current = null;
      });
  };

  const updateResponse = (
    updater: (draftResponse: Draft<TResponse>) => void
  ) => {
    const { response } = state;

    if (response == null) return;

    const newResponse = produce(response, draftResponse => {
      updater(draftResponse);
    });

    setState(currentState => ({
      ...currentState,
      response: newResponse,
    }));
  };

  return { updateResponse, makeRequest, ...state };
};

import React from 'react';
import styled from 'styled-components/macro';
import { MoreIcon } from '../icons/Icons';
import { transitionMedium } from '../styling/transitions';
import { subtleShadow } from '../styling/shadows';

type Props = {
  onClick?: () => void;
};

export const MoreButton = (props: Props) => {
  return (
    <EllipsisButton onClick={props.onClick}>
      <MoreIcon />
    </EllipsisButton>
  );
};

const EllipsisButton = styled.button`
  outline: none;
  border: none;
  background: none;
  opacity: 0.5;
  cursor: pointer;
  transition: all ${transitionMedium} ease;
  border-radius: 100px;

  &:hover,
  &:focus {
    opacity: 1;
    box-shadow: ${subtleShadow};
  }
`;

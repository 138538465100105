import styled from 'styled-components/macro';
import { size300, size100 } from '../styling/sizes';
import { gray500 } from '../styling/colours';
import { fontSize300 } from '../styling/fontSizes';
import { letterSpacingWide } from '../styling/letterSpacing';

export const Section = styled.div`
  margin: ${size300} 0;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const SectionHeader = styled.div`
  color: ${gray500};
  text-transform: uppercase;
  font-size: ${fontSize300};
  letter-spacing: ${letterSpacingWide};
  margin-bottom: ${size100};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

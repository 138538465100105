import styled from 'styled-components/macro';
import {
  primary400,
  gray900,
  primary300,
  primary500,
  gray100,
  gray400,
  primary800,
  primary700,
  gray600,
  pink300,
  primary200,
  red400,
  red300,
  red600,
  red200,
  gray950,
  gray800,
} from '../styling/colours';
import {
  size300,
  size200,
  size250,
  size050,
  size275,
  size100,
  size000,
} from '../styling/sizes';
import { fontSize100, fontSize000, fontSize300 } from '../styling/fontSizes';
import { borderRadiusMedium } from '../styling/borders';
import { transitionFast } from '../styling/transitions';
import { letterSpacingSlightlyWide } from '../styling/letterSpacing';
import { subtleShadow } from '../styling/shadows';
import { fontWeightBold } from '../styling/fontWeights';

type Props = {
  size?: 'small' | 'medium' | 'large';
  icon?: string;
};

const BaseButton = styled.button<Props>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  letter-spacing: ${letterSpacingSlightlyWide};
  outline: none;
  border: solid 1px transparent;
  border-radius: ${borderRadiusMedium};
  font-size: ${props =>
    (props.size === 'small' && fontSize000) ||
    (props.size === 'large' && fontSize300) ||
    fontSize100};
  height: ${props =>
    (props.size === 'small' && size250) ||
    (props.size === 'large' && size300) ||
    size275};
  padding: 0
    ${props =>
      (props.size === 'small' && size100) ||
      (props.size === 'large' && size250) ||
      size200};
  transition: all ${transitionFast} ease;
  cursor: pointer;

  svg {
    height: 21px;
    width: 21px;
    position: relative;
    margin-right: ${props => (props.icon ? 10 : 0)}px;
    margin-left: ${props => (props.icon ? '-' + 10 : 0)}px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }
`;

export const PrimaryButton = styled(BaseButton).attrs({ type: 'button' })`
  background-color: ${primary400};
  color: ${gray900};

  &:hover {
    background-color: ${primary500};
  }

  &:active {
    background-color: ${primary300};
  }

  &:focus {
    &:not(:active) {
      border-color: ${primary300};
      box-shadow: 0 0 0 2px ${primary700};
    }
  }
`;

export const SecondaryButton = styled(BaseButton).attrs({ type: 'button' })`
  background-color: white;
  box-shadow: ${subtleShadow};
  color: ${gray100};

  svg {
    opacity: 0.75;
  }

  &:hover {
    color: ${gray400};
  }

  &:active {
    background-color: ${gray900};
  }

  &:focus {
    &:not(:active) {
      border-color: ${primary700};
      box-shadow: 0 0 0 2px ${primary800};
    }
  }
`;

export const TertiaryButton = styled(BaseButton).attrs({ type: 'button' })`
  background-color: transparent;
  color: ${primary200};
  padding-left: 0;
  padding-right: 0;

  &:hover {
    background-color: transparent;
    color: ${primary500};
  }

  &:active {
    background-color: transparent;
    color: ${primary300};
  }

  &:focus {
    &:not(:active) {
      background-color: transparent;
      color: ${primary300};
    }
  }

  svg {
    opacity: 0.85;
    margin-right: ${props => (props.icon ? 5 : 0)}px;
  }
`;

export const TertiaryButtonWhite = styled(TertiaryButton)`
  color: ${gray800};

  &:hover {
    color: ${gray950};
  }

  &:active {
    color: ${gray900};
  }

  &:focus {
    &:not(:active) {
      color: ${gray900};
    }
  }
`;

export const DangerButton = styled(BaseButton).attrs({ type: 'button' })`
  background-color: ${red300};
  color: white;

  &:hover {
    background-color: ${red400};
  }

  &:active {
    background-color: ${red200};
  }

  &:focus {
    &:not(:active) {
      border-color: ${red200};
      box-shadow: 0 0 0 2px ${red600};
    }
  }
`;

export const DeprecatedSubmitButton = styled(PrimaryButton).attrs({
  type: 'submit',
})``;

export const ButtonRow = styled.div`
  ${BaseButton} {
    &:not(:first-of-type) {
      margin-left: ${size050};
    }
  }

  ${TertiaryButton} {
    &:not(:first-of-type) {
      margin-left: ${size275};
    }
  }
`;

export const ButtonRowAlignRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${BaseButton} {
    &:not(:last-child) {
      margin-right: ${size050};
    }
  }

  ${TertiaryButton} {
    &:not(:last-child) {
      margin-right: ${size275};
    }
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  outline: none;
  color: ${gray600};
  transition: all 0.25s ease;
  cursor: pointer;

  &:hover:not(:disabled) {
    color: ${primary500};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const ButtonBadge = styled.div`
  height: ${size200};
  border-radius: ${size100};
  background-color: ${pink300};
  padding: ${size000} ${size050};
  color: white;
  font-size: ${fontSize000};
  font-weight: ${fontWeightBold};
  margin-left: ${size050};
  margin-right: -${size100};
  transform: scale(0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { ApiErrorResult } from './ApiErrorResult';
import { useApiRequest } from './useApiRequest';

type MakeRequestArguments<TResponse> = {
  query?: { [key: string]: string };
  onSuccess?: (response: TResponse) => void;
  onError?: (error: ApiErrorResult) => void;
};

export const useGet = <TResponse>(endpoint: string) => {
  const apiRequest = useApiRequest<undefined, TResponse>(endpoint, 'get');

  const makeRequest = (args?: MakeRequestArguments<TResponse>) => {
    apiRequest.makeRequest(
      args ?? {
        query: undefined,
        body: undefined,
        onSuccess: undefined,
        onError: undefined,
      }
    );
  };

  const { updateResponse, response, inProgress, error } = apiRequest;

  return { updateResponse, makeRequest, response, inProgress, error };
};

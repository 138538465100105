import React, { useContext } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import produce from 'immer';
import { ButtonRowAlignRight } from '../../shared/Buttons';
import { FormField } from '../../form/FormField';
import { Label } from '../../form/Label';
import { InputField } from '../../form/InputField';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import { TextAreaField } from '../../form/TextAreaField';
import { ProjectContext } from './ProjectContext';
import { Validator } from 'fluentvalidation-ts';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { UpdateProjectCommand } from './UpdateProjectCommand';
import { FormSubmitButton } from '../../form/FormSubmitButton';
import { FormCancelButton } from '../../form/FormCancelButton';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type Props = {
  close: () => void;
};

export const EditProject = (props: Props) => {
  const { getProject, setProject } = useContext(ProjectContext);
  const project = getProject();

  const updateProjectRequest = usePostWithoutResponse<UpdateProjectCommand>(
    'UpdateProject'
  );

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    updateProjectRequest.makeRequest({
      body: {
        projectId: project.projectId,
        title: formModel.title,
        description: formModel.description,
      },
      onSuccess: () => {
        setProject(
          produce(project, draft => {
            draft.title = formModel.title;
            draft.description = formModel.description;
          })
        );
        props.close();
      },
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  return (
    <Formik<FormModel>
      initialValues={{ title: project.title, description: project.description }}
      onSubmit={onSubmit}
      validate={validator.validate}
    >
      {() => (
        <Form>
          <ModalContent>
            <ModalHeader>Edit Project</ModalHeader>
            <FormField>
              <Label>Title</Label>
              <InputField name="title" />
            </FormField>
            <FormField>
              <Label>Description</Label>
              <TextAreaField name="description" />
            </FormField>
            <ApiErrorBox error={updateProjectRequest.error} />
          </ModalContent>
          <ModalFooter>
            <ButtonRowAlignRight>
              <FormCancelButton onClick={props.close}>Cancel</FormCancelButton>
              <FormSubmitButton>Save</FormSubmitButton>
            </ButtonRowAlignRight>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

type FormModel = {
  title: string;
  description: string;
};

class FormValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleFor('title').notEmpty();

    this.ruleFor('description').notEmpty();
  }
}

const validator = new FormValidator();

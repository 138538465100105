import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { DataContextProvider } from './model/DataContext';
import { UserContextProvider } from './app/UserContext';
import { GlobalStyles } from './GlobalStyles';
import { modalPortalRootElementId } from './shared/Modal';
import { AuthenticationContextProvider } from './authentication/AuthenticationContext';
import { AnonymousRoutes } from './routes/AnonymousRoutes';
import { UserAccountRoutes } from './routes/UserAccountRoutes';
import { OrganisationAccountRoutes } from './routes/OrganisationAccountRoutes';

export const App = () => {
  return (
    <>
      <GlobalStyles />
      <DataContextProvider>
        <UserContextProvider>
          <AuthenticationContextProvider>
            <Router>
              <AnonymousRoutes />
              <UserAccountRoutes />
              <OrganisationAccountRoutes />
            </Router>
          </AuthenticationContextProvider>
        </UserContextProvider>
      </DataContextProvider>
      <div id={modalPortalRootElementId} />
    </>
  );
};

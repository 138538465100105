import React, { useState } from 'react';
import {
  ProjectCycleStatus,
  projectCycleStatusDisplayNames,
} from '../../model/ProjectCycle';
import { Card, CardHeader, CardTitle } from '../../shared/Card';
import { SuccessBadge, NeutralBadge } from '../../shared/Badge';
import { SecondaryButton, ButtonRowAlignRight } from '../../shared/Buttons';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  size200,
  size100,
  size300,
  size050,
  size000,
} from '../../styling/sizes';
import {
  TickCircleIcon,
  DotCircleIcon,
  CircleIcon,
  FastForwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '../../icons/Icons';
import {
  gray700,
  gray500,
  primary700,
  primary400,
} from '../../styling/colours';
import { fontWeightBold } from '../../styling/fontWeights';
import {
  letterSpacingWide,
  letterSpacingWider,
} from '../../styling/letterSpacing';
import { fontSize300 } from '../../styling/fontSizes';
import {
  GetProjectResponse,
  ProjectCycleResponse,
} from '../../features/projects/GetProjectResponse';
import { PlanSection } from './planning/PlanSection';
import { DoSection } from './doing/DoSection';
import { StudySection } from './studying/StudySection';
import { ActSection } from './acting/ActSection';

type Props = {
  project: GetProjectResponse;
  cycle: ProjectCycleResponse;
  refreshProject: () => void;
};

export const ViewCycle = (props: Props) => {
  const { project, cycle } = props;
  const { cycleNumber } = cycle;

  const [redirectCycleNumber, setCycleNumber] = useState<number | null>(null);

  const totalNumberOfCycles = project.cycles.length;

  const isAtOrBeyondStatus = (status: ProjectCycleStatus) => {
    const statuses = Object.keys(projectCycleStatusDisplayNames);
    return statuses.indexOf(cycle.status) >= statuses.indexOf(status);
  };

  const isBeforeStatus = (status: ProjectCycleStatus) => {
    const statuses = Object.keys(projectCycleStatusDisplayNames);
    return statuses.indexOf(cycle.status) < statuses.indexOf(status);
  };

  if (redirectCycleNumber != null) {
    return (
      <Redirect
        to={`/app/projects/${project.projectId}/cycles/${redirectCycleNumber}`}
        push={false}
      />
    );
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>
            Cycle {cycle.cycleNumber}
            <CyclesOutOf>/{totalNumberOfCycles}</CyclesOutOf>
          </CardTitle>
          {cycle.status === 'Complete' ? (
            <SuccessBadge>Complete</SuccessBadge>
          ) : (
            <NeutralBadge>In progress</NeutralBadge>
          )}
          <CycleNavigation>
            <SecondaryButton
              disabled={cycleNumber === 1}
              onClick={() => setCycleNumber(cycleNumber - 1)}
              icon="true"
            >
              <ChevronLeftIcon />
              Previous cycle
            </SecondaryButton>
            <SecondaryButton
              disabled={cycleNumber === totalNumberOfCycles}
              onClick={() => setCycleNumber(cycleNumber + 1)}
              icon="true"
            >
              <ChevronRightIcon />
              Next cycle
            </SecondaryButton>
            {!project.isComplete && (
              <SecondaryButton
                disabled={cycleNumber === totalNumberOfCycles}
                onClick={() => setCycleNumber(totalNumberOfCycles)}
                icon="true"
              >
                <FastForwardIcon />
                Current cycle
              </SecondaryButton>
            )}
          </CycleNavigation>
        </CardHeader>
        <ProgressTimeline>
          <ProgressSpacer />
          {isAtOrBeyondStatus('Doing') ? (
            <CompletedStepIcon />
          ) : (
            <CurrentStepIcon />
          )}
          <ProgressBar complete={isAtOrBeyondStatus('Doing')} />
          {isAtOrBeyondStatus('Studying') ? (
            <CompletedStepIcon />
          ) : isBeforeStatus('Doing') ? (
            <FutureStepIcon />
          ) : (
            <CurrentStepIcon />
          )}
          <ProgressBar complete={isAtOrBeyondStatus('Studying')} />
          {isAtOrBeyondStatus('Acting') ? (
            <CompletedStepIcon />
          ) : isBeforeStatus('Studying') ? (
            <FutureStepIcon />
          ) : (
            <CurrentStepIcon />
          )}
          <ProgressBar complete={isAtOrBeyondStatus('Acting')} />
          {isAtOrBeyondStatus('Complete') ? (
            <CompletedStepIcon />
          ) : isBeforeStatus('Acting') ? (
            <FutureStepIcon />
          ) : (
            <CurrentStepIcon />
          )}
          <ProgressSpacer />
        </ProgressTimeline>
        <ProgressEvents>
          <ProgressSpacer />
          <ProgressText
            complete={isAtOrBeyondStatus('Doing')}
            active={!isAtOrBeyondStatus('Doing')}
          >
            Plan
          </ProgressText>
          <ProgressSpacer />
          <ProgressText
            complete={isAtOrBeyondStatus('Studying')}
            active={isAtOrBeyondStatus('Doing') && isBeforeStatus('Studying')}
          >
            Do
          </ProgressText>
          <ProgressSpacer />
          <ProgressText
            complete={isAtOrBeyondStatus('Acting')}
            active={isAtOrBeyondStatus('Studying') && isBeforeStatus('Acting')}
          >
            Study
          </ProgressText>
          <ProgressSpacer />
          <ProgressText
            complete={isAtOrBeyondStatus('Complete')}
            active={isAtOrBeyondStatus('Acting') && isBeforeStatus('Complete')}
          >
            Act
          </ProgressText>
          <ProgressSpacer />
        </ProgressEvents>
      </Card>
      <ActSection />
      <StudySection />
      <DoSection />
      <PlanSection />
    </>
  );
};

const ProgressTimeline = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${size200};
  padding-bottom: ${size100};

  svg {
    height: ${size300};
    width: ${size300};
    z-index: 20;
    margin: 0 -5px;
  }
`;

const CompletedStepIcon = styled(TickCircleIcon)`
  color: ${primary700};
`;

const CurrentStepIcon = styled(DotCircleIcon)`
  color: ${primary400};
`;

const FutureStepIcon = styled(CircleIcon)`
  color: ${gray700};
`;

const ProgressBar = styled.div<{ complete: boolean }>`
  z-index: 10;
  flex: 1;
  height: ${size050};
  background-color: ${props => (props.complete ? primary700 : gray700)};
`;

const ProgressEvents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${size200};
  padding-bottom: ${size200};
`;

const ProgressSpacer = styled.div`
  flex: 1;
`;

const ProgressText = styled.div<{
  complete?: boolean;
  active?: boolean;
}>`
  text-align: center;
  width: ${size300};
  font-weight: ${fontWeightBold};
  letter-spacing: ${letterSpacingWide};
  margin: 0 -5px;

  color: ${props =>
    (props.complete && primary700) || (props.active && primary400) || gray700};
`;

const CycleNavigation = styled(ButtonRowAlignRight)`
  flex: 1;
`;

const CyclesOutOf = styled.span`
  color: ${gray500};
  letter-spacing: ${letterSpacingWider};
  margin-left: ${size000};
  font-size: ${fontSize300};
`;

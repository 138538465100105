import React from 'react';
import styled from 'styled-components/macro';
import { gray100, gray800, primary600, primary900 } from '../styling/colours';
import { fontWeightBold } from '../styling/fontWeights';
import { letterSpacingSlightlyWide } from '../styling/letterSpacing';
import { transitionMedium } from '../styling/transitions';
import { borderRadiusSmall } from '../styling/borders';
import { shadow000 } from '../styling/shadows';
import { size000, size050 } from '../styling/sizes';
import { fontSize000 } from '../styling/fontSizes';
import { useHistory } from 'react-router-dom';

const defaultAvatarUrl = require('../images/default-avatar.png');

type UserDetails = {
  userAccountId: number;
  fullName: string;
};

type Props<TUserDetails extends UserDetails> = {
  users: Array<TUserDetails>;
};

export const UserCircles = <TUserDetails extends UserDetails>({
  users,
}: Props<TUserDetails>) => {
  const history = useHistory();

  return (
    <UserCirclesContainer>
      {users.map(user => (
        <UserCircle
          key={user.userAccountId}
          caption={user.fullName}
          src={defaultAvatarUrl}
          onClick={e => {
            history.push(`/app/users/${user.userAccountId}`);

            if (!e.isDefaultPrevented()) {
              e.preventDefault();
            }

            if (!e.isPropagationStopped()) {
              e.stopPropagation();
            }
          }}
        />
      ))}
    </UserCirclesContainer>
  );
};

const UserCirclesContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const UserCircle = styled.div<{ src?: string; caption?: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 45px;
  width: 45px;
  border: solid 3px white;
  background-color: ${props =>
    props.src == null ? primary900 : 'transparent'};
  color: ${primary600};
  font-weight: ${fontWeightBold};
  letter-spacing: ${letterSpacingSlightlyWide};
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 100%;
  margin-right: -14px;
  transition: all ${transitionMedium} ease;
  box-shadow: inset -0 0 50px 0 rgba(255, 255, 255, 0.2);

  &:hover {
    z-index: 10;
    transform: translateY(-2px);
    box-shadow: inset -0 0 10px 0 transparent;

    ::after {
      opacity: 1;
      display: ${props => (props.caption == null ? 'none' : 'block')};
    }
  }

  ::after {
    opacity: 0;
    content: '${props => props.caption}';
    display: none;
    position: absolute;
    bottom: -35px;
    transform: translateX(-50%);
    left: 50%;
    white-space: nowrap;
    background-color: ${gray100};
    color: ${gray800};
    border-radius: ${borderRadiusSmall};
    box-shadow: ${shadow000};
    padding: ${size000} ${size050};
    font-size: ${fontSize000};
  }
`;

import styled from 'styled-components/macro';
import {
  desktopContainerWidth,
  size050,
  size150,
  size200,
  size250,
} from '../styling/sizes';
import { gray000, gray050, gray700 } from '../styling/colours';
import { borderRadiusMedium } from '../styling/borders';
import { shadow000 } from '../styling/shadows';
import { fontSize500 } from '../styling/fontSizes';
import { fontWeightMedium } from '../styling/fontWeights';
import { ButtonRowAlignRight } from '../shared/Buttons';

export const OverlappingHeaderTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${size250};
  background-color: ${gray000};
  border-top: solid 1px ${gray050};
`;

export const OverlappingHeaderBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OverlappingHeaderTitleBar = styled.div`
  width: ${desktopContainerWidth};
  max-width: ${desktopContainerWidth};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${size200};
`;

export const OverlappingHeaderTitleText = styled.h1`
  margin: 0;
  color: ${gray700};
  font-size: ${fontSize500};
  font-weight: ${fontWeightMedium};
  display: flex;
  align-items: center;
`;

export const OverlappingHeaderTitleActions = styled(ButtonRowAlignRight)``;

const OverlappingHeaderPanelBase = styled.div`
  width: ${desktopContainerWidth};
  max-width: ${desktopContainerWidth};
  background-color: white;
  padding-left: ${size200};
  padding-right: ${size200};
`;

export const OverlappingHeaderPanelTop = styled(OverlappingHeaderPanelBase)`
  border-top-left-radius: ${borderRadiusMedium};
  border-top-right-radius: ${borderRadiusMedium};
  padding-top: ${size150};
  padding-bottom: ${size050};
  z-index: 10;
`;

export const OverlappingHeaderPanelBottom = styled(OverlappingHeaderPanelBase)`
  border-bottom-left-radius: ${borderRadiusMedium};
  border-bottom-right-radius: ${borderRadiusMedium};
  padding-top: ${size050};
  padding-bottom: ${size150};
  box-shadow: ${shadow000};
  z-index: 5;
`;

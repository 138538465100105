import styled from 'styled-components/macro';
import { size100, size275 } from '../styling/sizes';
import { borderRadiusSmall } from '../styling/borders';
import {
  gray700,
  gray800,
  yellow900,
  yellow500,
  gray000,
  yellow300,
  yellow600,
  gray950,
} from '../styling/colours';
import { fontSize200 } from '../styling/fontSizes';
import { ValidityState } from '../form/ValidityState';

export const Input = styled.input<{ validityState?: ValidityState }>`
  height: ${size275};
  padding: 0 ${size100};
  width: 100%;
  border-radius: ${borderRadiusSmall};
  outline: none;
  border-style: none;
  transition: all 0.25s ease;

  color: ${props => (props.validityState === 'invalid' ? yellow300 : gray000)};

  background-color: ${props =>
    props.validityState === 'invalid' ? yellow900 : gray950};

  box-shadow: 0 0 0 1px
    ${props => (props.validityState === 'invalid' ? yellow500 : gray800)};

  font-size: ${fontSize200};

  &:focus {
    color: ${gray000};
    background-color: white;
    box-shadow: 0 0 0 2px
      ${props => (props.validityState === 'invalid' ? yellow600 : gray700)};
  }

  &:disabled {
    opacity: 0.8;
  }
`;

import React from 'react';
import styled from 'styled-components/macro';
import { useField, useFormikContext } from 'formik';
import { Select } from '../shared/Select';
import { CaretDownIcon } from '../icons/Icons';
import { gray500 } from '../styling/colours';
import { FieldError } from './FieldError';

type SelectOptionValue = string | number;

export type SelectOption<TValue extends SelectOptionValue> = {
  text: string;
  value: TValue;
};

export type SelectOptions<TValue extends SelectOptionValue> = Array<
  SelectOption<TValue>
>;

type Props<TValue extends SelectOptionValue> = {
  name: string;
  options: SelectOptions<TValue>;
  hideError?: boolean;
};

export const SelectField = <TValue extends SelectOptionValue>(
  props: Props<TValue>
) => {
  const formikContext = useFormikContext();
  const [field, meta] = useField(props);

  const isInvalid = meta.touched && meta.error != null;

  return (
    <>
      <SelectContainer>
        <Caret />
        <Select
          {...props}
          {...field}
          id={field.name}
          validityState={isInvalid ? 'invalid' : 'untouched'}
          disabled={formikContext.isSubmitting}
        >
          {props.options.map((option: SelectOption<TValue>) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </SelectContainer>
      {props.hideError === true ? null : <FieldError meta={meta} />}
    </>
  );
};

export const withBlankOption = <TValue extends SelectOptionValue>(
  options: SelectOptions<TValue>,
  blankValue: TValue
): SelectOptions<TValue> => [{ text: '-', value: blankValue }, ...options];

const SelectContainer = styled.div`
  position: relative;
`;

const Caret = styled(CaretDownIcon)`
  position: absolute;
  right: 15px;
  top: 7px;
  pointer-events: none;
  cursor: pointer;
  fill: ${gray500};
`;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { Form } from '../../form/Form';
import { FormField } from '../../form/FormField';
import { Label } from '../../form/Label';
import { InputField } from '../../form/InputField';
import { ButtonRowAlignRight, SecondaryButton } from '../../shared/Buttons';
import { ModalHeader, ModalContent, ModalFooter } from '../../shared/Modal';
import { TextAreaField } from '../../form/TextAreaField';
import { usePostWithResponse } from '../../api/usePostWithResponse';
import { Validator } from 'fluentvalidation-ts';
import { FormSubmitButton } from '../../form/FormSubmitButton';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { useGet } from '../../api/useGet';
import { TeamsResponse } from '../../features/teams/TeamsResponse';
import { CenteredSpinner } from '../../shared/Spinner';
import {
  SelectField,
  SelectOptions,
  withBlankOption,
} from '../../form/SelectField';

type CreateProjectCommand = {
  title: string;
  description: string;
};

type CreateProjectResponse = {
  projectId: number;
};

type Props = {
  close: () => void;
};

export const CreateProject = (props: Props) => {
  const getTeamsForCurrentUserAccountRequest = useGet<TeamsResponse>(
    'GetTeamsForCurrentUserAccount'
  );

  useEffect(() => {
    getTeamsForCurrentUserAccountRequest.makeRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createProjectRequest = usePostWithResponse<
    CreateProjectCommand,
    CreateProjectResponse
  >('CreateProject');

  const history = useHistory();

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    const createProjectCommand: CreateProjectCommand = { ...formModel };

    createProjectRequest.makeRequest({
      body: createProjectCommand,
      onSuccess: response =>
        history.push(`/app/projects/${response.projectId}`),
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  if (getTeamsForCurrentUserAccountRequest.error != null) {
    return <ApiErrorBox error={getTeamsForCurrentUserAccountRequest.error} />;
  }

  const teamsResponse = getTeamsForCurrentUserAccountRequest.response;

  if (
    getTeamsForCurrentUserAccountRequest.inProgress ||
    teamsResponse == null
  ) {
    return <CenteredSpinner />;
  }

  const teamOptions: SelectOptions<number | ''> = teamsResponse.teams
    .filter(t => t.isMember && !t.isArchived)
    .map(t => ({
      text: t.name,
      value: t.teamId,
    }));

  if (teamOptions.length === 0) {
    return (
      <ModalContent>
        <ModalHeader>Create Project</ModalHeader>
        <p>
          In order to create a Project you first need to join a Team. Please
          join some Teams and try again.
        </p>
      </ModalContent>
    );
  }

  return (
    <Formik<FormModel>
      onSubmit={onSubmit}
      initialValues={initialFormModel}
      validate={formValidator.validate}
    >
      {formikProps => (
        <Form>
          <ModalContent>
            <ModalHeader>Create Project</ModalHeader>
            <p>
              To get started, all you need to do is choose which Team your
              Project will sit in, and give it a title and a brief description.
            </p>
            <p>
              A good title will tell the person reading it what you are
              investigating, the patients that will be involved, and where you
              are collecting the information from.
            </p>
            <p />
            <FormField>
              <Label>Team</Label>
              <SelectField
                name="teamId"
                options={withBlankOption(teamOptions, '')}
              />
            </FormField>
            <FormField>
              <Label>Title</Label>
              <InputField
                name="title"
                placeholder={`e.g. "Antibiotic prescriptions in patients with urinary tract infections on Ward 21"`}
              />
            </FormField>
            <FormField>
              <Label>Description</Label>
              <TextAreaField name="description" />
            </FormField>
            <ApiErrorBox error={createProjectRequest.error} withMargin={true} />
          </ModalContent>
          <ModalFooter>
            <ButtonRowAlignRight>
              <SecondaryButton
                onClick={props.close}
                disabled={formikProps.isSubmitting}
              >
                Cancel
              </SecondaryButton>
              <FormSubmitButton>Save</FormSubmitButton>
            </ButtonRowAlignRight>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

type FormModel = {
  teamId: number | '';
  title: string;
  description: string;
};

const initialFormModel: FormModel = {
  teamId: '',
  title: '',
  description: '',
};

class FormValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleFor('teamId')
      .must(teamId => teamId !== '')
      .withMessage('This field is required');

    this.ruleFor('title')
      .notEmpty()
      .withMessage('This field is required')
      .maxLength(256);

    this.ruleFor('description')
      .notEmpty()
      .withMessage('This field is required')
      .maxLength(5000);
  }
}

const formValidator = new FormValidator();

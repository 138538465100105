import React from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import { Validator } from 'fluentvalidation-ts';
import { Formik, FormikHelpers } from 'formik';
import { Form } from '../../form/Form';
import { FormSubmitButton } from '../../form/FormSubmitButton';
import { ButtonRowAlignRight, SecondaryButton } from '../../shared/Buttons';
import { InputField } from '../../form/InputField';
import { FormField } from '../../form/FormField';
import { Label } from '../../form/Label';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type UpdateTeamCommand = {
  teamId: number;
  name: string;
};

type Props = {
  teamId: number;
  teamName: string;
  close: (andRefresh: boolean) => void;
};

export const EditTeam = (props: Props) => {
  const updateTeamRequest = usePostWithoutResponse<UpdateTeamCommand>(
    'UpdateTeam'
  );

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    updateTeamRequest.makeRequest({
      body: {
        teamId: props.teamId,
        name: formModel.name,
      },
      onSuccess: () => props.close(true),
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  return (
    <Formik<FormModel>
      onSubmit={onSubmit}
      validate={formValidator.validate}
      initialValues={getInitialFormModel(props.teamName)}
    >
      <Form>
        <ModalContent>
          <ModalHeader>Edit team</ModalHeader>
          <FormField>
            <Label>Name</Label>
            <InputField name="name" />
          </FormField>
          <ApiErrorBox error={updateTeamRequest.error} withMargin={true} />
        </ModalContent>
        <ModalFooter>
          <ButtonRowAlignRight>
            <SecondaryButton
              onClick={() => props.close(false)}
              disabled={updateTeamRequest.inProgress}
            >
              Cancel
            </SecondaryButton>
            <FormSubmitButton>Save</FormSubmitButton>
          </ButtonRowAlignRight>
        </ModalFooter>
      </Form>
    </Formik>
  );
};

type FormModel = {
  name: string;
};

const getInitialFormModel = (teamName: string): FormModel => ({
  name: teamName,
});

class FormValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleFor('name')
      .notEmpty()
      .withMessage('Please enter a new name for the team');
  }
}

const formValidator = new FormValidator();

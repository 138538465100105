import React, { useCallback, useEffect, useState } from 'react';
import {
  GetProjectResponse,
  ProjectCycleResponse,
} from '../../features/projects/GetProjectResponse';
import { usePostWithResponse } from '../../api/usePostWithResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { CenteredSpinner } from '../../shared/Spinner';
import { GetProjectQuery } from './GetProjectQuery';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';
import { assertNotNull } from '../../helpers/nullHelpers';

type ProjectContextValue = {
  getProject: () => GetProjectResponse;
  getCycle: () => ProjectCycleResponse;
  hardReloadProject: () => void;
  silentReloadProject: () => void;
  setProject: (project: GetProjectResponse) => void;
};

const throwNotInitialisedError = () => {
  throw new Error(`ProjectContext is not yet initialised`);
};

export const ProjectContext = React.createContext<ProjectContextValue>({
  getProject: throwNotInitialisedError,
  getCycle: throwNotInitialisedError,
  hardReloadProject: throwNotInitialisedError,
  silentReloadProject: throwNotInitialisedError,
  setProject: throwNotInitialisedError,
});

type Props = {
  children: React.ReactNode;
};

export const ProjectContextProvider = ({ children }: Props) => {
  const { id, cycleNumberParam } = useParams();

  const [cachedProject, setCachedProject] = useState<GetProjectResponse | null>(
    null
  );

  const getProjectRequest = usePostWithResponse<
    GetProjectQuery,
    GetProjectResponse
  >('GetProject');

  const fetchProject = useCallback(
    () =>
      getProjectRequest.makeRequest({
        body: { projectId: Number(id) },
        onSuccess: projectResponse => setCachedProject(projectResponse),
      }),
    [id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    fetchProject();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (getProjectRequest.error != null) {
    return <ApiErrorBox error={getProjectRequest.error} />;
  }

  if (cachedProject == null) {
    return <CenteredSpinner />;
  }

  const hardReloadProject = () => {
    setCachedProject(null);
    fetchProject();
  };

  const cycleNumber = Number(cycleNumberParam);

  const getCycle = () =>
    assertNotNull(
      find(cachedProject.cycles, cycle => cycle.cycleNumber === cycleNumber)
    );

  const value: ProjectContextValue = {
    getProject: () => cachedProject,
    getCycle,
    hardReloadProject,
    silentReloadProject: fetchProject,
    setProject: setCachedProject,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};

import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import qs from 'query-string';
import { Validator } from 'fluentvalidation-ts';
import { FormField } from '../../form/FormField';
import { Label } from '../../form/Label';
import { Input } from '../../shared/Input';
import { size275, size300, size250, size150 } from '../../styling/sizes';
import { primary200, gray000 } from '../../styling/colours';
import { Form } from '../../form/Form';
import { Formik, FormikHelpers } from 'formik';
import { shadow200 } from '../../styling/shadows';
import { borderRadiusLarge } from '../../styling/borders';
import { fontSize200, fontSize600 } from '../../styling/fontSizes';
import { lineHeightMedium } from '../../styling/lineHeights';
import { fontWeightBold } from '../../styling/fontWeights';
import { InputField } from '../../form/InputField';
import { useLocation, useHistory } from 'react-router-dom';
import { usePostWithResponse } from '../../api/usePostWithResponse';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { FormSubmitButton } from '../../form/FormSubmitButton';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { AuthenticationContext } from '../../authentication/AuthenticationContext';

type GetDetailsFromOrganisationAccountInviteQuery = {
  inviteCode: string;
};

type GetDetailsFromOrganisationAccountInviteResponse = {
  organisationAccountId: number;
  organisationName: string;
  emailAddress: string;
};

type ActivateOrganisationAccountCommand = {
  organisationAccountId: number;
  inviteCode: string;
  password: string;
};

export const CompleteOrganisationAccountRegistration = () => {
  const history = useHistory();
  const authenticationContext = useContext(AuthenticationContext);

  const queryParameters = qs.parse(useLocation().search);
  const code = queryParameters['code'] as string;

  const getDetailsFromInviteRequest = usePostWithResponse<
    GetDetailsFromOrganisationAccountInviteQuery,
    GetDetailsFromOrganisationAccountInviteResponse
  >('GetDetailsFromOrganisationAccountInvite');

  useEffect(() => {
    getDetailsFromInviteRequest.makeRequest({
      body: { inviteCode: code },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const activateOrganisationAccountRequest = usePostWithoutResponse<
    ActivateOrganisationAccountCommand
  >('ActivateOrganisationAccount');

  if (getDetailsFromInviteRequest.inProgress) {
    return (
      <Page>
        <Panel style={{ textAlign: 'center' }}>Please wait...</Panel>
      </Page>
    );
  }

  if (getDetailsFromInviteRequest.error != null) {
    return (
      <Page>
        <Panel>
          <ApiErrorBox error={getDetailsFromInviteRequest.error} />
        </Panel>
      </Page>
    );
  }

  const inviteDetails = getDetailsFromInviteRequest.response;

  if (inviteDetails == null) {
    return null;
  }

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    activateOrganisationAccountRequest.makeRequest({
      body: {
        organisationAccountId: inviteDetails.organisationAccountId,
        inviteCode: code,
        password: formModel.password,
      },
      onSuccess: () => {
        history.replace('/');

        authenticationContext.setState({
          isAuthenticated: true,
          accountType: 'organisation',
        });
      },
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  return (
    <Page>
      <Panel>
        <WelcomeText>Welcome to QI Compass!</WelcomeText>
        <SupportText>
          You are creating an account to manage the{' '}
          <OrganisationName>{inviteDetails.organisationName}</OrganisationName>{' '}
          organisation.
        </SupportText>
        <SupportText>
          To complete the registration process, please choose a password.
        </SupportText>
        <SupportText>
          You will need your email address and password to log in to your QI
          Compass account.
        </SupportText>
        <br />
        <Formik<FormModel>
          initialValues={initialFormModel}
          onSubmit={onSubmit}
          validate={formValidator.validate}
        >
          <Form>
            <FormField>
              <Label>Email</Label>
              <Input
                type="email"
                disabled={true}
                value={inviteDetails.emailAddress}
              />
            </FormField>
            <FormField>
              <Label>Password</Label>
              <InputField name="password" type="password" />
            </FormField>
            <FormField>
              <Label>Re-type password</Label>
              <InputField name="retypePassword" type="password" />
            </FormField>
            <FullWidthSubmitButton>Create account</FullWidthSubmitButton>
            <ApiErrorBox
              error={activateOrganisationAccountRequest.error}
              withMargin={true}
            />
          </Form>
        </Formik>
      </Panel>
    </Page>
  );
};

type FormModel = {
  password: string;
  retypePassword: string;
};

const initialFormModel: FormModel = {
  password: '',
  retypePassword: '',
};

class FormValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleFor('password')
      .notEmpty()
      .withMessage('Please enter a password')
      .minLength(6)
      .withMessage('Please enter at least 6 characters');

    this.ruleFor('retypePassword')
      .notEmpty()
      .withMessage('Please re-type your password')
      .must(
        (retypePassword, formModel) => retypePassword === formModel.password
      )
      .withMessage('The passwords you have entered do not match');
  }
}

const formValidator = new FormValidator();

const Page = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: ${gray000};
`;

const Panel = styled.div`
  padding: ${size275} ${size300};
  margin: 0 ${size150};
  width: 550px;
  max-width: 550px;
  background-color: white;
  box-shadow: ${shadow200};
  border-radius: ${borderRadiusLarge};
`;

const WelcomeText = styled.div`
  font-size: ${fontSize600};
  font-weight: ${fontWeightBold};
  margin-bottom: ${size250};
`;

const SupportText = styled.div`
  font-size: ${fontSize200};
  margin-bottom: ${size150};
  line-height: ${lineHeightMedium};
`;

const OrganisationName = styled.span`
  color: ${primary200};
  font-weight: ${fontWeightBold};
`;

const FullWidthSubmitButton = styled(FormSubmitButton)`
  width: 100%;
`;

import React, { useContext, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../../shared/Card';
import { NeutralBadge, SuccessBadge } from '../../../shared/Badge';
import {
  PrimaryButton,
  ButtonRowAlignRight,
  SecondaryButton,
} from '../../../shared/Buttons';
import styled from 'styled-components/macro';
import { gray800, gray300 } from '../../../styling/colours';
import { size050 } from '../../../styling/sizes';
import { fontSize300 } from '../../../styling/fontSizes';
import { fontWeightBold } from '../../../styling/fontWeights';
import {
  TickIcon,
  PencilIcon,
  AttachIcon,
  CrossIcon,
} from '../../../icons/Icons';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from '../../../shared/Modal';
import { CreateOrEditStudyData } from './CreateOrEditStudyData';
import { lineHeightMedium } from '../../../styling/lineHeights';
import { ProjectContext } from '../ProjectContext';
import { UserAccountContext } from '../../../features/userAccounts/UserAccountContext';
import {
  ProjectCycleStatus,
  projectCycleStatusDisplayNames,
} from '../../../model/ProjectCycle';
import { usePostWithResponse } from '../../../api/usePostWithResponse';
import { GetSharedAccessSignatureForStudyAttachmentDownloadCommand } from './GetSharedAccessSignatureForStudyAttachmentDownloadCommand';
import { GetSharedAccessSignatureForStudyAttachmentDownloadResponse } from './GetSharedAccessSignatureForStudyAttachmentDownloadResponse';
import { UploadStudyAttachmentModal } from './UploadStudyAttachmentModal';
import { DeleteStudyAttachmentModal } from './DeleteStudyAttachmentModal';
import { usePostWithoutResponse } from '../../../api/usePostWithoutResponse';
import { CompleteStudyingPhaseCommand } from './CompleteStudyingPhaseCommand';
import {
  Attachment,
  AttachmentButton,
  AttachmentFileName,
  AttachmentsRow,
} from '../../../shared/Attachment';

export const StudySection = () => {
  const { getProject, getCycle, hardReloadProject } = useContext(
    ProjectContext
  );

  const project = getProject();
  const cycle = getCycle();

  const userAccountContext = useContext(UserAccountContext);
  const currentUserAccountId = userAccountContext.getUserAccountId();

  const currentUserIsProjectLead =
    currentUserAccountId === project.leadUserAccount.userAccountId;

  const [modal, setModal] = useState<string | null>(null);

  const [attachmentIdForDeletion, setAttachmentIdForDeletion] = useState<
    number | null
  >(null);

  const getSasForDownloadRequest = usePostWithResponse<
    GetSharedAccessSignatureForStudyAttachmentDownloadCommand,
    GetSharedAccessSignatureForStudyAttachmentDownloadResponse
  >('GetSharedAccessSignatureForStudyAttachmentDownload');

  const completeStudyingPhaseRequest = usePostWithoutResponse<
    CompleteStudyingPhaseCommand
  >('CompleteStudyingPhase');

  const isAtOrBeyondStatus = (status: ProjectCycleStatus) => {
    const statuses = Object.keys(projectCycleStatusDisplayNames);
    return statuses.indexOf(cycle.status) >= statuses.indexOf(status);
  };

  if (!isAtOrBeyondStatus('Studying')) {
    return null;
  }

  const cycleStudy = cycle.study!;
  const cycleStudyAttachments = cycleStudy.attachments;

  const moveToActPhase = () => {
    completeStudyingPhaseRequest.makeRequest({
      body: { projectId: project.projectId },
      onSuccess: () => {
        hardReloadProject();
      },
    });
  };

  const downloadAttachment = (projectCycleStudyAttachmentId: number) => {
    if (getSasForDownloadRequest.inProgress) {
      return;
    }

    getSasForDownloadRequest.makeRequest({
      body: {
        projectCycleId: cycle.projectCycleId,
        projectCycleStudyAttachmentId,
      },
      onSuccess: response => {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = response.sharedAccessSignature;
        hiddenElement.target = '_blank';
        hiddenElement.click();
        setTimeout(() => hiddenElement.remove(), 1_000);
      },
      onError: error => {
        alert(`Failed to get SAS: ${error.userVisibleMessage}`); // TODO - tidy this up
      },
    });
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>Study</CardTitle>
          {isAtOrBeyondStatus('Acting') ? (
            <SuccessBadge>Complete</SuccessBadge>
          ) : (
            <NeutralBadge>In Progress</NeutralBadge>
          )}
          {!isAtOrBeyondStatus('Acting') && currentUserIsProjectLead && (
            <ActionButtons>
              <SecondaryButton
                icon="true"
                onClick={() => setModal('uploadAttachment')}
              >
                <AttachIcon /> Attach document
              </SecondaryButton>
              <SecondaryButton
                onClick={() => setModal('studyData')}
                icon="true"
              >
                <PencilIcon /> Edit
              </SecondaryButton>
              {cycleStudy.writeUp && (
                <PrimaryButton
                  onClick={() => setModal('completePhase')}
                  icon="true"
                >
                  <TickIcon />
                  Complete phase
                </PrimaryButton>
              )}
              <Modal
                isOpen={modal === 'completePhase'}
                close={() => setModal(null)}
                preventClose={true}
              >
                <ModalContent>
                  <ModalHeader>Complete phase</ModalHeader>
                  <div>Are you sure you wish to move to the Act phase?</div>
                  <p>
                    After this point you will no longer be able to edit your
                    write-up or modify the supporting documents.
                  </p>
                </ModalContent>
                <ModalFooter>
                  <ButtonRowAlignRight>
                    <SecondaryButton
                      onClick={() => setModal(null)}
                      disabled={completeStudyingPhaseRequest.inProgress}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      onClick={moveToActPhase}
                      disabled={completeStudyingPhaseRequest.inProgress}
                    >
                      Confirm
                    </PrimaryButton>
                  </ButtonRowAlignRight>
                </ModalFooter>
              </Modal>
            </ActionButtons>
          )}
        </CardHeader>
        <CardContent>
          <Heading>Write-Up</Heading>
          <WriteUp>
            {cycleStudy.writeUp || 'No write-up has been submitted yet'}
          </WriteUp>
          <Modal isOpen={modal === 'studyData'} close={() => setModal(null)}>
            <CreateOrEditStudyData close={() => setModal(null)} />
          </Modal>
        </CardContent>
        <AttachmentsSection>
          <Heading>Supporting documents</Heading>
          {cycleStudyAttachments.length === 0 && (
            <div>You have not uploaded any attachments yet.</div>
          )}
          <AttachmentsRow>
            {cycleStudyAttachments.map(attachment => (
              <Attachment
                key={attachment.projectCycleStudyAttachmentId}
                disable={getSasForDownloadRequest.inProgress ? 'true' : 'false'}
                onClick={() =>
                  downloadAttachment(attachment.projectCycleStudyAttachmentId)
                }
              >
                <AttachmentFileName>{attachment.fileName}</AttachmentFileName>
                {!isAtOrBeyondStatus('Acting') && currentUserIsProjectLead && (
                  <AttachmentButton
                    onClick={e => {
                      e.stopPropagation();
                      setAttachmentIdForDeletion(
                        attachment.projectCycleStudyAttachmentId
                      );
                    }}
                  >
                    <CrossIcon />
                  </AttachmentButton>
                )}
              </Attachment>
            ))}
          </AttachmentsRow>
          <UploadStudyAttachmentModal
            isOpen={modal === 'uploadAttachment'}
            close={() => setModal(null)}
          />
          {attachmentIdForDeletion != null && (
            <DeleteStudyAttachmentModal
              isOpen={true}
              close={() => setAttachmentIdForDeletion(null)}
              projectCycleStudyAttachmentId={attachmentIdForDeletion}
            />
          )}
        </AttachmentsSection>
      </Card>
    </div>
  );
};

const WriteUp = styled.div`
  line-height: ${lineHeightMedium};
  white-space: pre-wrap;
`;

const AttachmentsSection = styled(CardContent)`
  border-top: solid 1px ${gray800};
`;

const Heading = styled.div`
  color: ${gray300};
  font-weight: ${fontWeightBold};
  margin: 0 0 ${size050};
  padding-bottom: ${size050};
  font-size: ${fontSize300};
`;

const ActionButtons = styled(ButtonRowAlignRight)`
  flex: 1;
`;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { ButtonRowAlignRight, PrimaryButton } from '../../shared/Buttons';
import {
  CancelSendIcon,
  ReplayIcon,
  UserOutlineIcon,
  UserPlusIcon,
  UserTickIcon,
} from '../../icons/Icons';
import {
  StandaloneTable,
  TBody,
  Td,
  Th,
  THead,
  Tr,
  TableOverlay,
} from '../../shared/Table';
import { size250 } from '../../styling/sizes';
import {
  OverlappingHeaderBottom,
  OverlappingHeaderPanelBottom,
  OverlappingHeaderPanelTop,
  OverlappingHeaderTitleActions,
  OverlappingHeaderTitleBar,
  OverlappingHeaderTitleText,
  OverlappingHeaderTop,
} from '../../layout/OverlappingHeader';
import {
  ConstrainedContainer,
  ConstrainedContainerContent,
} from '../../layout/ConstrainedContainer';
import { Checkbox } from '../../shared/Checkbox';
import { letterSpacingSlightlyWide } from '../../styling/letterSpacing';
import { NeutralBadge, SuccessBadge, WarningBadge } from '../../shared/Badge';
import { PopoverMenuButton } from '../../shared/PopoverMenuButton';
import { PopoverMenu, PopoverMenuOption } from '../../shared/PopoverMenu';
import { usePostWithResponse } from '../../api/usePostWithResponse';
import { debounce } from 'lodash';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { Modal } from '../../shared/Modal';
import { InviteUserAccounts } from './InviteUserAccounts';
import { ResendUserAccountInvite } from './ResendUserAccountInvite';
import { CancelPendingUserAccount } from './CancelPendingUserAccount';
import {
  GetUserAccountsResponse,
  GetUserAccountsUserResponse,
} from './GetUserAccountsResponse';
import { DeactivateUserAccount } from './DeactivateUserAccount';
import { ReactivateUserAccount } from './ReactivateUserAccount';
import {
  SearchInput,
  SearchInputLabel,
  CheckboxFiltersRow,
  CheckboxFilterLabel,
} from '../../shared/SearchPanel';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { GetUserAccountsQuery } from './GetUserAccountsQuery';

export const ManageUserAccounts = () => {
  const history = useHistory();

  const { active, inviteSent, deactivated, search } = qs.parse(
    useLocation().search,
    { decode: true }
  );

  const [
    showInviteUserAccountsModal,
    setShowInviteUserAccountsModal,
  ] = useState(false);

  const [
    resendUserAccountInviteModalState,
    setResendUserAccountInviteModalState,
  ] = useState<GetUserAccountsUserResponse | null>(null);

  const [
    cancelPendingUserAccountModalState,
    setCancelPendingUserAccountModalState,
  ] = useState<GetUserAccountsUserResponse | null>(null);

  const [
    deactivateUserAccountModalState,
    setDeactivateUserAccountModalState,
  ] = useState<GetUserAccountsUserResponse | null>(null);

  const [
    reactivateUserAccountModalState,
    setReactivateUserAccountModalState,
  ] = useState<GetUserAccountsUserResponse | null>(null);

  const [searchTerm, setSearchTerm] = useState(
    search == null ? '' : (search as string)
  );
  const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);
  const [showActive, setShowActive] = useState(
    active == null ? true : active === 'true'
  );
  const [showInviteSent, setShowInviteSent] = useState(
    inviteSent == null ? true : inviteSent === 'true'
  );
  const [showDeactivated, setShowDeactivated] = useState(
    deactivated == null ? false : deactivated === 'true'
  );

  const [
    latestResponse,
    setLatestResponse,
  ] = useState<GetUserAccountsResponse | null>(null);

  const getUserAccountsRequest = usePostWithResponse<
    GetUserAccountsQuery,
    GetUserAccountsResponse
  >('GetUserAccounts');

  const makeRequest = () => {
    getUserAccountsRequest.makeRequest({
      body: {
        searchTerm:
          currentSearchTerm.trim() === '' ? null : currentSearchTerm.trim(),
        includeActive: showActive,
        includeInviteSent: showInviteSent,
        includeDeactivated: showDeactivated,
      },
      onSuccess: response => setLatestResponse(response),
    });
  };

  useEffect(() => {
    makeRequest();

    const query = {
      active: showActive,
      inviteSent: showInviteSent,
      deactivated: showDeactivated,
      search: currentSearchTerm,
    };

    history.replace({ search: qs.stringify(query, { encode: true }) });
  }, [currentSearchTerm, showActive, showInviteSent, showDeactivated]); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedSetSearchTerm = useRef(
    debounce((value: string) => {
      setCurrentSearchTerm(value);
    }, 1_000)
  );

  useEffect(() => {
    debouncedSetSearchTerm.current(searchTerm);
  }, [searchTerm]);

  const onChangeSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const closeInviteUserAccountsModal = (andRefresh: boolean) => {
    if (andRefresh) {
      makeRequest();
    }
    setShowInviteUserAccountsModal(false);
  };

  const closeResendUserAccountInviteModal = () => {
    setResendUserAccountInviteModalState(null);
  };

  const closeCancelPendingUserAccountModalState = (andRefresh: boolean) => {
    if (andRefresh) {
      makeRequest();
    }
    setCancelPendingUserAccountModalState(null);
  };

  const closeDeactivateUserAccountModalState = (andRefresh: boolean) => {
    if (andRefresh) {
      makeRequest();
    }
    setDeactivateUserAccountModalState(null);
  };

  const closeReactivateUserAccountModalState = (andRefresh: boolean) => {
    if (andRefresh) {
      makeRequest();
    }
    setReactivateUserAccountModalState(null);
  };

  return (
    <>
      <OverlappingHeaderTop>
        <OverlappingHeaderTitleBar>
          <OverlappingHeaderTitleText>Users</OverlappingHeaderTitleText>
          <OverlappingHeaderTitleActions>
            <PrimaryButton
              icon="true"
              onClick={() => setShowInviteUserAccountsModal(true)}
            >
              <UserPlusIcon /> Add users
            </PrimaryButton>
          </OverlappingHeaderTitleActions>
        </OverlappingHeaderTitleBar>
        <OverlappingHeaderPanelTop>
          <SearchInputLabel>Search users</SearchInputLabel>
          <SearchInput
            placeholder="Search by name or email address"
            value={searchTerm}
            onChange={onChangeSearchInput}
          />
        </OverlappingHeaderPanelTop>
      </OverlappingHeaderTop>
      <OverlappingHeaderBottom>
        <OverlappingHeaderPanelBottom>
          <CheckboxFiltersRow>
            <CheckboxFilterLabel>
              <Checkbox
                checked={showActive}
                onChange={event => {
                  setShowActive(event.target.checked);
                }}
              />
              Active
            </CheckboxFilterLabel>
            <CheckboxFilterLabel>
              <Checkbox
                checked={showInviteSent}
                onChange={event => {
                  setShowInviteSent(event.target.checked);
                }}
              />
              Invite sent
            </CheckboxFilterLabel>
            <CheckboxFilterLabel>
              <Checkbox
                checked={showDeactivated}
                onChange={event => {
                  setShowDeactivated(event.target.checked);
                }}
              />
              Deactivated
            </CheckboxFilterLabel>
          </CheckboxFiltersRow>
        </OverlappingHeaderPanelBottom>
      </OverlappingHeaderBottom>
      <ConstrainedContainer>
        <ConstrainedContainerContent>
          <TableOverlay show={getUserAccountsRequest.inProgress}>
            <UsersTable>
              <THead>
                <Tr>
                  <Th>Email</Th>
                  <Th>Name</Th>
                  <Th>Status</Th>
                  <Th />
                </Tr>
              </THead>
              <TBody>
                {latestResponse == null ? (
                  <Tr>
                    <Td colSpan={4}>Loading...</Td>
                  </Tr>
                ) : latestResponse.userAccounts.length === 0 ? (
                  <Tr>
                    <Td colSpan={4}>No results </Td>
                  </Tr>
                ) : (
                  latestResponse.userAccounts.map(userAccount => (
                    <Tr key={userAccount.userAccountId}>
                      <Td>
                        <EmailAddress>{userAccount.emailAddress}</EmailAddress>
                      </Td>
                      <Td>{userAccount.fullName}</Td>
                      <Td>
                        {userAccount.isActive ? (
                          <SuccessBadge>Active</SuccessBadge>
                        ) : !userAccount.isDeactivated ? (
                          <WarningBadge>Invite sent</WarningBadge>
                        ) : (
                          <NeutralBadge>Deactivated</NeutralBadge>
                        )}
                      </Td>
                      <Td>
                        <ButtonRowAlignRight>
                          <PopoverMenuButton>
                            <PopoverMenu>
                              {userAccount.isActive ? (
                                <>
                                  <PopoverMenuOption
                                    onClick={() => {
                                      setDeactivateUserAccountModalState(
                                        userAccount
                                      );
                                    }}
                                  >
                                    <UserOutlineIcon /> Deactivate user
                                  </PopoverMenuOption>
                                </>
                              ) : !userAccount.isDeactivated ? (
                                <>
                                  <PopoverMenuOption
                                    onClick={() => {
                                      setResendUserAccountInviteModalState(
                                        userAccount
                                      );
                                    }}
                                  >
                                    <ReplayIcon />
                                    Resend invite
                                  </PopoverMenuOption>
                                  <PopoverMenuOption
                                    onClick={() => {
                                      setCancelPendingUserAccountModalState(
                                        userAccount
                                      );
                                    }}
                                  >
                                    <CancelSendIcon /> Cancel invite
                                  </PopoverMenuOption>
                                </>
                              ) : (
                                <>
                                  <PopoverMenuOption
                                    onClick={() => {
                                      setReactivateUserAccountModalState(
                                        userAccount
                                      );
                                    }}
                                  >
                                    <UserTickIcon /> Reactivate
                                  </PopoverMenuOption>
                                </>
                              )}
                            </PopoverMenu>
                          </PopoverMenuButton>
                        </ButtonRowAlignRight>
                      </Td>
                    </Tr>
                  ))
                )}
              </TBody>
            </UsersTable>
          </TableOverlay>

          <ApiErrorBox error={getUserAccountsRequest.error} withMargin={true} />
        </ConstrainedContainerContent>
      </ConstrainedContainer>

      <Modal
        isOpen={showInviteUserAccountsModal}
        close={() => setShowInviteUserAccountsModal(false)}
      >
        <InviteUserAccounts close={closeInviteUserAccountsModal} />
      </Modal>

      <Modal
        isOpen={resendUserAccountInviteModalState != null}
        close={closeResendUserAccountInviteModal}
      >
        {resendUserAccountInviteModalState != null && (
          <ResendUserAccountInvite
            close={closeResendUserAccountInviteModal}
            {...resendUserAccountInviteModalState}
          />
        )}
      </Modal>

      <Modal
        isOpen={cancelPendingUserAccountModalState != null}
        close={() => closeCancelPendingUserAccountModalState(false)}
      >
        {cancelPendingUserAccountModalState != null && (
          <CancelPendingUserAccount
            close={closeCancelPendingUserAccountModalState}
            {...cancelPendingUserAccountModalState}
          />
        )}
      </Modal>

      <Modal
        isOpen={deactivateUserAccountModalState != null}
        close={() => closeDeactivateUserAccountModalState(false)}
      >
        {deactivateUserAccountModalState != null && (
          <DeactivateUserAccount
            close={closeDeactivateUserAccountModalState}
            {...deactivateUserAccountModalState}
          />
        )}
      </Modal>

      <Modal
        isOpen={reactivateUserAccountModalState != null}
        close={() => closeReactivateUserAccountModalState(false)}
      >
        {reactivateUserAccountModalState != null && (
          <ReactivateUserAccount
            close={closeReactivateUserAccountModalState}
            {...reactivateUserAccountModalState}
          />
        )}
      </Modal>
    </>
  );
};

const UsersTable = styled(StandaloneTable)`
  margin-top: ${size250};
`;

const EmailAddress = styled.div`
  letter-spacing: ${letterSpacingSlightlyWide};
`;

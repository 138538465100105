import styled from 'styled-components/macro';
import { size000, size050, size100, size150 } from '../styling/sizes';
import { gray700, gray800, gray900 } from '../styling/colours';
import { fontSize100 } from '../styling/fontSizes';
import { IconButton } from './Buttons';

export const AttachmentsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const Attachment = styled.div<{ disable: 'true' | 'false' }>`
  display: inline-flex;
  align-items: center;
  padding: ${size000} ${size100};
  border-radius: 100px;
  border: solid 1px ${gray800};
  margin-right: ${size050};
  background-color: ${gray900};
  transition: all 0.25s ease;
  cursor: ${props => (props.disable === 'true' ? 'default' : 'pointer')};
  opacity: ${props => (props.disable === 'true' ? 0.5 : 1)};

  &:hover {
    background-color: ${gray800};
    border-color: ${gray700};
  }
`;

export const AttachmentFileName = styled.div`
  margin: 0 ${size000};
  font-size: ${fontSize100};
`;

export const AttachmentButton = styled(IconButton)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${size150};
`;

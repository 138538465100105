import React from 'react';
import styled from 'styled-components/macro';
import {
  primary600,
  gray900,
  gray800,
  primary500,
  gray600,
  primary700,
} from '../styling/colours';
import { fontSize700, fontSize300 } from '../styling/fontSizes';
import Hero from '../images/laptop2.jpg';
import { Link } from 'react-router-dom';
import { fontWeightBold } from '../styling/fontWeights';
import { letterSpacingWide } from '../styling/letterSpacing';
import { FlareIcon } from '../icons/Icons';
import { size300, size150, size200, size500, size250 } from '../styling/sizes';
import AngledBackground from '../images/angled-background.svg';
import { lineHeightWide, lineHeightTight } from '../styling/lineHeights';

export const Landing = () => (
  <Page>
    <Pane>
      <HeroImage src={Hero} />
      <PaneLeft>
        <PaneLeftContent>
          <Nav>
            <LogoLink to="/">
              <Logo />
              QI Compass
            </LogoLink>
            <div>
              <LoginLink to="login">Log in</LoginLink>
            </div>
          </Nav>
          <HeroText>
            Empowering people to{' '}
            <ColouredText>improve the world's health</ColouredText>
          </HeroText>
          <SalesText>
            Working in healthcare is <em>hard</em>. We know you go the extra
            mile, and often stay late to do Quality Improvement in your own
            time, because you care about getting things right for your patients.
            But QI can be confusing, and sometimes it’s difficult to know where
            to go next.
          </SalesText>
          <SalesText>
            QI Compass is a tool that organisations can give to their staff to
            help them navigate the world of Quality Improvement. With QI Compass
            you can save time, build a team, and discover new ways to deliver
            world-class care.
          </SalesText>
          <SalesText>
            It doesn't matter if you’re a first-timer or a quality improvement
            veteran: if you have a good idea, if you are ready to lead, if you
            are ready to support your team to improve patient care, then{' '}
            <ColouredTextLight>
              join us and start your quality improvement journey now
            </ColouredTextLight>
            .
          </SalesText>
        </PaneLeftContent>
      </PaneLeft>
    </Pane>
  </Page>
);

const Page = styled.div`
  background-color: ${gray900};
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-size: ${fontSize300};
  color: ${primary600};
  font-weight: ${fontWeightBold};
  letter-spacing: ${letterSpacingWide};
`;

const Logo = styled(FlareIcon)`
  display: block;
  height: ${size250};
  width: ${size250};
  margin-right: ${size150};
  fill: ${primary500};
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${gray600};
  margin: 0 ${size150};
  transition: all 0.25s ease;

  &:hover {
    color: ${gray800};
  }
`;

const LoginLink = styled(NavLink)`
  color: ${primary600};
  font-weight: ${fontWeightBold};
  letter-spacing: ${letterSpacingWide};

  &:hover {
    color: ${primary700};
  }
`;

const HeroText = styled.h1`
  margin-top: ${size300};
  font-size: ${fontSize700};
  color: ${gray800};
  line-height: ${lineHeightTight};
`;

const SalesText = styled.p`
  color: ${gray600};
  line-height: ${lineHeightWide};
  font-size: ${fontSize300};
`;

const ColouredText = styled.span`
  color: ${primary500};
`;

const ColouredTextLight = styled.span`
  color: ${primary600};
`;

const Pane = styled.div`
  width: 100%;
  position: relative;
`;

const PaneLeft = styled.div`
  background-image: url(${AngledBackground});
  background-size: 100% auto;
  background-position: -5px -5px;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 55%;
  position: relative;
`;

const PaneLeftContent = styled.div`
  padding: ${size200} ${size300};
  padding-right: ${size500};
`;

const HeroImage = styled.img`
  position: absolute;
  height: 100%;
  right: 0;
  opacity: 0.7;
`;

import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components/macro';
import { borderRadiusLarge } from '../styling/borders';
import { size200, size100, size150, size750 } from '../styling/sizes';
import { gray900, gray500, gray300, gray200 } from '../styling/colours';
import { fontSize100, fontSize300, fontSize400 } from '../styling/fontSizes';
import { fontWeightBold } from '../styling/fontWeights';
import { usePrevious } from '../hooks/usePrevious';
import { CrossIcon } from '../icons/Icons';
import { lineHeightMedium } from '../styling/lineHeights';
import { useRef } from 'react';

export const modalPortalRootElementId = 'modal-portal-root';
export const modalIsOpenClassName = 'modal-is-open';

type Props = {
  isOpen: boolean;
  close: () => void;
  children?: React.ReactNode;
  preventClose?: boolean;
};

export const Modal = ({ isOpen, close, children, preventClose }: Props) => {
  const prevIsOpen = usePrevious(isOpen);

  const modalPaneRef = useRef(null);

  useEffect(() => {
    const documentHtmlNode = document.getElementsByTagName('html').item(0)!;

    if (!prevIsOpen && isOpen) {
      documentHtmlNode.classList.add(modalIsOpenClassName);
    } else if (prevIsOpen && !isOpen) {
      documentHtmlNode.classList.remove(modalIsOpenClassName);
    }

    return () => documentHtmlNode.classList.remove(modalIsOpenClassName);
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isOpen) {
    return null;
  }

  const domNode = document.getElementById(modalPortalRootElementId);

  if (domNode == null) {
    throw new Error('Could not find modal portal root element');
  }

  return createPortal(
    <div>
      <ModalBackdrop>
        <ModalPane ref={modalPaneRef}>
          {children}
          {!preventClose && <CloseModalButton onClick={close} />}
        </ModalPane>
      </ModalBackdrop>
    </div>,
    domNode
  );
};

const backdropFadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const ModalBackdrop = styled.div`
  overflow-y: auto;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  z-index: 100;
  padding: ${size200} 0;
  animation: ${backdropFadeIn} 250ms ease forwards;
`;

const ModalPane = styled.div`
  position: relative;
  margin: auto;
  background-color: white;
  z-index: 110;
  border-radius: ${borderRadiusLarge};
  width: 90%;
  max-width: ${size750};
`;

const CloseModalButtonContainer = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  height: ${size200};
  width: ${size200};
  padding: 0;
  background: none;
  outline: none;
  border: none;
  color: ${gray500};
  transition: all 0.25s ease;
  cursor: pointer;

  svg {
    height: 100%;
    width: 100%;
  }

  &:hover {
    color: ${gray300};
  }
`;

const CloseModalButton = (props: { onClick: () => void }) => (
  <CloseModalButtonContainer onClick={props.onClick}>
    <CrossIcon />
  </CloseModalButtonContainer>
);

export const ModalHeader = styled.div`
  font-size: ${fontSize400};
  font-weight: ${fontWeightBold};
  color: ${gray200};
  margin-bottom: ${size150};
`;

export const ModalSubHeader = styled.div`
  font-size: ${fontSize300};
  font-weight: ${fontWeightBold};
  color: ${gray500};
  margin-bottom: ${size150};
`;

export const ModalContent = styled.div`
  padding: ${size200};
  line-height: ${lineHeightMedium};

  p {
    font-size: ${fontSize100};
    line-height: ${lineHeightMedium};
    white-space: pre-wrap;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const ModalFooter = styled.div`
  background-color: ${gray900};
  border-bottom-left-radius: ${borderRadiusLarge};
  border-bottom-right-radius: ${borderRadiusLarge};
  padding: ${size100} ${size200};
`;

import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../../../shared/Card';
import { projectCycleActionTypes } from '../../../model/ProjectCycleAction';
import {
  ProjectCycleStatus,
  projectCycleStatusDisplayNames,
} from '../../../model/ProjectCycle';
import styled from 'styled-components/macro';
import {
  size100,
  size200,
  size350,
  size250,
  size150,
  size050,
} from '../../../styling/sizes';
import { PrimaryButton } from '../../../shared/Buttons';
import {
  gray800,
  primary900,
  primary400,
  gray200,
  gray400,
  gray300,
} from '../../../styling/colours';
import { ReplayIcon, TickIcon } from '../../../icons/Icons';
import { fontSize300 } from '../../../styling/fontSizes';
import { fontWeightBold } from '../../../styling/fontWeights';
import { SuccessBadge, NeutralBadge } from '../../../shared/Badge';
import { Modal } from '../../../shared/Modal';
import { CompleteProject } from './CompleteProject';
import { NewCycle } from './NewCycle';
import { ProjectContext } from '../ProjectContext';
import { UserAccountContext } from '../../../features/userAccounts/UserAccountContext';
import { assertNotNull } from '../../../helpers/nullHelpers';
import { ActDataAttachments } from './ActDataAttachments';
import { ActDataWriteUp } from './ActDataWriteUp';

export const ActSection = () => {
  const history = useHistory();

  const { getProject, getCycle, hardReloadProject } = useContext(
    ProjectContext
  );

  const project = getProject();
  const cycle = getCycle();

  const userAccountContext = useContext(UserAccountContext);
  const currentUserAccountId = userAccountContext.getUserAccountId();

  const currentUserIsProjectLead =
    currentUserAccountId === project.leadUserAccount.userAccountId;

  const [modal, setModal] = useState<string | null>(null);

  const isAtOrBeyondStatus = (status: ProjectCycleStatus) => {
    const statuses = Object.keys(projectCycleStatusDisplayNames);
    return statuses.indexOf(cycle.status) >= statuses.indexOf(status);
  };

  if (!isAtOrBeyondStatus('Acting')) {
    return null;
  }

  const actData = assertNotNull(cycle.actData);

  const writeUp = actData.writeUp ?? '';
  const actionTaken = actData.action;
  const attachments = actData.attachments;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Act</CardTitle>
        {isAtOrBeyondStatus('Complete') ? (
          <SuccessBadge>Complete</SuccessBadge>
        ) : cycle.status === 'AwaitingApprovalToCompleteProject' ? (
          <NeutralBadge>Awaiting approval</NeutralBadge>
        ) : (
          <NeutralBadge>In Progress</NeutralBadge>
        )}
      </CardHeader>
      {cycle.status === 'Complete' ? (
        <>
          {actionTaken === 'NewCycle' ? (
            <>
              <CardContent>
                <Heading>Action taken</Heading>
                <div>The cycle was completed and a new cycle was started.</div>
              </CardContent>
              <ReasonSection>
                <Heading>Reason</Heading>
                <div>{actData.newCycleReason}</div>
              </ReasonSection>
            </>
          ) : (
            <>
              <CardContent>
                <Heading>Action taken</Heading>
                <div>The Project was marked as complete.</div>
              </CardContent>
            </>
          )}
        </>
      ) : cycle.status === 'AwaitingApprovalToCompleteProject' ? (
        <>
          <CardContent>
            The Project was marked as complete. A request has been sent to the
            Management team for review.
          </CardContent>
          <CardContent>
            <Heading>Write-Up</Heading>
            <ActDataWriteUp>{writeUp}</ActDataWriteUp>
          </CardContent>
          <CardContent>
            <Heading>Supporting documents</Heading>
            <ActDataAttachments
              projectCycleId={cycle.projectCycleId}
              attachments={attachments}
            />
          </CardContent>
        </>
      ) : !currentUserIsProjectLead ? (
        <CardContent>
          The project lead has not yet chosen an action.
        </CardContent>
      ) : (
        <Options>
          <Option>
            <OptionIcon>
              <ReplayIcon />
            </OptionIcon>
            <OptionItem>
              <OptionHeader>
                {projectCycleActionTypes.NewCycle.name}
              </OptionHeader>
              <OptionText>
                {projectCycleActionTypes.NewCycle.description}
              </OptionText>
              <PrimaryButton onClick={() => setModal('newCycle')}>
                Choose
              </PrimaryButton>
            </OptionItem>
            <Modal
              isOpen={modal === 'newCycle'}
              close={() => setModal(null)}
              preventClose={true}
            >
              <NewCycle
                close={() => setModal(null)}
                setCycleNumber={(newCycleNumber: number) => {
                  history.push(
                    `/app/projects/${project.projectId}/cycles/${newCycleNumber}`
                  );
                  hardReloadProject();
                }}
              />
            </Modal>
          </Option>
          <Option>
            <OptionIcon>
              <TickIcon />
            </OptionIcon>
            <OptionItem>
              <OptionHeader>Complete Project</OptionHeader>
              <OptionText>
                Marks the Project as completed. Requires approval.
              </OptionText>
              <PrimaryButton onClick={() => setModal('completeProject')}>
                Choose
              </PrimaryButton>
            </OptionItem>
            <Modal
              isOpen={modal === 'completeProject'}
              close={() => setModal(null)}
              preventClose={true}
            >
              <CompleteProject close={() => setModal(null)} />
            </Modal>
          </Option>
        </Options>
      )}
    </Card>
  );
};

const Options = styled.div`
  display: flex;
`;

const Option = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: ${size200};
  border-right: solid 1px ${gray800};

  &:last-of-type {
    border-right: none;
  }
`;

const OptionIcon = styled.div`
  width: ${size350};
  height: ${size350};
  border-radius: 100%;
  background-color: ${primary900};
  margin: 0 ${size100};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${primary400};
    height: ${size250};
    width: ${size250};
  }
`;

const OptionItem = styled.div`
  flex: 1;
  padding-left: ${size200};
`;

const OptionHeader = styled.div`
  font-weight: ${fontWeightBold};
  font-size: ${fontSize300};
  color: ${gray200};
  margin-bottom: ${size050};
`;

const OptionText = styled.div`
  color: ${gray400};
  margin-bottom: ${size150};
`;

const Heading = styled.div`
  color: ${gray300};
  font-weight: ${fontWeightBold};
  margin: 0 0 ${size050};
  padding-bottom: ${size050};
  font-size: ${fontSize300};
`;

const ReasonSection = styled(CardContent)`
  border-top: solid 1px ${gray800};
`;

import React, { useEffect } from 'react';
import { Section, SectionHeader } from '../shared/Section';
import { CompletedProjectsTable } from './project/CompletedProjectsTable';
import { useGet } from '../api/useGet';
import { GetCompletedProjectsResponse } from '../features/projects/GetCompletedProjectsResponse';
import { ApiErrorBox } from '../api/ApiErrorBox';
import { CenteredSpinner } from '../shared/Spinner';

export const CompletedProjects = () => {
  const getCompletedProjectsRequest = useGet<GetCompletedProjectsResponse>(
    'GetCompletedProjects'
  );

  useEffect(() => {
    getCompletedProjectsRequest.makeRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (getCompletedProjectsRequest.error != null) {
    return <ApiErrorBox error={getCompletedProjectsRequest.error} />;
  }

  const response = getCompletedProjectsRequest.response;

  if (getCompletedProjectsRequest.inProgress || response == null) {
    return <CenteredSpinner />;
  }

  const { completedProjects } = response;

  return (
    <>
      <Section>
        <SectionHeader>Completed Projects</SectionHeader>
        {completedProjects.length === 0 ? (
          <p>There are no completed Projects at the moment.</p>
        ) : (
          <CompletedProjectsTable projects={completedProjects} />
        )}
      </Section>
    </>
  );
};

import React, { createContext, useEffect } from 'react';
import { useGet } from '../../api/useGet';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { CenteredSpinner } from '../../shared/Spinner';

type GetCurrentUserAccountResponse = {
  userAccountId: number;
  fullName: string;
  isAnApproverForSomeTeams: boolean;
};

type UserAccountContextValue = {
  getUserAccountId: () => number;
  getFullName: () => string;
  getIsAnApproverForSomeTeams: () => boolean;
};

export const UserAccountContext = createContext<UserAccountContextValue>({
  getUserAccountId: () => {
    throw new Error('UserAccountContext is not initialised yet');
  },
  getFullName: () => {
    throw new Error('UserAccountContext is not initialised yet');
  },
  getIsAnApproverForSomeTeams: () => {
    throw new Error('UserAccountContext is not initialised yet');
  },
});

type Props = {
  children?: React.ReactNode;
};

export const UserAccountContextProvider = (props: Props) => {
  const getCurrentUserAccountRequest = useGet<GetCurrentUserAccountResponse>(
    'CurrentUserAccount'
  );

  useEffect(() => {
    getCurrentUserAccountRequest.makeRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (getCurrentUserAccountRequest.error) {
    return (
      <ApiErrorBox
        error={getCurrentUserAccountRequest.error}
        withMargin={true}
      />
    );
  }

  const response = getCurrentUserAccountRequest.response;

  if (getCurrentUserAccountRequest.inProgress || response == null) {
    return <CenteredSpinner />;
  }

  const value: UserAccountContextValue = {
    getUserAccountId: () => response.userAccountId,
    getFullName: () => response.fullName,
    getIsAnApproverForSomeTeams: () => response.isAnApproverForSomeTeams,
  };

  return (
    <UserAccountContext.Provider value={value}>
      {props.children}
    </UserAccountContext.Provider>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  SecondaryButton,
  PrimaryButton,
} from '../../shared/Buttons';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { GetUserAccountsResponse } from '../userAccounts/GetUserAccountsResponse';
import { usePostWithResponse } from '../../api/usePostWithResponse';
import { GetUserAccountsQuery } from '../userAccounts/GetUserAccountsQuery';
import { debounce, includes } from 'lodash';
import { SearchInput } from '../../shared/SearchPanel';
import { Spinner } from '../../shared/Spinner';
import { AddApproverCommand } from './AddApproverCommand';
import {
  UserResult,
  UserResultEmail,
  UserResultName,
  UserResults,
  UserResultsNoResultsContainer,
  UserResultsSpinnerContainer,
} from '../../shared/UserResults';

type Props = {
  teamId: number;
  existingApproverUserAccountIds: Array<number>;
  close: (andRefresh: boolean) => void;
};

export const AddApprover = (props: Props) => {
  const addApproverRequest = usePostWithoutResponse<AddApproverCommand>(
    'AddApprover'
  );

  const [selectedUserAccountId, setSelectedUserAccountId] = useState<
    number | null
  >(null);

  const submit = () => {
    if (selectedUserAccountId != null) {
      addApproverRequest.makeRequest({
        body: { teamId: props.teamId, userAccountId: selectedUserAccountId },
        onSuccess: () => props.close(true),
      });
    }
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);

  const [
    latestResponse,
    setLatestResponse,
  ] = useState<GetUserAccountsResponse | null>(null);

  const getUserAccountsRequest = usePostWithResponse<
    GetUserAccountsQuery,
    GetUserAccountsResponse
  >('GetUserAccounts');

  const makeRequest = () => {
    getUserAccountsRequest.makeRequest({
      body: {
        searchTerm:
          currentSearchTerm.trim() === '' ? null : currentSearchTerm.trim(),
        includeActive: true,
        includeInviteSent: false,
        includeDeactivated: false,
      },
      onSuccess: response => setLatestResponse(response),
    });
  };

  useEffect(() => {
    setSelectedUserAccountId(null);
    makeRequest();
  }, [currentSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedSetSearchTerm = useRef(
    debounce((value: string) => {
      setCurrentSearchTerm(value);
    }, 1_000)
  );

  useEffect(() => {
    debouncedSetSearchTerm.current(searchTerm);
  }, [searchTerm]);

  const onChangeSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const usersToShow = latestResponse?.userAccounts.filter(
    ua => !includes(props.existingApproverUserAccountIds, ua.userAccountId)
  );

  return (
    <>
      <ModalContent>
        <ModalHeader>Add approver</ModalHeader>
        <SearchInput
          placeholder="Search by name or email address"
          value={searchTerm}
          onChange={onChangeSearchInput}
        />
        {getUserAccountsRequest.inProgress && (
          <UserResultsSpinnerContainer>
            <Spinner />
          </UserResultsSpinnerContainer>
        )}
        {!getUserAccountsRequest.inProgress && (
          <UserResults>
            {usersToShow && usersToShow.length > 0 ? (
              usersToShow.map(ua => (
                <UserResult
                  key={ua.userAccountId}
                  selected={selectedUserAccountId === ua.userAccountId}
                  onClick={() => setSelectedUserAccountId(ua.userAccountId)}
                >
                  <UserResultName>{ua.fullName}</UserResultName>
                  <UserResultEmail>({ua.emailAddress})</UserResultEmail>
                </UserResult>
              ))
            ) : (
              <UserResultsNoResultsContainer>
                No results
              </UserResultsNoResultsContainer>
            )}
          </UserResults>
        )}
        <ApiErrorBox error={addApproverRequest.error} withMargin={true} />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={() => props.close(false)}
            disabled={addApproverRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={submit}
            disabled={
              selectedUserAccountId == null || addApproverRequest.inProgress
            }
          >
            Confirm
          </PrimaryButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

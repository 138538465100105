import React, { useEffect } from 'react';
import { Section, SectionHeader } from '../shared/Section';
import { useGet } from '../api/useGet';
import { ApiErrorBox } from '../api/ApiErrorBox';
import { CenteredSpinner } from '../shared/Spinner';
import { GetActiveProjectsResponse } from '../features/projects/GetActiveProjectsResponse';
import { ActiveProjectsTable } from './project/ActiveProjectsTable';

export const ActiveProjects = () => {
  const getActiveProjectsRequest = useGet<GetActiveProjectsResponse>(
    'GetActiveProjects'
  );

  useEffect(() => {
    getActiveProjectsRequest.makeRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (getActiveProjectsRequest.error != null) {
    return <ApiErrorBox error={getActiveProjectsRequest.error} />;
  }

  const response = getActiveProjectsRequest.response;

  if (getActiveProjectsRequest.inProgress || response == null) {
    return <CenteredSpinner />;
  }

  const { activeProjects } = response;

  return (
    <>
      <Section>
        <SectionHeader>Active Projects</SectionHeader>
        {activeProjects.length === 0 ? (
          <p>There are no active Projects at the moment.</p>
        ) : (
          <ActiveProjectsTable projects={activeProjects} />
        )}
      </Section>
    </>
  );
};

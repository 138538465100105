import styled from 'styled-components/macro';
import { desktopContainerWidth } from '../styling/sizes';

export const ConstrainedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConstrainedContainerContent = styled.div`
  width: ${desktopContainerWidth};
  max-width: ${desktopContainerWidth};
`;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  OverlappingHeaderTitleActions,
  OverlappingHeaderTitleBar,
  OverlappingHeaderTitleText,
  OverlappingHeaderTop,
} from '../../layout/OverlappingHeader';
import {
  TertiaryButtonWhite,
  ButtonRowAlignRight,
  PrimaryButton,
} from '../../shared/Buttons';
import { usePostWithResponse } from '../../api/usePostWithResponse';
import {
  ConstrainedContainer,
  ConstrainedContainerContent,
} from '../../layout/ConstrainedContainer';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { WarningBadge } from '../../shared/Badge';
import { size150, size050, size275 } from '../../styling/sizes';
import styled from 'styled-components';
import { Spinner } from '../../shared/Spinner';
import {
  ArchiveIcon,
  UnarchiveIcon,
  GroupIcon,
  UserTickIcon,
  CrossIcon,
  PlusIcon,
  PencilIcon,
} from '../../icons/Icons';
import { gray300 } from '../../styling/colours';
import { fontSize300 } from '../../styling/fontSizes';
import { StandaloneTable, TBody, Tr, Td, Th, THead } from '../../shared/Table';
import { PopoverMenuButton } from '../../shared/PopoverMenuButton';
import { PopoverMenu, PopoverMenuOption } from '../../shared/PopoverMenu';
import { ArchiveTeam } from './ArchiveTeam';
import { Modal } from '../../shared/Modal';
import { UnarchiveTeam } from './UnarchiveTeam';
import { AddApprover } from './AddApprover';
import { RemoveApprover } from './RemoveApprover';
import { MakeApprover } from './MakeApprover';
import { EditTeam } from './EditTeam';

type GetTeamQuery = {
  teamId: number;
};

type GetTeamUserResponse = {
  teamUserAccountId: number;
  userAccountId: number;
  name: string;
  emailAddress: string;
};

type GetTeamResponse = {
  teamId: number;
  name: string;
  isArchived: boolean;
  users: Array<GetTeamUserResponse>;
  approvers: Array<GetTeamUserResponse>;
};

type Params = {
  teamId: string;
};

export const ManageTeam = () => {
  const teamId = Number(useParams<Params>().teamId);

  const getTeamRequest = usePostWithResponse<GetTeamQuery, GetTeamResponse>(
    'GetTeam'
  );

  const getTeam = () => getTeamRequest.makeRequest({ body: { teamId } });

  useEffect(() => {
    getTeam();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [modal, setModal] = useState<string | null>(null);

  const [
    approverToRemove,
    setApproverToRemove,
  ] = useState<GetTeamUserResponse | null>(null);

  const [
    userToMakeApprover,
    setUserToMakeApprover,
  ] = useState<GetTeamUserResponse | null>(null);

  if (getTeamRequest.error != null) {
    return (
      <ConstrainedContainer>
        <ConstrainedContainerContent>
          <ApiErrorBox error={getTeamRequest.error} withMargin={true} />
        </ConstrainedContainerContent>
      </ConstrainedContainer>
    );
  }

  const response = getTeamRequest.response;

  if (getTeamRequest.inProgress || response == null) {
    return (
      <OverlappingHeaderTop>
        <OverlappingHeaderTitleBar>
          <OverlappingHeaderTitleText>
            <Spinner />
          </OverlappingHeaderTitleText>
          <OverlappingHeaderTitleActions>
            <TertiaryButtonWhite disabled={true}> </TertiaryButtonWhite>
          </OverlappingHeaderTitleActions>
        </OverlappingHeaderTitleBar>
      </OverlappingHeaderTop>
    );
  }

  const openArchiveModal = () => setModal('archive');
  const openUnarchiveModal = () => setModal('unarchive');
  const openAddApproverModal = () => setModal('add-approver');
  const openEditModal = () => setModal('edit');

  const closeModal = (andRefresh: boolean) => {
    setModal(null);

    if (andRefresh) {
      getTeam();
    }
  };

  const closeRemoveApproverModal = (andRefresh: boolean) => {
    setApproverToRemove(null);

    if (andRefresh) {
      getTeam();
    }
  };

  const closeMakeApproverModal = (andRefresh: boolean) => {
    setUserToMakeApprover(null);

    if (andRefresh) {
      getTeam();
    }
  };

  return (
    <>
      <OverlappingHeaderTop>
        <OverlappingHeaderTitleBar>
          <OverlappingHeaderTitleText>
            {response.name}
            {response.isArchived && <ArchivedBadge>Archived</ArchivedBadge>}
          </OverlappingHeaderTitleText>
          <OverlappingHeaderTitleActions>
            <TertiaryButtonWhite icon="true" onClick={openEditModal}>
              <PencilIcon /> Edit
            </TertiaryButtonWhite>
            {!response.isArchived ? (
              <TertiaryButtonWhite icon="true" onClick={openArchiveModal}>
                <ArchiveIcon /> Archive
              </TertiaryButtonWhite>
            ) : (
              <TertiaryButtonWhite icon="true" onClick={openUnarchiveModal}>
                <UnarchiveIcon /> Unarchive
              </TertiaryButtonWhite>
            )}
          </OverlappingHeaderTitleActions>
        </OverlappingHeaderTitleBar>
      </OverlappingHeaderTop>
      <Spacer />
      <ConstrainedContainer>
        <ConstrainedContainerContent>
          <HeadingContainer>
            <Heading>
              <GroupIcon /> Users
            </Heading>
          </HeadingContainer>
          <FixedTable>
            <THead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th />
              </Tr>
            </THead>
            <TBody>
              {response.users.length === 0 ? (
                <Tr>
                  <Td colSpan={3}>No results</Td>
                </Tr>
              ) : (
                response.users.map(user => (
                  <Tr key={user.teamUserAccountId}>
                    <Td>{user.name}</Td>
                    <Td>{user.emailAddress}</Td>
                    <Td>
                      <ButtonRowAlignRight>
                        <PopoverMenuButton>
                          <PopoverMenu>
                            <PopoverMenuOption
                              onClick={() => setUserToMakeApprover(user)}
                            >
                              <UserTickIcon />
                              Make approver
                            </PopoverMenuOption>
                          </PopoverMenu>
                        </PopoverMenuButton>
                      </ButtonRowAlignRight>
                    </Td>
                  </Tr>
                ))
              )}
            </TBody>
          </FixedTable>
          <Spacer />
          <HeadingContainer>
            <Heading>
              <UserTickIcon /> Approvers
            </Heading>
            <ButtonRowAlignRight>
              <PrimaryButton icon="true" onClick={openAddApproverModal}>
                <PlusIcon />
                Add approver
              </PrimaryButton>
            </ButtonRowAlignRight>
          </HeadingContainer>
          <FixedTable>
            <THead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th />
              </Tr>
            </THead>
            <TBody>
              {response.approvers.length === 0 ? (
                <Tr>
                  <Td colSpan={3}>No results</Td>
                </Tr>
              ) : (
                response.approvers.map(approver => (
                  <Tr key={approver.teamUserAccountId}>
                    <Td>{approver.name}</Td>
                    <Td>{approver.emailAddress}</Td>
                    <Td>
                      <ButtonRowAlignRight>
                        <PopoverMenuButton>
                          <PopoverMenu>
                            <PopoverMenuOption
                              onClick={() => setApproverToRemove(approver)}
                            >
                              <CrossIcon />
                              Remove approver
                            </PopoverMenuOption>
                          </PopoverMenu>
                        </PopoverMenuButton>
                      </ButtonRowAlignRight>
                    </Td>
                  </Tr>
                ))
              )}
            </TBody>
          </FixedTable>
        </ConstrainedContainerContent>
      </ConstrainedContainer>

      <Modal isOpen={modal === 'edit'} close={() => closeModal(false)}>
        <EditTeam teamId={teamId} teamName={response.name} close={closeModal} />
      </Modal>

      <Modal isOpen={modal === 'archive'} close={() => closeModal(false)}>
        <ArchiveTeam teamId={teamId} close={closeModal} />
      </Modal>

      <Modal isOpen={modal === 'unarchive'} close={() => closeModal(false)}>
        <UnarchiveTeam teamId={teamId} close={closeModal} />
      </Modal>

      <Modal isOpen={modal === 'add-approver'} close={() => closeModal(false)}>
        <AddApprover
          teamId={teamId}
          close={closeModal}
          existingApproverUserAccountIds={response.approvers.map(
            a => a.userAccountId
          )}
        />
      </Modal>

      <Modal
        isOpen={approverToRemove != null}
        close={() => closeRemoveApproverModal(false)}
      >
        {approverToRemove != null && (
          <RemoveApprover
            teamUserAccountId={approverToRemove.teamUserAccountId}
            name={approverToRemove.name}
            emailAddress={approverToRemove.emailAddress}
            close={closeRemoveApproverModal}
          />
        )}
      </Modal>

      <Modal
        isOpen={userToMakeApprover != null}
        close={() => closeMakeApproverModal(false)}
      >
        {userToMakeApprover != null && (
          <MakeApprover
            teamId={teamId}
            userAccountId={userToMakeApprover.userAccountId}
            name={userToMakeApprover.name}
            emailAddress={userToMakeApprover.emailAddress}
            close={closeMakeApproverModal}
          />
        )}
      </Modal>
    </>
  );
};

const FixedTable = styled(StandaloneTable)`
  table-layout: fixed;
`;

const ArchivedBadge = styled(WarningBadge)`
  margin-left: ${size150};
  top: 2px;
  position: relative;
`;

const Spacer = styled.div`
  height: ${size275};
`;

const Heading = styled.h3`
  margin: 0;
  display: flex;
  align-items: center;
  color: ${gray300};
  font-size: ${fontSize300};

  svg {
    margin-right: ${size050};
  }
`;

const HeadingContainer = styled.div`
  margin-bottom: ${size150};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { FormField } from '../form/FormField';
import { Label } from '../form/Label';
import { InputField } from '../form/InputField';
import { Form } from '../form/Form';
import styled from 'styled-components/macro';
import { gray000, primary600, primary500 } from '../styling/colours';
import { size150, size600, size200, size250 } from '../styling/sizes';
import { borderRadiusLarge } from '../styling/borders';
import { fontSize300 } from '../styling/fontSizes';
import { fontWeightBold } from '../styling/fontWeights';
import { letterSpacingWide } from '../styling/letterSpacing';
import { FlareIcon } from '../icons/Icons';
import { shadow100 } from '../styling/shadows';
import { AccountType } from '../authentication/AccountType';
import { usePostWithResponse } from '../api/usePostWithResponse';
import { ApiErrorBox } from '../api/ApiErrorBox';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { Validator } from 'fluentvalidation-ts';
import { FormSubmitButton } from '../form/FormSubmitButton';

type LoginCommand = {
  emailAddress: string;
  password: string;
};

type LoginResponse = {
  accountType: AccountType;
};

export const Login = () => {
  const history = useHistory();
  const authenticationContext = useContext(AuthenticationContext);

  const loginRequest = usePostWithResponse<LoginCommand, LoginResponse>(
    'Login'
  );

  const onSubmit = (
    formModel: FormModel,
    helpers: FormikHelpers<FormModel>
  ) => {
    loginRequest.makeRequest({
      body: formModel,
      onSuccess: (response: LoginResponse) => {
        authenticationContext.setState({
          isAuthenticated: true,
          accountType: response.accountType,
        });

        history.push(response.accountType === 'user' ? '/app' : '/');
      },
      onError: () => helpers.setSubmitting(false),
    });
  };

  return (
    <PageContainer>
      <LogoLink to="/">
        <Logo />
        QI Compass
      </LogoLink>
      <LoginCard>
        <Formik<FormModel>
          initialValues={initialFormModel}
          onSubmit={onSubmit}
          validate={formValidator.validate}
        >
          <Form>
            <FormField>
              <Label>Email</Label>
              <InputField name="emailAddress" />
            </FormField>
            <FormField>
              <Label>Password</Label>
              <InputField name="password" type="password" />
            </FormField>
            <LoginButton>
              {loginRequest.inProgress ? 'Logging in...' : 'Log in'}
            </LoginButton>
            <ApiErrorBox error={loginRequest.error} withMargin={true} />
          </Form>
        </Formik>
      </LoginCard>
    </PageContainer>
  );
};

type FormModel = {
  emailAddress: string;
  password: string;
};

const initialFormModel: FormModel = {
  emailAddress: '',
  password: '',
};

class FormValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleFor('emailAddress')
      .notEmpty()
      .withMessage('Please enter your email address')
      .emailAddress()
      .withMessage('Please enter a valid email address');

    this.ruleFor('password')
      .notEmpty()
      .withMessage('Please enter your password');
  }
}

const formValidator = new FormValidator();

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: ${gray000};
`;

const LoginCard = styled.div`
  padding: ${size200};
  border-radius: ${borderRadiusLarge};
  background-color: white;
  width: 90%;
  max-width: ${size600};
  box-shadow: ${shadow100};
`;

const LoginButton = styled(FormSubmitButton)`
  display: block;
  width: 100%;
  margin-top: ${size200};
`;

const LogoLink = styled(Link)`
  margin-top: 10vh;
  margin-bottom: ${size200};
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-size: ${fontSize300};
  color: ${primary600};
  font-weight: ${fontWeightBold};
  letter-spacing: ${letterSpacingWide};
`;

const Logo = styled(FlareIcon)`
  display: block;
  height: ${size250};
  width: ${size250};
  margin-right: ${size150};
  fill: ${primary500};
`;

import React from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import {
  ButtonRowAlignRight,
  PrimaryButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { ApiErrorBox } from '../../api/ApiErrorBox';

type LeaveTeamCommand = { teamId: number };

type Props = {
  teamId: number;
  teamName: string;
  close: (andRefresh: boolean) => void;
};

export const LeaveTeam = (props: Props) => {
  const leaveTeamRequest = usePostWithoutResponse<LeaveTeamCommand>(
    'LeaveTeam'
  );

  const submit = () => {
    leaveTeamRequest.makeRequest({
      body: { teamId: props.teamId },
      onSuccess: () => props.close(true),
    });
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Leave team</ModalHeader>
        <p>
          Are you sure you wish to leave <strong>{props.teamName}</strong>?
        </p>
        <ApiErrorBox error={leaveTeamRequest.error} withMargin={true} />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={() => props.close(false)}
            disabled={leaveTeamRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={submit}
            disabled={leaveTeamRequest.inProgress}
          >
            Confirm
          </PrimaryButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { keys, sortBy, find } from 'lodash';
import { Table, THead, Tr, Th, TBody, Td } from '../../../shared/Table';
import { gray600, primary500 } from '../../../styling/colours';
import { fontSize100 } from '../../../styling/fontSizes';
import styled from 'styled-components/macro';
import { size000, size200, size300 } from '../../../styling/sizes';
import {
  ButtonRow,
  ButtonRowAlignRight,
  PrimaryButton,
  SecondaryButton,
} from '../../../shared/Buttons';
import {
  DownloadIcon,
  PencilIcon,
  CrossIcon,
  ArrowBackIcon,
} from '../../../icons/Icons';
import {
  ProjectCycleStatus,
  projectCycleStatusDisplayNames,
} from '../../../model/ProjectCycle';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from '../../../shared/Modal';
import { EditDataPoint } from './EditDataPoint';
import {
  Breadcrumbs,
  BreadcrumbLink,
  BreadcrumbDivider,
  BreadcrumbItem,
} from '../../../shared/Breadcrumbs';
import { ProjectContext } from '../ProjectContext';
import { useGet } from '../../../api/useGet';
import { DataPointResponse, GetDataResponse } from './GetDataResponse';
import { ApiErrorBox } from '../../../api/ApiErrorBox';
import { CenteredSpinner } from '../../../shared/Spinner';
import { formatAsShortDateTime } from '../../../helpers/dateTimeHelpers';

export const ViewData = () => {
  const { getProject, getCycle } = useContext(ProjectContext);

  const project = getProject();
  const cycle = getCycle();
  const cycleNumber = cycle.cycleNumber;

  // const { getUserAccountId } = useContext(UserAccountContext);
  // const userAccountId = getUserAccountId();

  const [editId, setEditId] = useState<string | null>(null);

  const [deleteId, setDeleteId] = useState<string | null>(null);

  // const currentUserIsProjectLead =
  //   userAccountId === project.leadUserAccount.userAccountId;

  const isAtOrBeyondStatus = (status: ProjectCycleStatus) => {
    const statuses = Object.keys(projectCycleStatusDisplayNames);
    return statuses.indexOf(cycle.status) >= statuses.indexOf(status);
  };

  const getDataRequest = useGet<GetDataResponse>('GetData');

  useEffect(() => {
    getDataRequest.makeRequest({
      query: {
        projectId: project.projectId.toString(),
        cycleNumber: cycleNumber.toString(),
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { response, inProgress, error, updateResponse } = getDataRequest;

  const onEdited = (newDataPoint: DataPointResponse) => {
    const dataPointId = newDataPoint.id;

    updateResponse(draft => {
      draft.dataPoints = draft.dataPoints.map(dp =>
        dp.id === dataPointId ? newDataPoint : dp
      );
    });
  };

  if (error) {
    return <ApiErrorBox error={error} />;
  }

  if (response == null || inProgress) {
    return <CenteredSpinner />;
  }

  const {
    dataPoints,
    userAccountFullNamesById,
    dataEntryFormVariableNamesById,
  } = response;

  const dataEntryFormVariableIds = sortBy(
    keys(dataEntryFormVariableNamesById).map(x => Number(x))
  );

  const downloadData = () => {
    let csv = 'ID,Entered By,Created,Modified';

    for (const dataEntryFormVariableId of dataEntryFormVariableIds) {
      const variableName =
        dataEntryFormVariableNamesById[dataEntryFormVariableId];
      csv += ',' + variableName;
    }

    csv += '\r\n';

    for (const dataPoint of dataPoints) {
      csv += '_' + dataPoint.id;

      let userAccountFullName =
        userAccountFullNamesById[dataPoint.userAccountId];

      csv += ',' + userAccountFullName;

      csv += ',' + dataPoint.createdDate + ',' + dataPoint.lastModifiedDate;

      for (const dataEntryFormVariableId of dataEntryFormVariableIds) {
        csv +=
          ',' +
          (dataPoint.valuesByDataEntryFormVariableId[
            dataEntryFormVariableId
          ]?.toString() ?? '');
      }

      csv += '\r\n';
    }

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${project.title}.csv`;
    hiddenElement.click();
  };

  const dataPointBeingEdited =
    find(response.dataPoints, dp => dp.id === editId) || null;

  return (
    <div>
      <Breadcrumbs>
        <BreadcrumbLink to={`/app/projects`}>Projects</BreadcrumbLink>
        <BreadcrumbDivider />
        <BreadcrumbLink to={`/app/projects/${project.projectId}`}>
          {project.title}
        </BreadcrumbLink>
        <BreadcrumbDivider />
        <BreadcrumbLink
          to={`/app/projects/${project.projectId}/cycles/${cycleNumber}`}
        >
          Cycle {cycleNumber}
        </BreadcrumbLink>
        <BreadcrumbDivider />
        <BreadcrumbItem>View data</BreadcrumbItem>
      </Breadcrumbs>
      <ActionButtons>
        <SecondaryButton
          icon="true"
          as={Link}
          to={`/app/projects/${project.projectId}/cycles/${cycleNumber}`}
        >
          <ArrowBackIcon />
          Back to Project
        </SecondaryButton>
        <PrimaryButton icon="true" onClick={downloadData}>
          <DownloadIcon /> Download data
        </PrimaryButton>
      </ActionButtons>
      <DataTable>
        <THead>
          <Tr>
            <Th>Entered by</Th>
            {dataEntryFormVariableIds.map(dataEntryFormVariableId => (
              <Th key={dataEntryFormVariableId}>
                {dataEntryFormVariableNamesById[dataEntryFormVariableId]}
              </Th>
            ))}
            {!isAtOrBeyondStatus('Studying') && (
              <>
                <IconHeader />
                <IconHeader />
              </>
            )}
          </Tr>
        </THead>
        <TBody>
          {dataPoints.length === 0 && (
            <Tr>
              <Td
                colSpan={
                  1 +
                  dataEntryFormVariableIds.length +
                  (!isAtOrBeyondStatus('Studying') ? 2 : 0)
                }
              >
                No data has been collected yet.
              </Td>
            </Tr>
          )}
          {dataPoints.map(dataPoint => (
            <Tr key={dataPoint.id}>
              <Td>
                <div>{userAccountFullNamesById[dataPoint.userAccountId]}</div>
                <DateStamp>
                  {formatAsShortDateTime(dataPoint.createdDate)}
                </DateStamp>
              </Td>
              {dataEntryFormVariableIds.map(dataEntryFormVariableId => (
                <Td key={dataEntryFormVariableId}>
                  {dataPoint.valuesByDataEntryFormVariableId[
                    dataEntryFormVariableId
                  ]?.toString()}
                </Td>
              ))}
              {!isAtOrBeyondStatus('Studying') && (
                <>
                  <IconCell>
                    <ActionButton onClick={() => setEditId(dataPoint.id)}>
                      <PencilIcon />
                    </ActionButton>
                  </IconCell>
                  <IconCell>
                    <ActionButton onClick={() => setDeleteId('TODO')}>
                      <CrossIcon />
                    </ActionButton>
                  </IconCell>
                </>
              )}
            </Tr>
          ))}
        </TBody>
      </DataTable>
      {dataPointBeingEdited != null && (
        <Modal isOpen={true} close={() => setEditId(null)}>
          <EditDataPoint
            dataPoint={dataPointBeingEdited}
            close={() => setEditId(null)}
            onEdited={onEdited}
          />
        </Modal>
      )}
      {deleteId && (
        <Modal isOpen={true} close={() => setDeleteId(null)}>
          <ModalContent>
            <ModalHeader>Delete data entry</ModalHeader>
            <div>Are you sure you wish to delete this entry?</div>
          </ModalContent>
          <ModalFooter>
            <ButtonRowAlignRight>
              <SecondaryButton onClick={() => setDeleteId(null)}>
                Cancel
              </SecondaryButton>
              <PrimaryButton onClick={() => alert('TODO')}>
                Confirm
              </PrimaryButton>
            </ButtonRowAlignRight>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

const DataTable = styled(Table)`
  width: 100%;
`;

const DateStamp = styled.div`
  color: ${gray600};
  font-size: ${fontSize100};
  margin-top: ${size000};
`;

const ActionButtons = styled(ButtonRow)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${size200};
`;

const IconHeader = styled(Th)`
  width: ${size300};
  padding-left: 0;
  padding-right: 0;
`;

const IconCell = styled(Td)`
  padding-left: 0;
  padding-right: 0;
`;

const ActionButton = styled.div`
  color: ${gray600};
  transition: all 0.25s ease;
  cursor: pointer;

  &:hover {
    color: ${primary500};
  }
`;

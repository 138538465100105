import styled from 'styled-components/macro';
import { size275, size050 } from '../styling/sizes';
import { borderRadiusSmall } from '../styling/borders';
import {
  gray800,
  gray700,
  yellow300,
  gray000,
  gray950,
  yellow900,
  yellow500,
  yellow600,
} from '../styling/colours';
import { fontSize200 } from '../styling/fontSizes';
import { ValidityState } from '../form/ValidityState';

export const Select = styled.select<{ validityState?: ValidityState }>`
  height: ${size275};
  padding: 0 ${size050};
  width: 100%;
  border-radius: ${borderRadiusSmall};
  outline: none;
  border-style: none;
  transition: all 0.25s ease;
  font-size: ${fontSize200};
  cursor: pointer;

  color: ${props => (props.validityState === 'invalid' ? yellow300 : gray000)};

  background-color: ${props =>
    props.validityState === 'invalid' ? yellow900 : gray950};

  box-shadow: 0 0 0 1px
    ${props => (props.validityState === 'invalid' ? yellow500 : gray800)};

  &:focus {
    color: ${gray000};
    background-color: white;
    box-shadow: 0 0 0 2px
      ${props => (props.validityState === 'invalid' ? yellow600 : gray700)};
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  &::-ms-expand {
    display: none;
  }

  &:disabled {
    opacity: 0.8;
  }
`;

import React from 'react';
import styled from 'styled-components/macro';
import { Table, TBody, Td, Th, THead, Tr } from '../../shared/Table';
import { CompletedProjectResponse } from '../../features/projects/CompletedProjectResponse';
import { AppLink } from '../../shared/AppLink';
import { fontWeightBold } from '../../styling/fontWeights';
import { formatAsShortDate } from '../../helpers/dateTimeHelpers';
import { UserCircles } from '../../shared/UserCircles';

type Props = {
  projects: Array<CompletedProjectResponse>;
};

export const CompletedProjectsTable = ({ projects }: Props) => {
  if (projects.length === 0) {
    return null;
  }

  return (
    <ProjectsTable>
      <THead>
        <Tr>
          <Th>Title</Th>
          <Th>Lead by</Th>
          <Th>Contributors</Th>
          <Th>Completed</Th>
        </Tr>
      </THead>
      <TBody>
        {projects.map(p => {
          return (
            <Tr key={p.projectId}>
              <Td>
                <ProjectLink to={`/app/projects/${p.projectId}`}>
                  {p.title}
                </ProjectLink>
              </Td>
              <Td>
                <AppLink to={`/app/users/${p.leadUserAccount.userAccountId}`}>
                  {p.leadUserAccount.fullName}
                </AppLink>
              </Td>
              <Td>
                <UserCircles
                  users={p.contributors.map(c => ({
                    userAccountId: c.userAccountId,
                    fullName: c.fullName,
                  }))}
                />
              </Td>
              <Td>{formatAsShortDate(p.dateCompleted)}</Td>
            </Tr>
          );
        })}
      </TBody>
    </ProjectsTable>
  );
};

const ProjectLink = styled(AppLink)`
  font-weight: ${fontWeightBold};
`;

const ProjectsTable = styled(Table)`
  width: 100%;
`;

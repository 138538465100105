import React, { useContext } from 'react';
import { usePostWithoutResponse } from '../../../api/usePostWithoutResponse';
import { DeleteStudyAttachmentCommand } from './DeleteStudyAttachmentCommand';
import { ProjectContext } from '../ProjectContext';
import produce from 'immer';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '../../../shared/Modal';
import {
  ButtonRowAlignRight,
  DangerButton,
  SecondaryButton,
} from '../../../shared/Buttons';

type Props = {
  isOpen: boolean;
  close: () => void;
  projectCycleStudyAttachmentId: number;
};

export const DeleteStudyAttachmentModal = ({
  isOpen,
  close,
  projectCycleStudyAttachmentId,
}: Props) => {
  const { getProject, setProject, getCycle } = useContext(ProjectContext);

  const project = getProject();
  const currentCycle = getCycle();

  const removeStudyAttachment = () => {
    const newProject = produce(project, draftProject => {
      const latestCycle = draftProject.cycles.filter(
        c => c.projectCycleId === currentCycle.projectCycleId
      )[0]!;

      latestCycle.study!.attachments = latestCycle.study!.attachments.filter(
        a => a.projectCycleStudyAttachmentId !== projectCycleStudyAttachmentId
      );
    });

    setProject(newProject);
  };

  const deleteStudyAttachmentRequest = usePostWithoutResponse<
    DeleteStudyAttachmentCommand
  >('DeleteStudyAttachment');

  const deleteAttachment = () => {
    deleteStudyAttachmentRequest.makeRequest({
      body: {
        projectCycleId: currentCycle.projectCycleId,
        projectCycleStudyAttachmentId,
      },
      onSuccess: () => {
        removeStudyAttachment();
        close();
      },
    });
  };

  return (
    <Modal isOpen={isOpen} close={close} preventClose={true}>
      <ModalContent>
        <ModalHeader>Delete attachment</ModalHeader>
        <p>Are you sure you wish to delete this attachment?</p>
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={close}
            disabled={deleteStudyAttachmentRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <DangerButton
            onClick={deleteAttachment}
            disabled={deleteStudyAttachmentRequest.inProgress}
          >
            {deleteStudyAttachmentRequest.inProgress ? 'Deleting...' : 'Delete'}
          </DangerButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </Modal>
  );
};

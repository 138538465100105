import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Login } from '../sales/Login';
import { CompleteOrganisationAccountRegistration } from '../features/organisationAccounts/CompleteOrganisationAccountRegistration';
import { Landing } from '../sales/Landing';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { CompleteUserAccountRegistration } from '../features/userAccounts/CompleteUserAccountRegistration';

export const AnonymousRoutes = () => {
  const authenticationContext = useContext(AuthenticationContext);

  if (authenticationContext.isAuthenticated) {
    return null;
  }

  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/register-organisation">
        <CompleteOrganisationAccountRegistration />
      </Route>
      <Route path="/register-user">
        <CompleteUserAccountRegistration />
      </Route>
      <Route exact path="/">
        <Landing />
      </Route>
      <Route path="*">Not Found</Route>
    </Switch>
  );
};

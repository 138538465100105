import styled from 'styled-components/macro';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  PrimaryButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { debounce } from 'lodash';
import {
  gray400,
  gray500,
  primary200,
  primary900,
} from '../../styling/colours';
import {
  size000,
  size050,
  size100,
  size150,
  size200,
  size250,
  size300,
} from '../../styling/sizes';
import { BuildingIcon, TickIcon, UserIcon } from '../../icons/Icons';
import { GetProjectResponse } from '../../features/projects/GetProjectResponse';
import { usePostWithResponse } from '../../api/usePostWithResponse';
import {
  UserResult,
  UserResults,
  UserResultsNoResultsContainer,
  UserResultsSpinnerContainer,
} from '../../shared/UserResults';
import { Spinner } from '../../shared/Spinner';
import { SearchInput } from '../../shared/SearchPanel';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import { fontSize100, fontSize300 } from '../../styling/fontSizes';
import { fontWeightBold } from '../../styling/fontWeights';
import { ProjectContext } from './ProjectContext';

type SearchUserAccountsQuery = {
  projectId: number;
  searchTerm: string;
};

type SearchUserAccountsResponse = {
  userAccounts: Array<SearchUserAccountsUserAccountResponse>;
};

type SearchUserAccountsUserAccountResponse = {
  userAccountId: number;
  emailAddress: string;
  fullName: string;
  organisationName: string;
};

type InviteProjectContributorRequest = {
  projectId: number;
  userAccountId: number;
  message: string;
};

type Props = {
  close: () => void;
};

export const InviteContributor = ({ close }: Props) => {
  const { getProject, setProject } = useContext(ProjectContext);
  const project = getProject();

  const [selectedUserAccountId, setSelectedUserAccountId] = useState<
    number | null
  >(null);

  const [inviteSent, setInviteSent] = useState(false);

  const inviteProjectContributorRequest = usePostWithResponse<
    InviteProjectContributorRequest,
    { project: GetProjectResponse }
  >('InviteProjectContributor');

  const inviteContributor = () => {
    if (selectedUserAccountId == null) {
      return;
    }

    inviteProjectContributorRequest.makeRequest({
      body: {
        projectId: project.projectId,
        userAccountId: selectedUserAccountId,
        message: '', // TODO
      },
      onSuccess: response => {
        setInviteSent(true);
        setProject(response.project);
      },
    });
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);

  const [
    latestResponse,
    setLatestResponse,
  ] = useState<SearchUserAccountsResponse | null>(null);

  const searchUserAccountsRequest = usePostWithResponse<
    SearchUserAccountsQuery,
    SearchUserAccountsResponse
  >('SearchUserAccountsForInviteContributorModal');

  const makeRequest = () => {
    if (currentSearchTerm.trim().length < 1) {
      return;
    }

    searchUserAccountsRequest.makeRequest({
      body: {
        projectId: project.projectId,
        searchTerm: currentSearchTerm.trim(),
      },
      onSuccess: response => setLatestResponse(response),
    });
  };

  useEffect(() => {
    setSelectedUserAccountId(null);
    makeRequest();
  }, [currentSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedSetSearchTerm = useRef(
    debounce((value: string) => {
      setCurrentSearchTerm(value);
    }, 1_000)
  );

  useEffect(() => {
    debouncedSetSearchTerm.current(searchTerm);
  }, [searchTerm]);

  const onChangeSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const usersToShow = latestResponse?.userAccounts;

  if (inviteSent) {
    return (
      <>
        <ModalContent>
          <SuccessMessage>
            <SuccessIcon />
            <div>Your invite has been sent.</div>
          </SuccessMessage>
        </ModalContent>
      </>
    );
  }

  return (
    <>
      <ModalContent>
        <ModalHeader>Invite contributor</ModalHeader>
        <SearchInput
          placeholder="Search by name or email address"
          value={searchTerm}
          onChange={onChangeSearchInput}
        />
        {searchUserAccountsRequest.error != null && (
          <ApiErrorBox
            error={searchUserAccountsRequest.error}
            withMargin={true}
          />
        )}
        {searchUserAccountsRequest.inProgress &&
          searchUserAccountsRequest.error == null && (
            <UserResultsSpinnerContainer>
              <Spinner />
            </UserResultsSpinnerContainer>
          )}
        {!searchUserAccountsRequest.inProgress &&
          searchUserAccountsRequest.error == null && (
            <UserResults>
              {usersToShow && usersToShow.length > 0 ? (
                usersToShow.map(ua => (
                  <UserResult
                    key={ua.userAccountId}
                    selected={selectedUserAccountId === ua.userAccountId}
                    onClick={() => {
                      if (!inviteProjectContributorRequest.inProgress) {
                        setSelectedUserAccountId(ua.userAccountId);
                      }
                    }}
                  >
                    <ProfilePicture />
                    <div>
                      <UserName>{ua.fullName}</UserName>
                      <ProfileDetails>
                        <ProfileDetailsRow>
                          <BuildingIcon /> {ua.organisationName}
                        </ProfileDetailsRow>
                      </ProfileDetails>
                    </div>
                  </UserResult>
                ))
              ) : (
                <UserResultsNoResultsContainer>
                  No results
                </UserResultsNoResultsContainer>
              )}
            </UserResults>
          )}
        <ApiErrorBox
          error={inviteProjectContributorRequest.error}
          withMargin={true}
        />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={close}
            disabled={inviteProjectContributorRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            disabled={
              selectedUserAccountId == null ||
              inviteProjectContributorRequest.inProgress
            }
            onClick={inviteContributor}
          >
            {inviteProjectContributorRequest.inProgress
              ? 'Sending...'
              : 'Send invite'}
          </PrimaryButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${size100};
`;

const SuccessIcon = styled(TickIcon)`
  padding: ${size100};
  background-color: ${primary900};
  color: ${primary200};
  border-radius: 100%;
  margin: ${size200} 0;
  height: ${size250};
  width: ${size250};
`;

const ProfilePicture = styled(UserIcon)`
  height: ${size300};
  width: ${size300};
  color: ${gray500};
  margin-left: ${size050};
  margin-right: ${size150};
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ProfileDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${gray400};
  margin-right: ${size150};
  font-size: ${fontSize100};

  &:not(last-of-type) {
    margin-bottom: ${size000};
  }

  svg {
    height: ${size200};
    width: ${size200};
    margin-right: ${size050};
  }
`;

const UserName = styled(ProfileDetailsRow)`
  font-size: ${fontSize300};
  font-weight: ${fontWeightBold};
`;

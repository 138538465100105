export const size000 = '4px';
export const size050 = '8px';
export const size100 = '12px';
export const size150 = '16px';
export const size200 = '24px';
export const size225 = '28px';
export const size250 = '32px';
export const size275 = '38px';
export const size300 = '48px';
export const size350 = '64px';
export const size400 = '96px';
export const size450 = '128px';
export const size500 = '192px';
export const size550 = '256px';
export const size600 = '384px';
export const size650 = '512px';
export const size700 = '640px';
export const size750 = '768px';

export const desktopContainerWidth = '1024px';

import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { primary500, primary700 } from '../styling/colours';

export const AppLink = styled(Link)`
  color: ${primary500};
  transition: all 0.25s ease;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    color: ${primary700};
  }
`;

export const AppLinkButton = styled.button.attrs({ type: 'button' })`
  color: ${primary500};
  transition: all 0.25s ease;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: inline;

  &:hover {
    color: ${primary700};
  }
`;

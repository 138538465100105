import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { MoreButton } from './MoreButton';
import { borderRadiusMedium } from '../styling/borders';
import { shadow100 } from '../styling/shadows';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

type Props = {
  children?: React.ReactNode;
};

export const PopoverMenuButton = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const popoverRef = useRef(null);
  useOnClickOutside(popoverRef, () => {
    setIsOpen(false);
  });

  return (
    <Container>
      <MoreButton onClick={() => setIsOpen(!isOpen)} />
      <Popover ref={popoverRef} show={isOpen}>
        {props.children}
      </Popover>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Popover = styled.div<{ show: boolean }>`
  position: absolute;
  right: 0;
  top: calc(100% + 3px);
  background-color: white;
  border-radius: ${borderRadiusMedium};
  box-shadow: ${shadow100};
  z-index: 10;
  display: ${props => (props.show ? 'block' : 'none')};
`;

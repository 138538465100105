import React from 'react';

export const Search = () => (
  <>
    <h1>Search</h1>
    <p>
      The search results will be shown here, including Projects, Users, etc.
      that are relevant.
    </p>
    <p>
      We might want to give the user the option to filter or narrow down the
      results.
    </p>
  </>
);

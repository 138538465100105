import React from 'react';
import {
  DataEntryFormVariableTypeCode,
  dataEntryFormVariableTypes,
} from '../../../model/DataEntryForm';
import { find } from 'lodash';
import styled from 'styled-components/macro';
import { lineHeightMedium } from '../../../styling/lineHeights';
import { gray000, gray300, gray500, gray800 } from '../../../styling/colours';
import { fontWeightBold } from '../../../styling/fontWeights';
import {
  size000,
  size050,
  size300,
  size600,
  size150,
} from '../../../styling/sizes';
import { fontSize100 } from '../../../styling/fontSizes';
import { letterSpacingWide } from '../../../styling/letterSpacing';

type Props = {
  plan: {
    projectCyclePlanId: number;
    whichPatients: string;
    whoIsHelping: string;
    where: string;
    when: string;
    how: string;
    why: string;
    additionalInformation: string;
  };
  variables: Array<{
    dataEntryFormVariableId: number;
    label: string;
    typeCode: DataEntryFormVariableTypeCode;
  }>;
};

export const PlanDetails = ({ plan, variables }: Props) => (
  <Container>
    <Row>
      <LeftColumn>
        <QuestionTitle>Who?</QuestionTitle>
        <QuestionText>
          Which patients will you be collecting information on?
        </QuestionText>
        <QuestionText>Who will be helping you with this Project?</QuestionText>
      </LeftColumn>
      <RightColumn>
        {plan.whichPatients || 'N/A'}
        {'\r\n'}
        {plan.whoIsHelping || 'N/A'}
      </RightColumn>
    </Row>
    <Row>
      <LeftColumn>
        <QuestionTitle>Where?</QuestionTitle>
        <QuestionText>
          Where will you be collecting patient information from?
        </QuestionText>
      </LeftColumn>
      <RightColumn>{plan.where || 'N/A'}</RightColumn>
    </Row>
    <Row>
      <LeftColumn>
        <QuestionTitle>When?</QuestionTitle>
        <QuestionText>
          When are you planning on collecting the information?
        </QuestionText>
      </LeftColumn>
      <RightColumn>{plan.when || 'N/A'}</RightColumn>
    </Row>
    <Row>
      <LeftColumn>
        <QuestionTitle>How?</QuestionTitle>
        <QuestionText>
          How are you going to get the information you need?
        </QuestionText>
      </LeftColumn>
      <RightColumn>{plan.how || 'N/A'}</RightColumn>
    </Row>
    <Row>
      <LeftColumn>
        <QuestionTitle>Why?</QuestionTitle>
        <QuestionText>Why do you think this project is necessary?</QuestionText>
      </LeftColumn>
      <RightColumn>{plan.why || 'N/A'}</RightColumn>
    </Row>
    <Row>
      <LeftColumn>
        <QuestionTitle>What?</QuestionTitle>
        <QuestionText>What are you collecting information on?</QuestionText>
      </LeftColumn>
      <RightColumn>
        The following data will be collected:
        <ul>
          {variables.map(variable => (
            <li key={variable.dataEntryFormVariableId}>
              {variable.label} (
              {
                find(
                  dataEntryFormVariableTypes,
                  type => type.typeCode === variable.typeCode
                )?.name
              }
              )
            </li>
          ))}
        </ul>
      </RightColumn>
    </Row>
    <Row>
      <LeftColumn>
        <QuestionTitle>Additional information</QuestionTitle>
        <QuestionText>
          What other relevant information, if any, would you like to give?
        </QuestionText>
      </LeftColumn>
      <RightColumn>{plan.additionalInformation || 'N/A'}</RightColumn>
    </Row>
  </Container>
);

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: ${size150};
  margin-bottom: ${size150};
  border-bottom: solid 1px ${gray800};

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  padding-right: ${size300};
  min-width: ${size600};
`;

const QuestionTitle = styled.div`
  font-weight: ${fontWeightBold};
  margin-bottom: ${size050};
  color: ${gray300};
  font-size: ${fontSize100};
  text-transform: uppercase;
  letter-spacing: ${letterSpacingWide};
`;

const QuestionText = styled.div`
  color: ${gray500};
  margin-bottom: ${size000};
  line-height: ${lineHeightMedium};
`;

const RightColumn = styled.div`
  flex: 2;
  line-height: ${lineHeightMedium};
  color: ${gray000};
  white-space: pre-wrap;
  min-width: ${size600};
`;

import React, { useContext } from 'react';
import { ModalContent, ModalFooter, ModalHeader } from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  PrimaryButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { SectionHeader } from '../../shared/Section';
import styled from 'styled-components/macro';
import { gray600, gray900 } from '../../styling/colours';
import { size000, size050, size100, size250 } from '../../styling/sizes';
import { borderRadiusMedium } from '../../styling/borders';
import { UserIcon } from '../../icons/Icons';
import { NotificationsBadge } from '../../shared/NotificationsBadge';
import { GetProjectResponse } from '../../features/projects/GetProjectResponse';
import { usePostWithResponse } from '../../api/usePostWithResponse';
import { CancelProjectContributorInviteCommand } from './CancelProjectContributorInviteCommand';
import { ProjectContext } from './ProjectContext';

type Props = {
  close: () => void;
};

export const ManageContributors = ({ close }: Props) => {
  const { getProject, setProject } = useContext(ProjectContext);
  const project = getProject();

  const projectContributors = project.contributors;
  const projectContributorJoinRequests = project.contributorJoinRequests;
  const projectContributorInvites = project.contributorInvites;

  const cancelInviteApiRequest = usePostWithResponse<
    CancelProjectContributorInviteCommand,
    { project: GetProjectResponse }
  >('CancelProjectContributorInvite');

  const cancelInvite = (projectContributorInviteId: number) => {
    cancelInviteApiRequest.makeRequest({
      body: {
        projectContributorInviteId,
      },
      onSuccess: response => {
        setProject(response.project);
      },
    });
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Manage contributors</ModalHeader>
        {projectContributors.length > 0 ? (
          projectContributors.map(c => (
            <UserRow key={c.projectContributorId}>
              <UserRowDetails>
                <UserRowAvatar />
                <span>{c.fullName}</span>
              </UserRowDetails>
              <ButtonRowAlignRight>
                <SecondaryButton size="small">Remove</SecondaryButton>
              </ButtonRowAlignRight>
            </UserRow>
          ))
        ) : (
          <>There are no contributors yet.</>
        )}
        <SubSection>
          <SectionHeader>Pending invites</SectionHeader>
          {projectContributorInvites.length > 0 ? (
            projectContributorInvites.map(invite => (
              <UserRow key={invite.projectContributorInviteId}>
                <UserRowDetails>
                  <UserRowAvatar />
                  <span>{invite.fullName}</span>
                </UserRowDetails>
                <ButtonRowAlignRight>
                  <SecondaryButton
                    size="small"
                    onClick={() =>
                      cancelInvite(invite.projectContributorInviteId)
                    }
                    disabled={cancelInviteApiRequest.inProgress}
                  >
                    Cancel
                  </SecondaryButton>
                </ButtonRowAlignRight>
              </UserRow>
            ))
          ) : (
            <>There are no pending invites.</>
          )}
        </SubSection>
        <SubSection>
          <SectionHeader>
            Join requests
            {projectContributorJoinRequests.length > 0 && (
              <NotificationsBadge>
                {projectContributorJoinRequests.length}
              </NotificationsBadge>
            )}
          </SectionHeader>
          {projectContributorJoinRequests.length > 0 ? (
            projectContributorJoinRequests.map(request => (
              <UserRow key={request.projectContributorJoinRequestId}>
                <UserRowDetails>
                  <UserRowAvatar />
                  <span>{request.fullName}</span>
                </UserRowDetails>
                <ButtonRowAlignRight>
                  <SecondaryButton
                    size="small"
                    onClick={() => {
                      // TODO : Delete Project Contributor Join Request
                      // deleteProjectContributorJoinRequest(
                      //     request.projectContributorJoinRequestId,
                      //     data,
                      //     setData
                      // )
                    }}
                  >
                    Reject
                  </SecondaryButton>
                  <PrimaryButton
                    size="small"
                    onClick={() => {
                      // TODO : Approve Project Contributor Join Request
                      // approveProjectContributorJoinRequest(
                      //     request,
                      //     data,
                      //     setData
                      // )
                    }}
                  >
                    Approve
                  </PrimaryButton>
                </ButtonRowAlignRight>
              </UserRow>
            ))
          ) : (
            <>There are no outstanding join requests.</>
          )}
        </SubSection>
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton onClick={close}>Close</SecondaryButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

const SubSection = styled.div`
  margin-top: ${size250};
`;

const UserRow = styled.div`
  background-color: ${gray900};
  padding: ${size100};
  border-radius: ${borderRadiusMedium};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: ${size050};
  }
`;

const UserRowDetails = styled.div`
  display: flex;
  align-items: center;
`;

const UserRowAvatar = styled(UserIcon)`
  margin: 0 ${size100} 0 ${size000};
  color: ${gray600};
`;

import React, { useState } from 'react';
import { usePostWithoutResponse } from '../../api/usePostWithoutResponse';
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from '../../shared/Modal';
import {
  ButtonRowAlignRight,
  PrimaryButton,
  SecondaryButton,
} from '../../shared/Buttons';
import { ApiErrorBox } from '../../api/ApiErrorBox';
import {
  ModalSuccessMessage,
  ModalSuccessMessageButton,
} from '../../shared/ModalSuccessMessage';

type ResendUserAccountInviteCommand = {
  userAccountId: number;
};

type Props = {
  close: () => void;
  userAccountId: number;
  emailAddress: string;
};

export const ResendUserAccountInvite = (props: Props) => {
  const resendUserAccountInviteRequest = usePostWithoutResponse<
    ResendUserAccountInviteCommand
  >('ResendUserAccountInvite');

  const [success, setSuccess] = useState(false);

  const resendUserAccountInvite = () => {
    resendUserAccountInviteRequest.makeRequest({
      body: { userAccountId: props.userAccountId },
      onSuccess: () => setSuccess(true),
    });
  };

  if (success) {
    return (
      <ModalContent>
        <ModalSuccessMessage>
          <ModalSubHeader>Invite sent</ModalSubHeader>
          <ModalSuccessMessageButton onClick={props.close}>
            Close
          </ModalSuccessMessageButton>
        </ModalSuccessMessage>
      </ModalContent>
    );
  }

  return (
    <>
      <ModalContent>
        <ModalHeader>Resend invite</ModalHeader>
        <p>
          Are you sure you wish to send a new invite to{' '}
          <strong>{props.emailAddress}</strong>?
        </p>
        <ApiErrorBox
          error={resendUserAccountInviteRequest.error}
          withMargin={true}
        />
      </ModalContent>
      <ModalFooter>
        <ButtonRowAlignRight>
          <SecondaryButton
            onClick={props.close}
            disabled={resendUserAccountInviteRequest.inProgress}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={resendUserAccountInvite}
            disabled={resendUserAccountInviteRequest.inProgress}
          >
            {resendUserAccountInviteRequest.inProgress ? 'Sending...' : 'Send'}
          </PrimaryButton>
        </ButtonRowAlignRight>
      </ModalFooter>
    </>
  );
};

import React, { useContext } from 'react';
import { Section } from '../../shared/Section';
import {
  TableWithClickableRows,
  THead,
  Tr,
  Th,
  ChevronHeader,
  TBody,
  Td,
  ChevronCell,
} from '../../shared/Table';
import {
  ChevronRightIcon,
  GroupIcon,
  FolderSharedIcon,
  UserOutlineIcon,
  UserPlusIcon,
} from '../../icons/Icons';
import { DataContext } from '../../model/DataContext';
import { values } from 'lodash';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardHeaderActions,
} from '../../shared/Card';
import {
  Stat,
  Stats,
  StatIcon,
  StatItem,
  StatHeadline,
  StatSupportingText,
} from '../../shared/Stats';
import { UserContext } from '../UserContext';
import { PrimaryButton } from '../../shared/Buttons';

export const Users = () => {
  const dataContext = useContext(DataContext);
  const data = dataContext.getData();

  const currentUser = data.users[useContext(UserContext).userId!];

  const users = values(data.users);
  const organisationUsers = users.filter(
    u => u.organisationId === currentUser.organisationId
  );

  const history = useHistory();

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Users</CardTitle>
          <CardHeaderActions>
            <PrimaryButton icon="true" onClick={() => alert('TODO')}>
              <UserPlusIcon />
              Invite User
            </PrimaryButton>
          </CardHeaderActions>
        </CardHeader>
        <Stats>
          <Stat>
            <StatIcon>
              <GroupIcon />
            </StatIcon>
            <StatItem>
              <StatHeadline>{users.length}</StatHeadline>
              <StatSupportingText>
                approved user{users.length === 1 ? '' : 's'}{' '}
              </StatSupportingText>
            </StatItem>
          </Stat>
          <Stat>
            <StatIcon>
              <FolderSharedIcon />
            </StatIcon>
            <StatItem>
              <StatHeadline>{organisationUsers.length}</StatHeadline>
              <StatSupportingText>
                user{users.length === 1 ? '' : 's'} in your organisation
              </StatSupportingText>
            </StatItem>
          </Stat>
          <Stat>
            <StatIcon>
              <UserOutlineIcon />
            </StatIcon>
            <StatItem>
              <StatHeadline>0</StatHeadline>
              <StatSupportingText>
                user{users.length === 1 ? '' : 's'} pending approval
              </StatSupportingText>
            </StatItem>
          </Stat>
        </Stats>
      </Card>
      <Section>
        <UsersTable>
          <THead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Organisation</Th>
              <Th>Department</Th>
              <Th>Job</Th>
              <ChevronHeader />
            </Tr>
          </THead>
          <TBody>
            {users.map(user => (
              <Tr
                key={user.userId}
                onClick={() => history.push(`/app/users/${user.userId}`)}
              >
                <Td>{user.name}</Td>
                <Td>{user.emailAddress}</Td>
                <Td>{data.organisations[user.organisationId].name}</Td>
                <Td>{user.department}</Td>
                <Td>{user.profession}</Td>
                <ChevronCell>
                  <ChevronRightIcon />
                </ChevronCell>
              </Tr>
            ))}
          </TBody>
        </UsersTable>
      </Section>
    </>
  );
};

const UsersTable = styled(TableWithClickableRows)`
  width: 100%;
`;

import React, { useContext } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form } from '../../../form/Form';
import { Label } from '../../../form/Label';
import { FormField } from '../../../form/FormField';
import { TextAreaField } from '../../../form/TextAreaField';
import { ButtonRowAlignRight, SecondaryButton } from '../../../shared/Buttons';
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from '../../../shared/Modal';
import { usePostWithResponse } from '../../../api/usePostWithResponse';
import { StartNewCycleCommand } from './StartNewCycleCommand';
import { StartNewCycleResponse } from './StartNewCycleResponse';
import { ProjectContext } from '../ProjectContext';
import { FormSubmitButton } from '../../../form/FormSubmitButton';
import { Validator } from 'fluentvalidation-ts';
import { ApiErrorBox } from '../../../api/ApiErrorBox';

type Props = {
  close: () => void;
  setCycleNumber: (cycleNumber: number) => void;
};

export const NewCycle = (props: Props) => {
  const { getProject } = useContext(ProjectContext);
  const project = getProject();

  const startNewCycleRequest = usePostWithResponse<
    StartNewCycleCommand,
    StartNewCycleResponse
  >('StartNewCycle');

  const onSubmit = (
    formModel: FormModel,
    formikHelpers: FormikHelpers<FormModel>
  ) => {
    startNewCycleRequest.makeRequest({
      body: {
        projectId: project.projectId,
        reason: formModel.reason,
      },
      onSuccess: response => {
        props.close();
        props.setCycleNumber(response.newCycleNumber);
      },
      onError: () => formikHelpers.setSubmitting(false),
    });
  };

  return (
    <Formik<FormModel>
      initialValues={{ reason: '' }}
      onSubmit={onSubmit}
      validate={validator.validate}
    >
      <Form>
        <ModalContent>
          <ModalHeader>New Cycle</ModalHeader>
          <ModalSubHeader>
            Begins a new cycle, allowing you to introduce a change or gather
            more data.
          </ModalSubHeader>
          <FormField>
            <Label>Reason</Label>
            <TextAreaField name="reason" />
          </FormField>
          <ApiErrorBox error={startNewCycleRequest.error} withMargin={true} />
        </ModalContent>
        <ModalFooter>
          <ButtonRowAlignRight>
            <SecondaryButton
              onClick={props.close}
              disabled={startNewCycleRequest.inProgress}
            >
              Cancel
            </SecondaryButton>
            <FormSubmitButton>Submit</FormSubmitButton>
          </ButtonRowAlignRight>
        </ModalFooter>
      </Form>
    </Formik>
  );
};

type FormModel = {
  reason: string;
};

class FormModelValidator extends Validator<FormModel> {
  constructor() {
    super();

    this.ruleFor('reason').notEmpty();
  }
}

const validator = new FormModelValidator();

import React, { useContext } from 'react';
import {
  BreadcrumbDivider,
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumbs,
} from '../../shared/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { GetProjectResponse } from '../../features/projects/GetProjectResponse';
import { UserAccountContext } from '../../features/userAccounts/UserAccountContext';

type Props = {
  project: GetProjectResponse;
};

export const ProjectBreadcrumbs = (props: Props) => {
  const { cycleNumberParam } = useParams();

  const { project } = props;

  const userAccountContext = useContext(UserAccountContext);
  const userAccountId = userAccountContext.getUserAccountId();

  if (project == null) {
    return <div>Project is null QQ TODO</div>;
  }

  const isMyProject = userAccountId === project.leadUserAccount.userAccountId;

  const projectLead = project.leadUserAccount;

  const cycleNumber =
    cycleNumberParam == null ? null : Number(cycleNumberParam);

  return (
    <Breadcrumbs>
      {isMyProject ? (
        <BreadcrumbLink to={`/app/projects`}>My Projects</BreadcrumbLink>
      ) : (
        <BreadcrumbLink to={`/app/users/${projectLead.userAccountId}`}>
          {projectLead.fullName}
        </BreadcrumbLink>
      )}
      <BreadcrumbDivider />
      <BreadcrumbLink to={`/app/projects/${project.projectId}`}>
        {project.title}
      </BreadcrumbLink>
      {cycleNumber != null && (
        <>
          <BreadcrumbDivider />
          <BreadcrumbItem>Cycle {cycleNumber}</BreadcrumbItem>
        </>
      )}
    </Breadcrumbs>
  );
};

export const black = 'hsl(210, 24%, 12%)';

export const gray000 = 'hsl(210, 24%, 16%)';
export const gray050 = 'hsl(210, 24%, 20%)';
export const gray100 = 'hsl(209, 20%, 25%)';
export const gray200 = 'hsl(209, 18%, 30%)';
export const gray300 = 'hsl(209, 14%, 37%)';
export const gray400 = 'hsl(211, 12%, 43%)';
export const gray500 = 'hsl(211, 10%, 53%)';
export const gray600 = 'hsl(211, 13%, 65%)';
export const gray700 = 'hsl(210, 16%, 82%)';
export const gray750 = 'hsl(210, 16%, 89%)';
export const gray800 = 'hsl(214, 15%, 91%)';
export const gray900 = 'hsl(216, 33%, 97%)';
export const gray950 = 'hsl(216, 33%, 98%)';

export const primary000 = 'hsl(234, 62%, 26%)';
export const primary100 = 'hsl(232, 51%, 36%)';
export const primary200 = 'hsl(230, 49%, 41%)';
export const primary300 = 'hsl(228, 45%, 45%)';
export const primary400 = 'hsl(227, 42%, 51%)';
export const primary500 = 'hsl(227, 50%, 59%)';
export const primary600 = 'hsl(225, 57%, 67%)';
export const primary700 = 'hsl(224, 67%, 76%)';
export const primary800 = 'hsl(221, 78%, 86%)';
export const primary900 = 'hsl(221, 68%, 93%)';
export const primary950 = 'hsl(221, 100%, 97%)';

export const accent000 = 'hsl(188, 91%, 23%)';
export const accent100 = 'hsl(186, 91%, 29%)';
export const accent200 = 'hsl(184, 90%, 34%)';
export const accent300 = 'hsl(182, 85%, 39%)';
export const accent400 = 'hsl(180, 77%, 47%)';
export const accent500 = 'hsl(178, 78%, 57%)';
export const accent600 = 'hsl(176, 87%, 67%)';
export const accent700 = 'hsl(174, 96%, 78%)';
export const accent800 = 'hsl(172, 97%, 88%)';
export const accent900 = 'hsl(171, 82%, 94%)';

export const pink000 = 'hsl(320, 100%, 19%)';
export const pink100 = 'hsl(322, 93%, 27%)';
export const pink200 = 'hsl(324, 93%, 33%)';
export const pink300 = 'hsl(326, 90%, 39%)';
export const pink400 = 'hsl(328, 85%, 46%)';
export const pink500 = 'hsl(330, 79%, 56%)';
export const pink600 = 'hsl(334, 86%, 67%)';
export const pink700 = 'hsl(336, 100%, 77%)';
export const pink800 = 'hsl(338, 100%, 86%)';
export const pink900 = 'hsl(341, 100%, 95%)';

export const red000 = 'hsl(348, 90%, 20%)';
export const red100 = 'hsl(350, 90%, 28%)';
export const red200 = 'hsl(352, 86%, 35%)';
export const red300 = 'hsl(354, 81%, 44%)';
export const red400 = 'hsl(356, 71%, 53%)';
export const red500 = 'hsl(360, 79%, 62%)';
export const red600 = 'hsl(360, 87%, 69%)';
export const red700 = 'hsl(360, 100%, 80%)';
export const red800 = 'hsl(360, 100%, 87%)';
export const red900 = 'hsl(360, 100%, 95%)';

export const yellow000 = 'hsl(15, 86%, 30%)';
export const yellow100 = 'hsl(22, 82%, 39%)';
export const yellow200 = 'hsl(29, 80%, 44%)';
export const yellow300 = 'hsl(36, 77%, 49%)';
export const yellow400 = 'hsl(42, 87%, 55%)';
export const yellow500 = 'hsl(44, 92%, 63%)';
export const yellow600 = 'hsl(48, 94%, 68%)';
export const yellow700 = 'hsl(48, 95%, 76%)';
export const yellow800 = 'hsl(48, 100%, 88%)';
export const yellow900 = 'hsl(49, 100%, 96%)';

export const green000 = 'hsl(125, 97%, 14%)';
export const green100 = 'hsl(125, 86%, 20%)';
export const green200 = 'hsl(125, 79%, 26%)';
export const green300 = 'hsl(122, 80%, 29%)';
export const green400 = 'hsl(122, 73%, 35%)';
export const green500 = 'hsl(123, 57%, 45%)';
export const green600 = 'hsl(123, 53%, 55%)';
export const green700 = 'hsl(124, 63%, 74%)';
export const green800 = 'hsl(127, 65%, 85%)';
export const green900 = 'hsl(125, 65%, 93%)';

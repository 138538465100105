export type ApiErrorResult = {
  userVisibleMessage: string | null;
  exceptionMessage: string | null;
  stackTrace: string | null;
};

export const getApiErrorResult = (rawError: any) => {
  const rawErrorBody = rawError?.response?.body ?? {};

  return {
    userVisibleMessage:
      rawErrorBody['userVisibleMessage'] ?? 'Sorry, something went wrong.',
    exceptionMessage: rawErrorBody['exceptionMessage'] ?? null,
    stackTrace: rawErrorBody['stackTrace'] ?? null,
  };
};

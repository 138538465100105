import styled from 'styled-components/macro';
import { shadow000 } from '../styling/shadows';
import { size050, size150, size200, size300, size350 } from '../styling/sizes';
import { borderRadiusMedium } from '../styling/borders';
import {
  gray900,
  gray800,
  gray200,
  gray300,
  gray500,
} from '../styling/colours';
import { fontSize200, fontSize300, fontSize400 } from '../styling/fontSizes';
import { fontWeightBold } from '../styling/fontWeights';
import { ButtonRowAlignRight } from './Buttons';

export const Card = styled.div`
  background-color: white;
  box-shadow: ${shadow000};
  margin-bottom: ${size200};
  border-radius: ${borderRadiusMedium};
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  background-color: ${gray900};
  border-bottom: solid 1px ${gray800};
  border-top-left-radius: ${borderRadiusMedium};
  border-top-right-radius: ${borderRadiusMedium};
  padding: 0 ${size200};
  height: ${size350};
`;

export const CardComplexHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${size150} ${size200};
  height: auto;
`;

export const CardHeaderActions = styled(ButtonRowAlignRight)`
  flex: 1;
`;

export const CardTitle = styled.div`
  font-size: ${fontSize400};
  font-weight: ${fontWeightBold};
  color: ${gray200};
  margin-right: ${size200};
`;

export const CardContent = styled.div`
  padding: ${size200};

  &:not(:first-of-type) {
    border-top: solid 1px ${gray800};
  }
`;

export const CardContentHeading = styled.div`
  color: ${gray300};
  font-weight: ${fontWeightBold};
  margin: 0 0 ${size050};
  font-size: ${fontSize300};

  &:not(:first-of-type) {
    margin-top: ${size200};
  }
`;

export const CardHeaderStats = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-top: ${size150};
`;

export const CardHeaderStat = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSize200};
  color: ${gray500};
  margin-right: ${size300};

  svg {
    margin-right: ${size050};
  }
`;

import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { OrganisationAppLayout } from '../layout/OrganisationAppLayout';
import { OrganisationAccountDashboard } from '../features/organisationAccounts/OrganisationAccountDashboard';
import { ManageUserAccounts } from '../features/userAccounts/ManageUserAccounts';
import { ManageTeams } from '../features/teams/ManageTeams';
import { ManageTeam } from '../features/teams/ManageTeam';
import { OrganisationAccountSettings } from '../features/organisationAccounts/OrganisationAccountSettings';

export const OrganisationAccountRoutes = () => {
  const authenticationContext = useContext(AuthenticationContext);

  if (
    !authenticationContext.isAuthenticated ||
    authenticationContext.accountType !== 'organisation'
  ) {
    return null;
  }

  return (
    <OrganisationAppLayout>
      <Switch>
        <Route path="/" exact={true}>
          <OrganisationAccountDashboard />
        </Route>
        <Route path="/users">
          <ManageUserAccounts />
        </Route>
        <Route path="/teams" exact={true}>
          <ManageTeams />
        </Route>
        <Route path="/teams/:teamId">
          <ManageTeam />
        </Route>
        <Route path="/settings">
          <OrganisationAccountSettings />
        </Route>
        <Route path="*">Not found (organisation account)</Route>
      </Switch>
    </OrganisationAppLayout>
  );
};
